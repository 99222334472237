import { InformationSection } from 'components/InformationCard';
import DateInformation from 'components/InformationCard/DateInformation';
import EditableInformationCard from 'components/InformationCard/EditableInformationCard';
import LinkInformation from 'components/InformationCard/LinkInformation';
import StatusInformation from 'components/InformationCard/StatusInformation';
import TextInformation from 'components/InformationCard/TextInformation';
import ToggleInformation from 'components/InformationCard/ToggleInformation';
import { statusMapping } from 'pages/scannedDocuments/scannedDocuments.mappings';
import { UPDATE_SCANNED_DOCUMENT } from 'pages/scannedDocuments/scannedDocuments.mutations';
import {
  GET_CLAIMS_BY_POLICY_ID,
  GET_SCANNED_DOCUMENT_BY_ID,
} from 'pages/scannedDocuments/scannedDocuments.queries';
import { ScannedDocument } from 'pages/scannedDocuments/scannedDocuments.types';
import { z } from 'zod';

export const ScannedDocumentInfo = ({
  scannedDocument,
}: {
  scannedDocument: ScannedDocument;
}) => {
  const editableData = {
    status: scannedDocument.status ?? undefined,
    policyNumber: scannedDocument.policyNumber?.trim() ?? undefined,
    documentDate: scannedDocument.documentDate ?? undefined,
    recipientName: scannedDocument.recipientName ?? undefined,
    senderName: scannedDocument.senderName ?? undefined,
    insuranceType: scannedDocument.insuranceType ?? undefined,
    subject: scannedDocument.subject ?? undefined,
    userPolicyId: scannedDocument.userPolicyId ?? undefined,
    friendlyName: scannedDocument.friendlyName ?? undefined,
    category: undefined,
    textContent: undefined,
    processed: scannedDocument.processed ? 'Yes' : 'No',
  };

  const editableIfNotArchived = !scannedDocument.archivedAt;

  const infoSections: InformationSection[] = [
    {
      title: 'Document data',
      rows: [
        {
          id: 'pdfFile',
          title: 'PDF',
          href: scannedDocument.url ?? '',
          data: scannedDocument.friendlyName ?? '',
          type: 'LINK',
          component: LinkInformation,
          externalLink: true,
        },
        {
          id: 'status',
          title: 'Status',
          data: scannedDocument.status ?? 'NEW',
          statusMapping,
          type: 'STATUS',
          component: StatusInformation,
          editable: false,
        },
        {
          id: 'categories',
          title: 'Category',
          data: scannedDocument.categories?.map(({ name }) => name).join(', '),
          type: 'TEXT',
          component: TextInformation,
          editable: false,
        },
        {
          id: 'policyNumber',
          title: 'Policy number',
          data: scannedDocument.policyNumber || '',
          type: 'TEXT',
          component: TextInformation,
          editable: editableIfNotArchived,
        },
        {
          id: 'userPolicyId',
          title: 'Connected policy id',
          data: scannedDocument.userPolicyId ?? '',
          type: 'TEXT',
          component: TextInformation,
          editable: editableIfNotArchived,
          errorMessage: 'Id must be a valid UUID or empty',
          zodValidationSchema: z.object({
            userPolicyId: z.union([
              z.string().uuid(),
              z.literal(''),
              z.null(),
              z.undefined(),
            ]),
          }),
        },
        {
          id: 'recipientName',
          title: 'Recipient name',
          data: scannedDocument.recipientName ?? '',
          type: 'TEXT',
          component: TextInformation,
          editable: editableIfNotArchived,
        },
        {
          id: 'subject',
          title: 'Subject',
          data: scannedDocument.subject ?? '',
          type: 'TEXT',
          component: TextInformation,
          editable: editableIfNotArchived,
        },
        {
          id: 'senderName',
          title: 'Sender name',
          data: scannedDocument.senderName ?? '',
          type: 'TEXT',
          component: TextInformation,
          editable: editableIfNotArchived,
        },
        {
          id: 'documentDate',
          title: 'Document date',
          data: scannedDocument.documentDate ?? '',
          type: 'DATE',
          component: DateInformation,
          editable: editableIfNotArchived,
        },
        {
          id: 'friendlyName',
          title: scannedDocument.blobName.includes(
            scannedDocument.friendlyName ?? ''
          )
            ? 'Friendly name'
            : 'Friendly name *',
          data: scannedDocument.friendlyName ?? '',
          type: 'TEXT',
          component: TextInformation,
          editable: editableIfNotArchived,
        },
        {
          id: 'processed',
          title: 'Processed',
          data: scannedDocument.processed ? 'Yes' : 'No',
          type: 'TOGGLE',
          component: ToggleInformation,
          editable: editableIfNotArchived,
        },
      ],
    },
  ];

  return (
    <EditableInformationCard
      sections={infoSections}
      editableData={editableData}
      mutation={UPDATE_SCANNED_DOCUMENT}
      resourceId={scannedDocument.id}
      refetchQueries={[GET_SCANNED_DOCUMENT_BY_ID, GET_CLAIMS_BY_POLICY_ID]}
      extraVariables={{}}
      successMessage="Successfully saved changes"
    />
  );
};
