import { ApolloError } from '@apollo/client';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import CheckBadge from 'components/CheckBadge/CheckBadge';
import LanguageSelector from 'components/LanguageSelector';
import Link from 'components/Link';
import { LinkButton } from 'components/LinkButton';
import NavBar from 'components/NavBar';
import Tabs, { TabInformation } from 'components/Tabs';
import Tab from 'components/Tabs/Tab';
import { TimedLoader } from 'components/TimedLoader';
import Page403 from 'pages/errors/403';
import Page404 from 'pages/errors/404';
import PageGenericError from 'pages/errors/genericError';
import { createElement } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import { paths } from 'routes/definedPaths';
import { countryIconMapper } from 'shared/countries/models';
import { checkNotFoundErrors } from 'shared/errorHandling/notFoundErrors';
import { checkUnauthenticatedErrors } from 'shared/errorHandling/unauthenticatedError';
import { checkUnauthorizedErrors } from 'shared/errorHandling/unauthorizedErrors';

import {
  ExternalLink,
  HeaderInfoText,
  InternalLink as InternalLinkType,
  TabComponent,
} from './models';
import * as styles from './styles';

interface Props {
  windowTitle: string;
  pageTitle: string;
  pageSubtitle?: string;
  backLink: { text: string; href?: string };
  tabs: TabInformation[];
  tabComponents: TabComponent[];
  loading: boolean;
  /**
   * TODO: [KONG] Come up with more type safe solution
   * Types should match with URL match param values
   * Refactor after creating reusable route variables and building pattern for URLs
   *  */
  current: string;
  internalLinks?: InternalLinkType[];
  externalLinks?: ExternalLink[];
  headerInfoTexts?: HeaderInfoText[];
  headerActions?: React.ReactNode;
  error?: ApolloError;
  hasLanguageSelector?: boolean;
  titleBadge?: React.ReactNode;
}

export const DetailsPage = ({
  windowTitle,
  pageTitle,
  pageSubtitle,
  backLink,
  tabs,
  tabComponents,
  loading,
  current,
  externalLinks,
  internalLinks,
  headerInfoTexts,
  headerActions,
  hasLanguageSelector = false,
  error,
  titleBadge,
}: Props) => {
  const history = useHistory();

  if (error && checkUnauthenticatedErrors(error)) {
    history.push(paths.auth.path);
  }
  if (error && checkUnauthorizedErrors(error)) return <Page403 />;
  if (error && checkNotFoundErrors(error)) return <Page404 />;
  if (error) return <PageGenericError />;

  const isPageSubTitleLong = Boolean(pageSubtitle && pageSubtitle.length > 30);

  return (
    <>
      <Helmet>
        <title>{windowTitle}</title>
      </Helmet>
      <div className="flex h-screen flex-col md:flex-row">
        <NavBar current={current} />
        <div className={styles.pageWrapper}>
          {loading ? (
            <TimedLoader />
          ) : (
            <>
              <div className={styles.headerWrapper}>
                <div className={styles.header}>
                  <div className={styles.topSectionWrapper}>
                    <Link
                      color="secondary"
                      text={backLink.text}
                      href={backLink.href}
                      IconComponent={ChevronLeftIcon}
                    />
                    {hasLanguageSelector && <LanguageSelector />}
                  </div>
                  <div className={styles.middleSectionWrapper}>
                    <div className={styles.pageTitle}>
                      <h1 className={styles.title}>{pageTitle}</h1>
                      {pageSubtitle && !isPageSubTitleLong && (
                        <h2 className={styles.subtitle}>{pageSubtitle}</h2>
                      )}
                      {titleBadge && (
                        <div className="ml-[16px]">{titleBadge}</div>
                      )}
                    </div>
                    {headerActions ?? null}
                  </div>
                  {isPageSubTitleLong && (
                    <h2 className={styles.longSubTitle}>{pageSubtitle}</h2>
                  )}
                  <div className={styles.detailsSectionWrapper}>
                    <div className="mb-2 mt-[8px] flex flex-col space-y-3 md:mb-0 md:flex-row md:space-y-0">
                      {internalLinks && internalLinks.length !== 0 && (
                        <div className="mr-[20px] flex">
                          {internalLinks?.map(
                            (
                              {
                                href,
                                icon,
                                title,
                                isTemporaryExternal,
                                type,
                                region,
                              },
                              idx
                            ) => (
                              <div
                                key={title}
                                className={classNames(styles.detailsSection, {
                                  'ml-[8px]': idx !== 0,
                                })}
                              >
                                <LinkButton
                                  href={href}
                                  buttonType={type ?? 'white'}
                                  Icon={icon}
                                  isExternal={isTemporaryExternal}
                                >
                                  {title}
                                  {region && (
                                    <img
                                      className="ml-2 rounded-sm"
                                      src={countryIconMapper[region]}
                                      alt={region}
                                    />
                                  )}
                                </LinkButton>
                              </div>
                            )
                          )}
                        </div>
                      )}
                      <div className={styles.headerInfoContainer}>
                        {headerInfoTexts?.map(({ title, icon, check }) => (
                          <div className={styles.headerInfoText} key={title}>
                            <div className={classNames(styles.icon)}>
                              {icon}
                            </div>
                            <span className="ml-[4px]">{title}</span>
                            {check && <CheckBadge checkType={check} />}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={classNames(styles.tabsContainer, {
                  'mt-[64px]': isPageSubTitleLong,
                })}
              >
                <Tabs
                  tabs={tabs}
                  links={externalLinks}
                  shouldExtendTabs={isPageSubTitleLong}
                >
                  {tabComponents.map(({ component, id, props }) => (
                    <Tab key={id}>
                      <div className={styles.tabWrapper}>
                        {createElement(component, { ...props })}
                      </div>
                    </Tab>
                  ))}
                </Tabs>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
