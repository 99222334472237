import { ApolloError } from '@apollo/client';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { CheckBadgeType } from 'components/CheckBadge/CheckBadge';
import OverviewPage from 'components/OverviewPage';
import { SelectButtonOption } from 'components/SelectButton';
import { FilterConfig } from 'components/TableRefactor/Filters/types';
import Table from 'components/TableRefactor/Table';
import { RowConfig } from 'components/TableRefactor/types';
import { capitalize, lowerCase, upperFirst } from 'lodash';
import { Status } from 'models/statusColor';
import { retrieveClaimsDetailsPath } from 'pages/claims/template/utils/utils';
import Page404 from 'pages/errors/404';
import { useState } from 'react';
import { useParams } from 'react-router';
import { policyRouteMapper } from 'shared/insurances/policyRouteMapper';
import { validateIBAN } from 'shared/validateIBAN';

import ExportButton from './components/ExportButton';
import PaymentSummaryModal from './components/PaymentSummaryModal';
import { tableHeaders } from './data/tableHeaders';
import { GET_QONTO_TRANSACTIONS } from './graphql/transactions';
import { useClaimAmountsValidation } from './hooks/useClaimAmountsValidation';
import { ClaimPaymentTransaction } from './models/ClaimPaymentTransaction';
import {
  InsuranceTypeFromPath,
  insuranceTypeFromPath,
  InsuranceTypeFromPathMapping,
} from './models/TransactionInsuranceType';
import {
  TransactionStatus,
  transactionStatusColorMaper,
} from './models/TransactionStatus';
import { getCountry } from './utils/getCountry';
import { getInsuranceTypePathName } from './utils/getInsuranceTypePathName';

const NUMBER_OF_TRANSACTIONS_PER_PAGE = 24;

const statusOptions: SelectButtonOption<TransactionStatus>[] = [
  {
    id: 'PENDING',
    label: 'Pending',
    title: 'Pending',
  },
  {
    id: 'READY',
    label: 'Ready',
    title: 'Ready',
  },
  {
    id: 'EXPORTED',
    label: 'Exported',
    title: 'Exported',
  },
  {
    id: 'SKIPPED',
    label: 'Skipped',
    title: 'Skipped',
  },
  {
    id: 'IGNORED',
    label: 'Ignored',
    title: 'Ignored',
  },
  {
    id: 'PAID',
    label: 'Paid',
    title: 'Paid',
  },
];

const PaymentTransactions = () => {
  const { insuranceType } = useParams<{
    insuranceType: string;
  }>();
  const [detailsModal, setDetailsModal] = useState(false);
  const [error, setError] = useState<ApolloError | undefined>(undefined);
  const [selectedTransaction, setSelectedTransaction] = useState<
    ClaimPaymentTransaction | undefined
  >(undefined);

  const mappedInsuranceType =
    InsuranceTypeFromPathMapping[insuranceType as InsuranceTypeFromPath];

  const { validateClaimAmounts } =
    useClaimAmountsValidation(mappedInsuranceType);

  if (!insuranceTypeFromPath.includes(insuranceType as InsuranceTypeFromPath)) {
    return <Page404 />;
  }

  const mapTransactionStatus = (status: string): Status => {
    return {
      text: {
        text: upperFirst(lowerCase(status)),
        id: status,
      },
      color: transactionStatusColorMaper[status as TransactionStatus],
    };
  };

  const tableFilters: FilterConfig[] = [
    {
      options: statusOptions,
      filterType: 'MULTIPLE',
      label: 'Status',
      id: 'filterStatus',
      placeholder: 'Select status(es)',
    },
  ];

  const rowConfig: RowConfig = {
    resourceOnClick: (data: ClaimPaymentTransaction) => {
      setSelectedTransaction(data);
      setDetailsModal(true);
    },
    cells: [
      {
        type: 'CHECK',
        props: {
          getCheck: (data: ClaimPaymentTransaction) => {
            const statusNotPaidOrIgnored =
              data?.status !== 'PAID' && data?.status !== 'IGNORED';
            const validClaimIban = validateIBAN(data.userClaim?.iban);
            const validAmount = validateClaimAmounts(data.userClaim, data);
            const hasPolicyNumber = !!data.userPolicy;
            const hasClaimNumber = data.userClaim
              ? !!data.userClaim.claimNumber
              : true;

            let check: CheckBadgeType = 'empty';
            if (!validAmount && statusNotPaidOrIgnored) {
              check = 'exclamation';
            }
            if (
              statusNotPaidOrIgnored &&
              (!validClaimIban || !hasPolicyNumber || !hasClaimNumber)
            ) {
              check = 'error';
            }

            let badgeCaption = '';
            if (!validClaimIban && statusNotPaidOrIgnored)
              badgeCaption = 'Invalid IBAN';
            if (!hasPolicyNumber && statusNotPaidOrIgnored)
              badgeCaption = 'Policy number not found';
            if (!hasClaimNumber && statusNotPaidOrIgnored)
              badgeCaption = 'Missing claim number';
            if (!validAmount && statusNotPaidOrIgnored)
              badgeCaption = 'Mismatch refund values';

            return {
              check,
              badgeCaption,
            };
          },
        },
        textColor: 'dark',
      },
      {
        type: 'TEXT',
        textColor: 'dark',
        props: {
          getText: (data: ClaimPaymentTransaction) =>
            data.userPolicy && data.userPolicy.user
              ? `${data.userPolicy?.user.firstName} ${data.userPolicy?.user.lastName}`
              : '',
        },
      },
      {
        type: 'STATUS',
        textColor: 'dark',
        props: {
          getStatus: (data: ClaimPaymentTransaction) =>
            mapTransactionStatus(data.status),
        },
      },
      {
        type: 'DATE',
        textColor: 'light',
        props: {
          getDate: (data: ClaimPaymentTransaction) => data.emittedAt,
        },
      },
      {
        type: 'TEXT',
        textColor: 'light',
        props: {
          getText: (data: ClaimPaymentTransaction) =>
            data.qontoTransaction?.amount
              ? englishFormattedEuroCurrency(data.qontoTransaction?.amount)
              : '',
        },
      },
      {
        type: 'TEXT',
        textColor: 'light',
        props: {
          getText: (data: ClaimPaymentTransaction) =>
            data.userClaim?.claimNumber ?? '',
        },
      },
      {
        type: 'TEXT',
        textColor: 'light',
        props: {
          getText: (data: ClaimPaymentTransaction) =>
            data.userClaim?.amount
              ? englishFormattedEuroCurrency(data.userClaim?.amount)
              : '',
        },
      },
      {
        type: 'TEXT',
        textColor: 'light',
        props: {
          getText: (data: ClaimPaymentTransaction) =>
            data.userClaim?.refundAmount
              ? englishFormattedEuroCurrency(data.userClaim?.refundAmount)
              : '',
        },
      },
      {
        type: 'LINK',
        textColor: 'dark',
        props: {
          getLink: (data: ClaimPaymentTransaction) => ({
            label: data.userClaim ? 'Policy' : '',
            href: data.userPolicy
              ? policyRouteMapper[data.insuranceType](data.userPolicy?.id ?? '')
              : '',
          }),
        },
      },
      {
        type: 'LINK',
        textColor: 'dark',
        props: {
          getLink: (data: ClaimPaymentTransaction) => ({
            label: data.userClaim ? 'Claim' : '',
            href: data.userClaim
              ? retrieveClaimsDetailsPath(
                  getInsuranceTypePathName(data.insuranceType),
                  data.userClaim.id,
                  getCountry(data.insuranceType)
                )
              : '',
          }),
        },
      },
    ],
  };

  return (
    <OverviewPage
      title={`${capitalize(lowerCase(insuranceType))} payments`}
      current={`payments-${insuranceType}`}
      error={error}
    >
      <Table
        title={capitalize(lowerCase(insuranceType))}
        itemsPerPage={NUMBER_OF_TRANSACTIONS_PER_PAGE}
        searchPlaceholder="Search by customer name, policy number, claim number"
        emptyStateLabel={{
          title: 'There are no transactions yet.',
          description: 'It looks like there are no Qonto transactions yet.',
        }}
        filterConfig={tableFilters}
        actionButton={<ExportButton insuranceType={mappedInsuranceType} />}
        query={GET_QONTO_TRANSACTIONS}
        headers={tableHeaders}
        rowConfig={rowConfig}
        onError={setError}
        queryVariables={{
          insuranceType: mappedInsuranceType,
        }}
      />
      <PaymentSummaryModal
        open={detailsModal}
        setOpen={setDetailsModal}
        transaction={selectedTransaction}
        insuranceType={mappedInsuranceType}
      />
    </OverviewPage>
  );
};

export default PaymentTransactions;
