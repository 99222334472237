import { CurrencyEuroIcon } from '@heroicons/react/outline';
import { retrieveClaimsOverviewPath } from 'pages/claims/template/utils/utils';
import { countries, Country } from 'shared/countries/models';

import type { NavigationLink, NavigationLinkChild } from '../NavBar';

const healthClaimsLinks = [
  {
    name: 'Private health',
    href: retrieveClaimsOverviewPath('private-health'),
    id: 'claims-private-health',
  },
  {
    name: 'Expat health',
    href: retrieveClaimsOverviewPath('expat-health'),
    id: 'claims-expat-health',
  },
];

const nonHealthClaimsLinks = () => [
  {
    name: 'Dental',
    href: retrieveClaimsOverviewPath('dental'),
    id: 'claims-dental',
  },
  {
    name: 'Liability',
    href: retrieveClaimsOverviewPath('liability'),
    id: 'claims-liability',
  },
  {
    name: 'Household',
    href: retrieveClaimsOverviewPath('household'),
    id: 'claims-household',
  },
  {
    name: 'Travel',
    href: retrieveClaimsOverviewPath('travel'),
    id: 'claims-travel',
  },
  {
    name: 'Pet health',
    href: retrieveClaimsOverviewPath('pet-health'),
    id: 'claims-pet-health',
  },
  {
    name: 'Disability',
    href: retrieveClaimsOverviewPath('disability'),
    id: 'claims-disability',
  },
  {
    name: 'Company health',
    href: retrieveClaimsOverviewPath('company-health'),
    id: 'claims-company-health',
  },
  {
    name: 'Company life',
    href: retrieveClaimsOverviewPath('company-life'),
    id: 'claims-company-life',
  },
  {
    name: 'Bike',
    href: retrieveClaimsOverviewPath('bike'),
    id: 'claims-bike',
  },
  {
    name: 'Legal',
    href: retrieveClaimsOverviewPath('legal'),
    id: 'claims-legal',
  },
  {
    name: 'Dog liability',
    href: retrieveClaimsOverviewPath('dog-liability'),
    id: 'claims-dog-liability',
  },
];

export const expatLTClaimsLinks: Record<Country, NavigationLinkChild> =
  countries.reduce(
    (acc, country) => ({
      ...acc,
      [country]: {
        name: 'Expat health long-term',
        href: retrieveClaimsOverviewPath('expat-health-long-term', country),
        id: `claims-expat-health-long-term-${country}`,
      },
    }),
    {} as Record<Country, NavigationLinkChild>
  );

export const germanyClaimsLinks: NavigationLink = {
  name: 'Claims',
  icon: CurrencyEuroIcon,
  id: 'claims-de',
  children: [
    ...healthClaimsLinks,
    expatLTClaimsLinks.de,
    ...nonHealthClaimsLinks().sort((a, b) => a.name.localeCompare(b.name)),
  ],
};

export const spainClaimsLink: NavigationLink = {
  name: 'Claims',
  icon: CurrencyEuroIcon,
  id: 'claims-es',
  children: [
    {
      name: 'Expat health',
      href: retrieveClaimsOverviewPath('expat-health-es', 'es'),
      id: 'claims-expat-health',
    },
    expatLTClaimsLinks.es,
  ],
};

export const createRegionalClaimsLinks = (
  country: Country
): NavigationLink => ({
  name: 'Claims',
  icon: CurrencyEuroIcon,
  id: `claims-${country}`,
  children: [expatLTClaimsLinks[country]],
});
