import { gql } from '@apollo/client';

export const GET_SCANNED_DOCUMENTS = gql`
  query getScannedDocuments(
    $offset: Float!
    $limit: Float!
    $searchString: String!
    $sortColumn: String!
    $sortOrder: String!
    $filterStatus: [String]!
    $filterCategories: [String]!
    $filterView: String!
    $filterSender: String!
    $filterPolicyType: String
    $documentDateRangeStart: String
    $documentDateRangeEnd: String
  ) {
    scannedDocuments(
      offset: $offset
      limit: $limit
      searchString: $searchString
      sortColumn: $sortColumn
      sortOrder: $sortOrder
      filterStatus: $filterStatus
      filterCategories: $filterCategories
      filterView: $filterView
      filterSender: $filterSender
      filterPolicyType: $filterPolicyType
      documentDateRangeStart: $documentDateRangeStart
      documentDateRangeEnd: $documentDateRangeEnd
    ) {
      archivedAt
      categories {
        id
        name
        attributes
      }
      createdAt
      documentDate
      id
      meta {
        source
      }
      policyNumber
      recipientName
      senderName
      status
      subject
      userPolicyId
      visibleInUserAccount
    }
    totalScannedDocuments(
      searchString: $searchString
      filterStatus: $filterStatus
      filterCategories: $filterCategories
      filterView: $filterView
      filterSender: $filterSender
      filterPolicyType: $filterPolicyType
      documentDateRangeStart: $documentDateRangeStart
      documentDateRangeEnd: $documentDateRangeEnd
    )
  }
`;

export const GET_SCANNED_DOCUMENTS_COUNT = gql`
  query getScannedDocumentsCount {
    unprocessedScannedDocumentsCount
    totalNewDocumentsCount: getNewDocumentsCount
    newAdvigonDocumentsCount: getNewDocumentsCount(senderName: "Advigon")
    newBarmeniaDocumentsCount: getNewDocumentsCount(senderName: "Barmenia")
    newHallescheDocumentsCount: getNewDocumentsCount(senderName: "Hallesche")
  }
`;

export const GET_SCANNED_DOCUMENT_BY_ID = gql`
  query getScannedDocumentById($scannedDocumentId: String!) {
    scannedDocument(scannedDocumentId: $scannedDocumentId) {
      archivedAt
      blobName
      categories {
        id
        name
        attributes
      }
      processed
      createdAt
      documentDate
      friendlyName
      id
      meta {
        source
      }
      policyNumber
      recipientName
      senderName
      status
      subject
      textContent
      url
      userPolicyId
      relatedUserPolicy {
        id
        insuranceType
        policyNumber
        regionOfPurchase
        user {
          id
          firstName
          lastName
          email
        }
      }
      userPolicy {
        id
        insuranceType
        policyNumber
        regionOfPurchase
        user {
          id
          firstName
          lastName
          email
        }
      }
      connectedUserClaims {
        id
        status
        amount
        claimType {
          type
        }
      }
      visibleInUserAccount
    }
  }
`;

export const GET_CLAIMS_BY_POLICY_ID = gql`
  query userClaimsByPolicyId($policyId: String!) {
    userClaimsByPolicyId(policyId: $policyId) {
      id
      status
      amount
      claimType {
        type
      }
      createdAt
    }
  }
`;

export const GET_CLAIMS_BY_SIMILAR_POLICY_NUMBER = gql`
  query userClaimsBySimilarPolicyNumber($policyNumber: String!) {
    userClaimsBySimilarPolicyNumber(policyNumber: $policyNumber) {
      id
      status
      amount
      claimType {
        type
      }
      createdAt
      userPolicies {
        id
        policyNumber
      }
    }
  }
`;

export const GET_DENTAL_POLICY_BY_NAME = gql`
  query dentalPolicyByName($name: String!) {
    dentalPolicyByName(name: $name) {
      id
      insuranceType
      policyNumber
      dentalPublicStatus
      questionnaires
      user {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const GET_PRIVATE_POLICY_BY_NAME = gql`
  query privatePolicyByName($name: String!) {
    privatePolicyByName(name: $name) {
      id
      insuranceType
      policyNumber
      publicStatus
      questionnaire {
        id
        answers {
          personalInfo {
            name {
              firstName
              lastName
            }
            coverageStartDate
          }
        }
      }
      user {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const GET_POLICIES_BY_POLICY_NUMBERS = gql`
  query policiesByPolicyNumbers($policyNumbers: [String]) {
    policiesByPolicyNumbers(policyNumbers: $policyNumbers) {
      id
      policyNumber
      insuranceType
      questionnaires
      user {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const GET_POLICIES_BY_POLICY_NUMBERS_FOR_LIST = gql`
  query policiesByPolicyNumbers($policyNumbers: [String]) {
    policiesByPolicyNumbers(
      policyNumbers: $policyNumbers
      onlyListViewInfo: true
    ) {
      id
      policyNumber
      insuranceType
      publicStatus
    }
  }
`;
