import { useTranslation } from '@getpopsure/i18n-react';
import classNames from 'classnames';
import Badge from 'components/Badge/Badge';
import CalendarDatePicker from 'components/CalendarDatePicker';
import FileDropzone from 'components/FileDropzone';
import { CurrencyInput } from 'components/Input/CurrencyInput';
import Input from 'components/Input/Input';
import Modal from 'components/Modal';
import RadioButton from 'components/RadioButton';
import { SelectButtonOption } from 'components/SelectButton';
import SelectMenu, { SelectMenuOption } from 'components/SelectMenu';
import TextArea from 'components/TextArea/TextArea';
import dayjs from 'dayjs';
import { Issue } from 'models/issue';
import UnresolvedIssues from 'pages/policies/publicHealth/components/UnresolvedIssues/UnresolvedIssues';
import { useEffect, useState } from 'react';
import { retrieveZodErrorMessages } from 'shared/errorHandling/retrieveZodErrorMessages';
import { ZodObject, ZodRawShape } from 'zod';

import {
  StatusUpdateFormDependencies,
  StatusUpdateFormQuestion,
} from './models';
import * as styles from './styles';
import { filterAnswersByDependency, handleDependencyCheck } from './utils';

interface Props<Form, T> {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  onSubmit: (form?: Partial<Form>) => void;
  form: StatusUpdateFormQuestion[];
  status?: SelectButtonOption<T>;
  validationSchema: ZodObject<ZodRawShape>;
  bottomText?: string;
  bottomTextDependencies?: StatusUpdateFormDependencies[];
  title?: string;
  loading: boolean;
  showIssuesSection?: boolean;
  openIssues?: Issue[];
}

export const StatusUpdateModal = <
  Form extends Record<string, any>,
  T extends string
>({
  isOpen,
  setOpen,
  onSubmit,
  form,
  status,
  validationSchema,
  bottomText,
  bottomTextDependencies,
  title,
  loading,
  showIssuesSection,
  openIssues,
}: Props<Form, T>) => {
  const { t } = useTranslation();
  const [formAnswers, setFormAnswers] = useState<Partial<Form>>();
  const [error, setError] = useState<Partial<Record<keyof Form, string>>>();

  useEffect(() => {
    if (!isOpen) {
      setFormAnswers(undefined);
      setError(undefined);
    }
    if (isOpen) {
      const defaultFormAnswers = form.reduce(
        (acc, { id, data }) => ({ ...acc, [id]: data.defaultValue }),
        {}
      );
      setFormAnswers({ ...defaultFormAnswers });
    }
  }, [isOpen]);

  const handleOnSubmit = () => {
    const filteredFormAnswers = filterAnswersByDependency(form, formAnswers);

    // TODO: [KONG] Remove after converting CurrencyInput value to number
    const currencyValueValidation = filteredFormAnswers?.payoutAmount
      ? { payoutAmount: Number(filteredFormAnswers.payoutAmount) }
      : {};

    const subscriptionPriceValidation = filteredFormAnswers?.price
      ? { price: Number(filteredFormAnswers.price) }
      : {};

    const transformedFormAnswers = {
      ...filteredFormAnswers,
      ...currencyValueValidation,
      ...subscriptionPriceValidation,
    };

    const validationSchemaFilter = formAnswers
      ? Object.keys(formAnswers).reduce((schemaFilter, key) => {
          if (key in transformedFormAnswers) {
            return { ...schemaFilter, [key]: true };
          }

          return schemaFilter;
        }, {})
      : {};

    const validation = validationSchema.pick(validationSchemaFilter).safeParse({
      ...transformedFormAnswers,
    });

    if (!validation.success) {
      setError(retrieveZodErrorMessages(validation.error));
      return;
    }

    onSubmit(transformedFormAnswers as Partial<Form>);
  };

  return (
    <Modal
      title={title ?? t('component.statusUpdateModal.title', 'Change status')}
      open={isOpen}
      setOpen={setOpen}
      handleConfirm={handleOnSubmit}
      loading={loading}
    >
      <>
        <div>
          <h2 className={styles.label}>
            {t('component.statusUpdateModal.label.newStatus', 'New status')}
          </h2>
          {status?.color && (
            <Badge
              className={styles.badge}
              color={status.color}
              badgeType="full"
            >
              {status.label}
            </Badge>
          )}
        </div>

        <div className={styles.formContainer}>
          {form.map(
            ({
              id,
              label,
              required,
              data,
              description,
              errorMessage,
              fieldDependencies,
            }) => {
              return (
                handleDependencyCheck(fieldDependencies, formAnswers) && (
                  <div className={styles.formContainer} key={id}>
                    <label className={styles.label} htmlFor={id}>
                      {label}{' '}
                      {!required && (
                        <span className={styles.formOptionalLabel}>
                          {t(
                            'component.statusUpdateModal.optional',
                            '(optional)'
                          )}
                        </span>
                      )}
                    </label>

                    <p className={styles.description}>{description}</p>

                    {data.type === 'UPLOAD' && (
                      <>
                        {data.fileName && (
                          <Input
                            className={styles.formInputContainer}
                            value={formAnswers?.[data.fileName.id]}
                            onChange={(e) => {
                              setFormAnswers({
                                ...formAnswers,
                                [data.fileName?.id as string]: e.target.value,
                              } as Form[string]);
                            }}
                            color="gray"
                            placeholder={data.fileName.placeholder}
                            defaultValue={data.fileName.defaultValue ?? ''}
                          />
                        )}
                        <div className={styles.formInputContainer}>
                          <FileDropzone
                            uploadedFile={formAnswers?.[id]}
                            onFileSelect={(file) => {
                              setFormAnswers({
                                ...formAnswers,
                                [id]: file,
                              } as Form[string]);
                            }}
                          />
                        </div>
                      </>
                    )}

                    {data.type === 'CURRENCY' && (
                      <div className={styles.formInputContainer}>
                        <CurrencyInput
                          onChange={(value) =>
                            setFormAnswers({
                              ...formAnswers,
                              [id]: value,
                            } as Form[string])
                          }
                          color="gray"
                          placeholder={data.placeholder}
                          value={
                            formAnswers?.[id] ? String(formAnswers[id]) : ''
                          }
                          error={!!error?.[id]}
                        />
                      </div>
                    )}

                    {data.type === 'DATE' && (
                      <CalendarDatePicker
                        className={classNames(
                          styles.formInputContainer,
                          styles.dateInput
                        )}
                        selectedDate={
                          formAnswers?.[id]
                            ? new Date(formAnswers?.[id] as string)
                            : undefined
                        }
                        onChange={(date) => {
                          setFormAnswers({
                            ...formAnswers,
                            [id]: dayjs(date as Date).format('YYYY-MM-DD'),
                          } as Form[string]);
                        }}
                        maxDate={data.max ? new Date(data.max) : undefined}
                        minDate={data.min ? new Date(data.min) : undefined}
                      />
                    )}

                    {data.type === 'TEXT_AREA' && (
                      <TextArea
                        className={styles.formInputContainer}
                        value={formAnswers?.[id] ?? ''}
                        onChange={(e) => {
                          const { value } = e.target;

                          setFormAnswers({
                            ...formAnswers,
                            [id]: value,
                          } as Form[string]);
                        }}
                        color="gray"
                        placeholder={data.placeholder}
                        error={!!error?.[id]}
                      />
                    )}

                    {data.type === 'TEXT' && (
                      <Input
                        color="gray"
                        placeholder={data.placeholder}
                        className="mt-2"
                        value={formAnswers?.[id] ?? ''}
                        onChange={(e) => {
                          const { value } = e.target;

                          setFormAnswers({
                            ...formAnswers,
                            [id]: value,
                          } as Form[string]);
                        }}
                        error={!!error?.[id]}
                      />
                    )}

                    {data.type === 'RADIO' && (
                      <div
                        className={classNames(
                          styles.formInputContainer,
                          styles.radioButtons
                        )}
                      >
                        {data.options.map((currentValue) => {
                          return (
                            <div key={currentValue.label}>
                              <RadioButton
                                value={currentValue.label}
                                id={currentValue.label}
                                name={formAnswers?.id}
                                onChange={() => {
                                  setFormAnswers({
                                    ...formAnswers,
                                    [id]: currentValue.value,
                                  } as Form[string]);
                                }}
                                checked={
                                  currentValue.value === formAnswers?.[id]
                                }
                                error={!!error?.[id]}
                              >
                                {currentValue.label}
                              </RadioButton>
                            </div>
                          );
                        })}
                      </div>
                    )}

                    {data.type === 'SELECT' && (
                      <div className={styles.formInputContainer}>
                        <SelectMenu
                          selected={data.options.find(
                            (option) => option.id === formAnswers?.[id]
                          )}
                          color="gray"
                          options={data.options}
                          placeholder={data.placeholder}
                          setSelected={(option: SelectMenuOption) => {
                            setFormAnswers({
                              ...formAnswers,
                              [id]: option.id,
                            } as Form[string]);
                          }}
                          error={!!error?.[id]}
                        />
                      </div>
                    )}

                    {error?.[id] && (
                      <p className={styles.error}>
                        {errorMessage ?? error[id]}
                      </p>
                    )}
                  </div>
                )
              );
            }
          )}
        </div>

        {showIssuesSection && openIssues && openIssues.length !== 0 && (
          <div className={styles.formContainer}>
            <h1 className={styles.label}>
              {t(
                'admin.provider.policy.public.modal.changestatus.issues.title',
                'These issues will be resolved automatically'
              )}
            </h1>
            <UnresolvedIssues issues={openIssues} setOpenModal={setOpen} />
          </div>
        )}

        {bottomText &&
          handleDependencyCheck(bottomTextDependencies, formAnswers) && (
            <p className={styles.bottomText}>{bottomText}</p>
          )}
      </>
    </Modal>
  );
};
