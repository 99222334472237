import { gql } from '@apollo/client';

export const UPLOAD_DOG_LIABILITY_DOCUMENT = gql`
  mutation UploadDogLiabilityDocument(
    $policyId: String!
    $newFilename: String
    $file: Upload
  ) {
    uploadDogLiabilityDocument(
      id: $policyId
      newFilename: $newFilename
      file: $file
    ) {
      id
      url
      name
    }
  }
`;

export const DELETE_DOG_LIABILITY_DOCUMENT = gql`
  mutation DeleteDogLiabilityDocument(
    $id: String!
    $blobName: String!
    $documentType: String!
  ) {
    deleteDogLiabilityPolicyFile(
      id: $id
      blobName: $blobName
      type: $documentType
    ) {
      id
    }
  }
`;
