export const markSelectionRange = (
  startIndex: number,
  endIndex: number,
  markAsSelected: boolean
): Record<number, boolean> => {
  const selectionObj: Record<number, boolean> = {};

  const targetValue = markAsSelected;

  for (let i = startIndex; i < endIndex; i++) {
    selectionObj[i] = targetValue;
  }
  return selectionObj;
};
