import { capitalizeName } from '@getpopsure/public-utility';
import { PolicyTemplateData } from 'pages/policies/template/models/PolicyTemplateData';
import { insuranceNameMapper } from 'shared/insurances/insuranceNameMapper';

import { NUMBER_OF_POLICIES_PER_PAGE } from '../template/constants';
import { TemplateInsuranceType } from '../template/models/InsuranceTypes';
import { getDbInsuranceType } from '../template/utils';
import { fileTypeOptions, getDocumentSections } from './data/documentsData';
import { getOverviewTabCards } from './data/overviewTabData';
import { getQuestionnaireData } from './data/questionnaireData';
import {
  expatSpainNextStatuses,
  expatSpainStatusOptions,
  extendedValidatorObject,
  getExpatSpainModals,
  retrieveModalId,
} from './data/statusData';
import { rowConfig, tableHeaders } from './data/tableData';
import { tableFilters } from './data/tableFilters';
import {
  DELETE_EXPAT_SPAIN_DOCUMENT,
  UPLOAD_EXPAT_SPAIN_DOCUMENT,
} from './graphql/document';
import { GET_EXPAT_SPAIN_POLICIES } from './graphql/policies';
import { GET_EXPAT_SPAIN_POLICY } from './graphql/singlePolicy';
import { UPDATE_EXPAT_SPAIN_QUESTIONNAIRE } from './graphql/updateQuestionnaire';
import { UPDATE_EXPAT_SPAIN_STATUS } from './graphql/updateStatus';
import { ExpatSpainPolicies } from './models/ExpatSpainPolicies';
import { ExpatSpainPolicyData } from './models/ExpatSpainPolicy';

export const getExpatSpainPoliciesTemplateData = (): PolicyTemplateData<
  ExpatSpainPolicies,
  ExpatSpainPolicyData,
  string
> => {
  const insurancePath = 'expat-health-es';
  const insuranceType = getDbInsuranceType(
    insurancePath
  ) as TemplateInsuranceType;
  const insuranceName = insuranceNameMapper[insuranceType];

  return {
    insuranceType,
    navigation: {
      current: `policies-${insurancePath}`,
    },
    queries: {
      allPolicies: GET_EXPAT_SPAIN_POLICIES,
      policy: GET_EXPAT_SPAIN_POLICY,
    },
    mutations: {
      statusChange: UPDATE_EXPAT_SPAIN_STATUS,
      uploadFile: UPLOAD_EXPAT_SPAIN_DOCUMENT,
      editQuestionnaire: UPDATE_EXPAT_SPAIN_QUESTIONNAIRE,
      deleteFile: DELETE_EXPAT_SPAIN_DOCUMENT,
    },
    copy: {
      table: {
        title: `${insuranceName} policies`,
        searchPlaceholder:
          'Search by name, email, policy number, policy id or utm source',
      },
      details: {
        pageTitle: `${insuranceName} policy - Feather Admin Panel`,
        title: insuranceName,
      },
    },
    features: {
      overviewTable: {
        numberOfRowsPerPage: NUMBER_OF_POLICIES_PER_PAGE,
        headersConfig: tableHeaders,
        rowConfig,
        filtersConfig: tableFilters,
      },
      details: {
        queryVariables: {
          insuranceType,
        },
        insuredPersonName: (data?: ExpatSpainPolicyData) =>
          capitalizeName({
            firstName: data?.expatSpainPolicy.insuredPerson?.firstName ?? '',
            lastName: data?.expatSpainPolicy.insuredPerson?.lastName ?? '',
          }) ?? '',
        overviewTab: {
          getCards: getOverviewTabCards,
        },
      },
      statusChange: {
        statusOptions: expatSpainStatusOptions,
        statusChangeOptions: expatSpainNextStatuses,
        getModals: getExpatSpainModals,
        retrieveModalId,
        extendedValidatorObject,
        additionalQueryVariables: { insuranceType },
      },
      documents: {
        uploadOptions: fileTypeOptions,
        getDocumentSections,
      },
      questionnaire: {
        cardTitle: 'Personal information',
        editable: true,
        getQuestionnaireData,
      },
    },
  };
};
