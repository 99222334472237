import { gql } from '@apollo/client';

export const GET_HR_COMPANY_PENSION_POLICY = gql`
  query HrCompanyPensionPolicy($id: String!) {
    hrCompanyPensionPolicy(id: $id) {
      id
      policyNumber
      publicStatus
      providerId
      startDate
      user {
        id
        firstName
        lastName
        dateOfBirth
      }
      pensionDetails {
        ownContribution
        employerContribution
        totalContribution
        yearlyContribution
        financingType
        paymentMethod
      }
      policyDocuments {
        url
        id
        name
        blobName
        isRegeneratable
        resizedUrl
      }
    }
  }
`;
