import { useMutation, useReactiveVar } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import Button from 'components/Button';
import { SelectMenuOption } from 'components/SelectMenu';
import { DocumentNode } from 'graphql';
import { useState } from 'react';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';

import FileUploadForm from './FileUploadForm';

interface FileUploadProps {
  fileTypeOptions: SelectMenuOption[];
  mutation: DocumentNode;
  policyId?: string;
  getPolicyMutation: DocumentNode;
  extraVariables?: Record<string, string>;
  documentCategoryOptions?: SelectMenuOption[];
}

// TODO: [KONG] Refactor collecting variables
const FileUpload = ({
  fileTypeOptions,
  mutation,
  policyId,
  getPolicyMutation,
  extraVariables,
  documentCategoryOptions,
}: FileUploadProps) => {
  const { t } = useTranslation();

  const alertBannersState = useReactiveVar(alertBanners);

  const handleCompletedUploadFile = () => {
    setOpenForm(false);

    const newAlertBanner: AlertBannerState = {
      id: uuidv4(),
      type: 'SUCCESS',
      message: t(
        'admin.alert.documentstatus.success.uploaded.header',
        'Document successfully uploaded.'
      ),
    };

    setNewAlertBanner({ state: alertBannersState, newAlertBanner });
  };
  const [uploadFile, { loading, error, reset }] = useMutation(mutation, {
    refetchQueries: [getPolicyMutation],
    onCompleted: handleCompletedUploadFile,
  });

  const [openForm, setOpenForm] = useState(false);

  const handleUploadFile = (variables: {
    type: string;
    newFilename?: string;
    file: File;
  }) => {
    uploadFile({ variables: { policyId, ...variables, ...extraVariables } });
  };

  return (
    <>
      <Button
        buttonType="secondary"
        onClick={() => setOpenForm(true)}
        className="w-[88px]"
      >
        {t('admin.documents.upload', 'Upload')}
      </Button>
      {openForm && (
        <FileUploadForm
          open={openForm}
          setOpen={setOpenForm}
          fileTypeOptions={fileTypeOptions}
          uploadFile={handleUploadFile}
          loading={loading}
          error={error}
          reset={reset}
          documentCategoryOptions={documentCategoryOptions}
        />
      )}
    </>
  );
};

export default FileUpload;
