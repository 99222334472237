import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import Card from 'components/Card';
import CardSection from 'components/Card/CardSection';
import { dentalClaimsData } from 'pages/claims/dental';
import { expatHealthClaimsData } from 'pages/claims/expatHealth';
import { expatSpainHealthClaimsData } from 'pages/claims/expatSpain';
import { genericClaimsData } from 'pages/claims/generic';
import { privateHealthClaimsData } from 'pages/claims/privateHealth';
import { ClaimsTemplateData } from 'pages/claims/template/models';
import {
  ClaimData,
  ScannedDocument,
} from 'pages/scannedDocuments/scannedDocuments.types';
import { getClaimDetailsBaseURLFromInsuranceType } from 'pages/scannedDocuments/scannedDocuments.utils';
import { combinedClaimTypeMapper } from 'shared/claims/mappers';
import { insuranceIconMapper } from 'shared/insurances/insuranceIconMapper';
import { InsuranceType } from 'shared/insurances/types';

import { AttachToClaim } from './AttachToClaim/ScannedDocument.AttachToClaim';
import { CreateAndConnect } from './CreateAndConnect/ScannedDocument.CreateAndConnect';
import * as styles from './ScannedDocument.ConnectedClaims.styles';

const claimTemplateDataLookup: Partial<
  Record<InsuranceType, ClaimsTemplateData<any, any>>
> = {
  PRIVATE_HEALTH: privateHealthClaimsData,
  DENTAL: dentalClaimsData,
  INCOMING: expatHealthClaimsData,
  INCOMING_ES: expatSpainHealthClaimsData,
  INCOMING_EU: genericClaimsData('INCOMING_EU'),
  PET_HEALTH: genericClaimsData('PET_HEALTH'),
};

export const ConnectedClaims = ({
  scannedDocument,
  relatedClaims,
}: {
  scannedDocument: ScannedDocument;
  relatedClaims?: ClaimData[];
}) => {
  const hasConnectedClaims = scannedDocument.connectedUserClaims?.length;
  const relatedPolicy = scannedDocument.relatedUserPolicy;

  const matchingPolicy =
    scannedDocument.userPolicy ?? scannedDocument.relatedUserPolicy;

  const insuranceType = matchingPolicy?.insuranceType;

  const claimsTemplateData =
    insuranceType && claimTemplateDataLookup[insuranceType as InsuranceType];

  return (
    <Card
      title="Claims"
      boldTitle={false}
      actionButton={
        <>
          {claimsTemplateData && (
            <CreateAndConnect
              scannedDocument={scannedDocument}
              claimsTemplateData={claimsTemplateData}
            />
          )}
          <AttachToClaim scannedDocument={scannedDocument} />
        </>
      }
    >
      {hasConnectedClaims ? (
        scannedDocument.connectedUserClaims?.map((claim) => (
          <a
            href={
              getClaimDetailsBaseURLFromInsuranceType(
                relatedPolicy?.insuranceType,
                claim.id
              ) ?? ''
            }
            key={claim.id}
            target="_blank"
            rel="noreferrer"
          >
            <CardSection className={styles.cardContent}>
              <div className={styles.iconAndTextWrapper}>
                {relatedPolicy?.insuranceType && (
                  <img
                    className={styles.claimIcon}
                    src={insuranceIconMapper[relatedPolicy.insuranceType]}
                    alt=""
                  />
                )}
                <p>
                  {`${claim.claimType
                    ?.map(({ type }) => combinedClaimTypeMapper[type])
                    .join(', ')}`}
                </p>
              </div>

              <p className={styles.amountText}>
                {`${
                  claim.amount
                    ? englishFormattedEuroCurrency(claim.amount, true)
                    : '€ ---'
                }`}
              </p>
            </CardSection>
          </a>
        ))
      ) : (
        <div className={styles.noDocumentsContainer}>
          {(relatedClaims?.length ?? 0) > 0 ? (
            <p className={styles.noDocuments}>No claims connected yet.</p>
          ) : (
            <p className={styles.noDocuments}>
              No claims to connect to could be found.
            </p>
          )}
        </div>
      )}
    </Card>
  );
};
