import { Document } from 'components/DocumentsCard';

export const mapDocuments = <DocumentType>(
  documents: {
    id?: string;
    name: string;
    url: string;
    blobName?: string;
  }[],
  canDelete: boolean,
  filename?: string,
  documentType?: DocumentType
): Document<DocumentType>[] => {
  return documents.map(({ url, id, name, blobName }) => ({
    ...(id && { id }),
    fileLink: url,
    fileName: filename ?? name,
    ...(blobName && { blobName }),
    ...(documentType && { documentType }),
    canDelete,
  }));
};

export const mapQuestionnaireDocuments = <FileUploadType>(
  documents: { type: string; url: string }[]
): Document<FileUploadType>[] => {
  return documents.map((document) => {
    let name;

    switch (document.type) {
      case 'ARCHIVE':
        name = 'Policy documents archive';
        break;
      case 'HALLESCHE_ALL_IN_ONE':
        name = 'Hallesche bundle';
        break;
      case 'ADVICE_RECORD':
        name = 'Advice record';
        break;
      case 'SUMMARY':
        name = 'Application summary';
        break;
      default:
        name = document.type;
    }
    return {
      fileLink: document.url,
      fileName: name,
    };
  });
};
