import classNames from 'classnames';

export const cell = classNames('p-[16px]', 'text-sm', 'row-cell');

export const nestedAnchor = classNames('p-[16px]', 'text-sm', 'block');

export const lightTextColor = classNames('text-gray-600');

export const darkTextColor = classNames('text-gray-900');

export const sticky = classNames('sticky', 'bg-white');

export const borderRight = classNames(
  'border-r',
  'border-gray-300',
  'table-breakpoint:border-r-0'
);

export const borderLeft = classNames(
  'border-l',
  'border-gray-300',
  'table-breakpoint:border-l-0'
);

export const divider = classNames('border-t', 'border-t-gray-200');
