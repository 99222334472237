import { gql } from '@apollo/client';

export const GET_HR_COMPANY_HEALTH_POLICY = gql`
  query HrCompanyHealthPolicy($id: String!) {
    hrCompanyHealthPolicy(id: $id) {
      id
      policyNumber
      publicStatus
      providerId
      startDate
      user {
        id
        firstName
        lastName
        dateOfBirth
      }
      healthDetails {
        employerContribution
        yearlyBudget
        preventiveHealthCareAddon
        dentalAddon
        dailySicknessAllowance
        stationaryAddon
      }
      policyDocuments {
        url
        id
        name
        blobName
        isRegeneratable
        resizedUrl
      }
    }
  }
`;
