import classNames from 'classnames';

export const container = classNames('w-full');

export const headerContainer = classNames(
  'flex',
  'animate-appear-in',
  'items-center',
  'justify-between'
);
export const header = classNames(
  'text-lg',
  'font-bold',
  'leading-6',
  'text-gray-900'
);

export const addNoteContainer = classNames('w-[752px]');
export const addNoteInnerContainer = classNames(
  'relative',
  'animate-appear-in'
);
export const addNoteTextArea = classNames(
  'mt-[32px]',
  'max-h-[170px]',
  'min-h-[85px]'
);
export const addNoteLoader = classNames(
  'mr-[5px]',
  'h-[20px]',
  'w-[20px]',
  'animate-spin'
);

export const pinnedNotesSection = classNames(
  'mt-[32px]',
  'animate-[appear-in-low_1s_both]'
);
export const allNotesSection = classNames(
  'mt-[32px]',
  'animate-[appear-in-low_1.3s_both]'
);

export const createNoteButton = classNames(
  'absolute',
  'bottom-[20px]',
  'right-[16px]',
  'rotate-90',

  'h-[24px]',
  'w-[24px]',

  'text-gray-500',
  'bg-indigo-100',

  'hover:text-indigo-500',

  'focus:text-indigo-500',
  'focus:outline-none',
  'focus:ring-2',
  'focus:ring-offset-2',
  'focus:ring-indigo-500',

  'rounded-[4px]',

  'flex',
  'justify-center',
  'items-center'
);

export const disabledCreateNoteButton = classNames(
  'cursor-default',
  'opacity-50',
  'pointer-events-none'
);

export const createNoteButtonIcon = classNames('h-[16px]', 'w-[16px]');
