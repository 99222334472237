import {
  ApolloError,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import { BadgeColor } from 'components/Badge/Badge';
import Button from 'components/Button';
import { CheckBadgeType } from 'components/CheckBadge/CheckBadge';
import DropdownButton from 'components/DropdownButton/DropdownButton';
import OverviewPage from 'components/OverviewPage';
import { SelectButtonOption } from 'components/SelectButton';
import { EmptyStateMessage } from 'components/Table/TableEmptyState';
import ColumnHeader from 'components/TableRefactor/ColumnHeader';
import { FilterConfig } from 'components/TableRefactor/Filters/types';
import SortableColumnHeader from 'components/TableRefactor/SortableColumnHeader';
import Table from 'components/TableRefactor/Table';
import {
  HeaderConfig,
  RowConfig,
  ViewConfig,
} from 'components/TableRefactor/types';
import useQueryParams from 'hooks/useQueryParams';
import { useState } from 'react';
import { generatePath } from 'react-router-dom';
import { paths } from 'routes/definedPaths';
import {
  countries,
  countryIconMapper,
  countryNameMapper,
} from 'shared/countries/models';
import {
  dentalBadgeColorMapper,
  dentalStatusMapper,
} from 'shared/insurances/statusMappers/dental';
import {
  expatBadgeColorMapper,
  expatStatusMapper,
} from 'shared/insurances/statusMappers/expat';
import {
  expatSpainBadgeColorMapper,
  expatSpainStatusMapper,
} from 'shared/insurances/statusMappers/expatSpain';
import {
  genericBadgeColorMapper,
  genericStatusMapper,
} from 'shared/insurances/statusMappers/generic';
import {
  privateHealthBadgeColorMapper,
  privateHealthStatusMapper,
} from 'shared/insurances/statusMappers/privateHealth';
import {
  DentalStatus,
  ExpatSpainStatus,
  ExpatStatus,
  GenericStatus,
  InsuranceType,
  PrivateHealthStatus,
} from 'shared/insurances/types';

import { GET_SD_CATEGORIES } from '../categories/sdCategories.queries';
import { SDCategory } from '../categories/sdCategories.types';
import {
  getCategoryActionDotData,
  statusMapping,
} from '../scannedDocuments.mappings';
import { PROCESS_SCANNED_DOCUMENTS } from '../scannedDocuments.mutations';
import {
  GET_POLICIES_BY_POLICY_NUMBERS_FOR_LIST,
  GET_SCANNED_DOCUMENTS,
  GET_SCANNED_DOCUMENTS_COUNT,
} from '../scannedDocuments.queries';
import {
  BasePolicyWithStatus,
  ScannedDocument,
} from '../scannedDocuments.types';
import { getPolicyDetailsBaseURLFromInsuranceType } from '../scannedDocuments.utils';
import { ArchiveDocumentBulkAction } from './BulkActions/ScannedDocuments.Archive';
import { CategorizeDocumentsAction } from './BulkActions/ScannedDocuments.Categorize';
import { ChangeStatusBulkAction } from './BulkActions/ScannedDocuments.ChangeStatus';
import { RenameDocumentBulkAction } from './BulkActions/ScannedDocuments.Rename';
import { ToggleVisibility } from './BulkActions/ScannedDocuments.ToggleVisibility';
import { TriggerCioEventBulkAction } from './BulkActions/ScannedDocuments.TriggerCioEvent';
import { UnprocessDocumentBulkAction } from './BulkActions/ScannedDocuments.Unprocess';
import FilterTabs, {
  TabOption,
} from './FilterTabs/ScannedDocuments.FilterTabs';
import * as styles from './ScannedDocuments.List.styles';

export const getCombinedPolicyStatusByInsuranceType =
  (insuranceType?: InsuranceType) =>
  (
    status: string
  ): {
    text: {
      id: string;
      text: string;
    };
    color: BadgeColor;
  } => {
    switch (insuranceType) {
      case 'PRIVATE_HEALTH':
        return {
          text: {
            id: status,
            text: privateHealthStatusMapper[status as PrivateHealthStatus],
          },
          color: privateHealthBadgeColorMapper[status as PrivateHealthStatus],
        };
      case 'INCOMING':
        return {
          text: {
            id: status,
            text: expatStatusMapper[status as ExpatStatus],
          },
          color: expatBadgeColorMapper[status as ExpatStatus],
        };
      case 'DENTAL':
        return {
          text: {
            id: status,
            text: dentalStatusMapper[status as DentalStatus],
          },
          color: dentalBadgeColorMapper[status as DentalStatus] as BadgeColor,
        };
      case 'INCOMING_ES':
        return {
          text: {
            id: status,
            text: expatSpainStatusMapper[status as GenericStatus],
          },
          color: expatSpainBadgeColorMapper[
            status as GenericStatus
          ] as BadgeColor,
        };
      case 'INCOMING_EU':
        return {
          text: {
            id: status,
            text: genericStatusMapper[status as ExpatSpainStatus],
          },
          color: genericBadgeColorMapper[
            status as ExpatSpainStatus
          ] as BadgeColor,
        };
      case 'PET_HEALTH':
        return {
          text: {
            id: status,
            text: genericStatusMapper[status as GenericStatus],
          },
          color: genericBadgeColorMapper[status as GenericStatus] as BadgeColor,
        };
      default:
        break;
    }
    return {
      text: {
        id: 'contactfeather',
        text: '',
      },
      color: 'gray',
    };
  };

const DEFAULT_NUMBER_OF_DOCUMENTS_PER_PAGE = 25;

type GetScannedDocumentsData = {
  scannedDocuments: ScannedDocument[];
  totalScannedDocuments: number;
};

type GetScannedDocumentsCountData = {
  unprocessedScannedDocumentsCount: number;
  totalNewDocumentsCount: number;
  newAdvigonDocumentsCount: number;
  newBarmeniaDocumentsCount: number;
  newHallescheDocumentsCount: number;
};

type ProcessScannedDocumentsData = {
  processScannedDocuments: number;
};

type GetSDCategoriesData = {
  sdCategories: SDCategory[];
  totalSDCategoriesCount: number;
};

type GetPoliciesByPolicyNumbersData = {
  policiesByPolicyNumbers: BasePolicyWithStatus[] | null;
};

const filterStatusOptions: SelectButtonOption[] = [
  {
    title: 'New',
    label: 'New',
    id: 'NEW',
    color: 'pink',
  },
  {
    label: 'In progress',
    title: 'In progress',
    id: 'IN_PROGRESS',
    color: 'blue',
  },
  {
    title: 'Waiting',
    label: 'Waiting',
    id: 'WAITING',
    color: 'yellow',
  },
  {
    title: 'Done',
    label: 'Done',
    id: 'DONE',
    color: 'green',
  },
];
const filterViewOptions = [
  {
    id: 'all',
    label: 'All documents',
    title: 'All documents',
    show: true,
  },
  {
    id: 'actionRequired',
    label: 'Action required',
    title: 'Action required',
    show: true,
  },
  {
    id: 'archived',
    label: 'Archived',
    title: 'Archived',
    show: true,
  },
];

export const ScannedDocumentsListPage = () => {
  const querySearchParam = useQueryParams();
  const filterSender = querySearchParam.get('filterSender');
  const {
    loading: categoriesLoading,
    error: categoriesError,
    data: categoriesData,
  } = useQuery<GetSDCategoriesData>(GET_SD_CATEGORIES, {
    variables: {
      offset: 0,
      searchString: '',
      sortColumn: 'name',
      sortOrder: 'asc',
      filterSender,
    },
    notifyOnNetworkStatusChange: true,
  });

  const allCategories = categoriesData?.sdCategories;

  const filterCategoriesOptions: SelectButtonOption[] =
    allCategories?.map(({ name, id }) => ({
      title: name,
      label: name,
      id,
    })) ?? [];

  filterCategoriesOptions.push({
    title: '- UNCATEGORIZED -',
    label: '- UNCATEGORIZED -',
    id: 'OTHER',
  });

  return (
    <ScannedDocumentsListPageBase
      filterCategoriesOptions={filterCategoriesOptions}
      categoriesLoading={categoriesLoading}
      categoriesError={categoriesError}
      allCategories={allCategories}
    />
  );
};

export const ScannedDocumentsListPageBase = ({
  filterCategoriesOptions,
  categoriesLoading,
  categoriesError,
  allCategories,
}: {
  filterCategoriesOptions: SelectButtonOption[];
  categoriesLoading: boolean;
  categoriesError?: ApolloError;
  allCategories?: SDCategory[];
}) => {
  const [tableError, setTableError] = useState<ApolloError | undefined>(
    undefined
  );

  const querySearchParam = useQueryParams();
  const devMode = querySearchParam.get('devMode') === '1';

  const [selectedDocuments, setSelectedDocuments] = useState<ScannedDocument[]>(
    []
  );

  const [activeModalId, setActiveModalId] = useState('');

  const [processScannedDocuments, { loading: busyProcessingDocuments }] =
    useMutation<ProcessScannedDocumentsData>(PROCESS_SCANNED_DOCUMENTS, {
      refetchQueries: [GET_SCANNED_DOCUMENTS_COUNT, GET_SCANNED_DOCUMENTS],
    });

  const { error: countError, data: countData } =
    useQuery<GetScannedDocumentsCountData>(GET_SCANNED_DOCUMENTS_COUNT, {
      skip: categoriesLoading,
    });

  const [getPoliciesByPolicyNumbers, getPoliciesByPolicyNumbersResult] =
    useLazyQuery<GetPoliciesByPolicyNumbersData>(
      GET_POLICIES_BY_POLICY_NUMBERS_FOR_LIST
    );

  const matchingPolicies =
    getPoliciesByPolicyNumbersResult.data?.policiesByPolicyNumbers;

  const defaultEmptyStateMessage: EmptyStateMessage = {
    title: 'There are no documents yet.',
    description: 'It looks like there are no scanned documents yet.',
  };

  const tableFilters: FilterConfig[] = [
    {
      options: filterStatusOptions,
      filterType: 'MULTIPLE',
      label: 'Status',
      id: 'filterStatus',
      placeholder: 'Select status(es)',
    },
    {
      options: filterCategoriesOptions,
      filterType: 'MULTIPLE',
      label: 'Category',
      id: 'filterCategories',
      placeholder: 'Select categories',
    },
    {
      filterType: 'DATE_RANGE',
      label: 'Document date range',
      id: 'filterDocumentDate',
    },
  ];

  const tableHeaders: HeaderConfig[] = [
    {
      id: 'actionRequired',
      label: '',
      width: 'w-[40px]',
      minWidth: 'min-w-[40px]',
      sticky: false,
      threshold: 'left-0',
      border: 'none',
      component: ColumnHeader,
    },
    {
      id: 'recipientName',
      label: 'Recipient name',
      width: 'w-none',
      minWidth: 'min-w-[160px]',
      sticky: false,
      threshold: 'left-[40px]',
      border: 'none',
      component: SortableColumnHeader,
    },
    {
      id: 'special',
      label: '',
      width: 'w-none',
      minWidth: 'min-w-[0px]',
      sticky: false,
      threshold: 'left-[40px]',
      border: 'none',
      component: ColumnHeader,
    },
    {
      id: 'status',
      label: 'Status',
      width: 'w-none',
      minWidth: 'min-w-[80px]',
      sticky: false,
      threshold: 'left-[220px]',
      border: 'right',
      component: SortableColumnHeader,
    },
    {
      id: 'subject',
      label: 'Subject',
      width: 'w-none',
      minWidth: 'min-w-[160px]',
      sticky: false,
      threshold: 'left-0',
      border: 'none',
      component: SortableColumnHeader,
    },
    {
      id: 'senderName',
      label: 'Sender',
      width: 'w-none',
      minWidth: 'min-w-[120px]',
      sticky: false,
      threshold: 'left-0',
      border: 'none',
      component: SortableColumnHeader,
    },
    {
      id: 'fileLink',
      label: 'PDF',
      width: 'w-none',
      minWidth: 'min-w-[80px]',
      sticky: false,
      threshold: '',
      border: 'none',
      component: ColumnHeader,
    },
    {
      id: 'policyNumber',
      label: 'Policy number',
      width: 'w-none',
      minWidth: 'min-w-[151px]',
      sticky: false,
      threshold: 'left-[340px]',
      border: 'none',
      component: SortableColumnHeader,
    },
    {
      id: 'documentDate',
      label: 'Document date',
      width: 'w-none',
      minWidth: 'min-w-[120px]',
      sticky: false,
      threshold: '',
      border: 'none',
      component: SortableColumnHeader,
    },
    {
      id: 'categories',
      label: 'Category',
      width: 'w-none',
      minWidth: 'min-w-[140px]',
      sticky: false,
      threshold: '',
      border: 'none',
      component: ColumnHeader,
    },
    {
      id: 'policyStatus',
      label: 'Policy',
      width: 'w-none',
      minWidth: 'min-w-[20px]',
      sticky: false,
      threshold: '',
      border: 'none',
      component: ColumnHeader,
    },
    {
      id: 'visibleInUserAccount',
      label: 'Visible?',
      width: 'w-none',
      minWidth: 'min-w-[40px]',
      sticky: false,
      threshold: '',
      border: 'none',
      component: SortableColumnHeader,
    },
  ];

  const expatEUCountries = countries
    .filter((country) => !['eu', 'de', 'es'].includes(country))
    .sort();

  const filterExpatEUSubOptions: TabOption[] = expatEUCountries.map(
    (country) => ({
      id: country.toUpperCase(),
      label: countryNameMapper[country],
      icon: countryIconMapper[country],
    })
  );

  const filterSenderTabOptions: TabOption[] = [
    {
      label: `All${
        countData?.totalNewDocumentsCount
          ? ` (${countData.totalNewDocumentsCount})`
          : ''
      }`,
      id: 'all',
    },
    {
      label: `Advigon${
        countData?.newAdvigonDocumentsCount
          ? ` (${countData.newAdvigonDocumentsCount})`
          : ''
      }`,
      id: 'advigonOnly',
    },
    {
      label: `Barmenia${
        countData?.newBarmeniaDocumentsCount
          ? ` (${countData.newBarmeniaDocumentsCount})`
          : ''
      }`,
      id: 'barmeniaOnly',
    },
    {
      label: `Hallesche${
        countData?.newHallescheDocumentsCount
          ? ` (${countData.newHallescheDocumentsCount})`
          : ''
      }`,
      id: 'hallescheOnly',
    },
  ];

  const senderFiltersWithPolicyFilter = ['advigonOnly', 'barmeniaOnly'];

  const filterPolicyTypeTabOptions: TabOption[] = [
    {
      id: 'ALL',
      label: 'All',
    },
    {
      id: 'INCOMING',
      label: countryNameMapper.de,
      icon: countryIconMapper.de,
    },
    {
      id: 'INCOMING_ES',
      label: countryNameMapper.es,
      icon: countryIconMapper.es,
    },
    {
      id: 'INCOMING_EU',
      label: countryNameMapper.eu,
      icon: countryIconMapper.eu,
      subFilterOptions: filterExpatEUSubOptions,
      subFilterPlaceholder: 'Select country',
    },
  ];

  const filterPolicyTypeTabOptionsBarmenia: TabOption[] = [
    {
      id: 'ALL',
      label: 'All',
    },
    {
      id: 'DENTAL',
      label: 'Dental',
    },
    {
      id: 'PET_HEALTH',
      label: 'Pet health',
    },
  ];

  const documentIds = selectedDocuments.map(({ id }) => id);

  const isOnArchivedView = querySearchParam.get('filterView') === 'archived';

  const hideDocumentsMode = selectedDocuments.some(
    ({ visibleInUserAccount }) => visibleInUserAccount
  );

  const showTriggerCioEventButton = selectedDocuments.some(({ categories }) =>
    categories.some((c) => c.attributes.customerIoEvent)
  );

  const currentFilterSender = querySearchParam.get('filterSender') ?? 'all';

  const getRowConfig = ({
    relatedPolicies,
  }: {
    relatedPolicies?: BasePolicyWithStatus[] | null;
  }): RowConfig => ({
    getResourceLink: (sDoc: ScannedDocument) => {
      return generatePath(paths.scannedDocuments.details.path, {
        id: sDoc.id,
      });
    },
    cells: [
      {
        type: 'DOT',
        textColor: 'dark',
        props: {
          getDot: (sDoc: ScannedDocument) => {
            const {
              data: active,
              badgeColor,
              badgeCaption,
            } = getCategoryActionDotData({
              allCategories: allCategories ?? [],
              documentCategoryNames: sDoc.categories.map(({ name }) => name),
              status: sDoc.status,
            });
            return {
              active,
              badgeColor,
              badgeCaption,
            };
          },
        },
      },
      {
        type: 'TEXT',
        textColor: 'light',
        props: {
          getText: (sDoc: ScannedDocument) => sDoc.recipientName || '-',
        },
      },
      {
        type: 'TEXT_WITH_CHECK',
        textColor: 'light',
        props: {
          getTextWithCheck: (sDoc: ScannedDocument) => {
            const customerIoEvents = sDoc.categories.flatMap((c) =>
              c.attributes.customerIoEvent ? [c.attributes.customerIoEvent] : []
            );
            const documentHasCustomerIoEvent = customerIoEvents.length > 0;
            const customerIoCaption = documentHasCustomerIoEvent
              ? `CIO: ${customerIoEvents.join(', ')}`
              : '';

            const sourceCaption = sDoc.meta?.source
              ? `Source: ${sDoc.meta.source}`
              : '';

            const specialCaption = [customerIoCaption, sourceCaption]
              .filter(Boolean)
              .join(' | ');

            const specialBadge = documentHasCustomerIoEvent
              ? 'chat'
              : sDoc.meta?.source;

            const hasMultipleCaptions = customerIoCaption && sourceCaption;
            return {
              check: specialBadge as CheckBadgeType,
              badgeCaption: specialCaption,
              label: hasMultipleCaptions ? '*' : '',
            };
          },
        },
      },
      {
        type: 'STATUS',
        textColor: 'dark',
        props: {
          getStatus: (sDoc: ScannedDocument) => statusMapping(sDoc.status),
        },
      },
      {
        type: 'TEXT',
        textColor: 'light',
        props: {
          getText: (sDoc: ScannedDocument) => sDoc.subject || '-',
        },
      },
      {
        type: 'TEXT',
        textColor: 'light',
        props: {
          getText: (sDoc: ScannedDocument) => sDoc.senderName || '-',
        },
      },
      {
        type: 'LINK',
        textColor: 'light',
        props: {
          getLink: (sDoc?: ScannedDocument) => {
            const url = generatePath(paths.scannedDocuments.details.path, {
              id: sDoc?.id,
            });
            return {
              href: `${url}#pdflink`,
              label: 'View',
            };
          },
        },
      },
      {
        type: 'TEXT',
        textColor: 'light',
        props: {
          getText: (sDoc: ScannedDocument) => sDoc.policyNumber || '-',
        },
      },
      {
        type: 'DATE',
        textColor: 'light',
        props: {
          getDate: (sDoc: ScannedDocument) => sDoc.documentDate || '',
        },
      },
      {
        type: 'TEXT',
        textColor: 'light',
        props: {
          getText: (sDoc: ScannedDocument) =>
            sDoc.categories.length > 0
              ? sDoc.categories.map((c) => c.name).join(', ')
              : '-',
        },
      },
      {
        type: 'STATUS',
        textColor: 'light',
        props: {
          getStatus: (sDoc: ScannedDocument) => {
            const relatedPolicy = relatedPolicies?.find(
              (p) => sDoc.policyNumber && p.policyNumber === sDoc.policyNumber
            );
            const statusMapper = getCombinedPolicyStatusByInsuranceType(
              relatedPolicy?.insuranceType
            );

            return statusMapper(relatedPolicy?.publicStatus || '');
          },
          href: (sDoc: ScannedDocument) => {
            const relatedPolicy = relatedPolicies?.find(
              (p) => sDoc.policyNumber && p.policyNumber === sDoc.policyNumber
            );
            const policyLink = getPolicyDetailsBaseURLFromInsuranceType(
              relatedPolicy?.insuranceType,
              relatedPolicy?.id
            );
            return policyLink;
          },
        },
      },
      {
        type: 'TEXT_WITH_CHECK',
        textColor: 'light',
        props: {
          getTextWithCheck: (sDoc: ScannedDocument) => ({
            label: '',
            badgeCaption: 'Visible in user account',
            check:
              sDoc.userPolicyId && sDoc.visibleInUserAccount
                ? 'eyeOpen'
                : 'empty',
          }),
        },
      },
    ],
  });

  const viewConfig: ViewConfig = {
    id: 'filterView',
    options: filterViewOptions,
    getGeneralActivityDot: (sDoc: ScannedDocument) => false,
  };

  return (
    <>
      <OverviewPage
        title="Scanned documents - Feather Admin Panel"
        current="scannedDocuments"
        error={tableError || countError || categoriesError}
      >
        <>
          <ArchiveDocumentBulkAction
            ids={documentIds}
            modalIsShown={activeModalId === 'ARCHIVE'}
            closeModal={() => {
              setActiveModalId('');
            }}
            unarchive={isOnArchivedView}
          />
          <ChangeStatusBulkAction
            ids={documentIds}
            modalIsShown={activeModalId === 'CHANGE_STATUS'}
            closeModal={() => {
              setActiveModalId('');
            }}
          />
          <RenameDocumentBulkAction
            ids={documentIds}
            modalIsShown={activeModalId === 'RENAME'}
            closeModal={() => {
              setActiveModalId('');
            }}
          />
          {!isOnArchivedView && showTriggerCioEventButton && (
            <TriggerCioEventBulkAction
              selectedDocuments={selectedDocuments}
              modalIsShown={activeModalId === 'TRIGGER_CIO_EVENT'}
              closeModal={() => {
                setActiveModalId('');
              }}
            />
          )}
          {!isOnArchivedView && (
            <ToggleVisibility
              selectedDocuments={selectedDocuments}
              modalIsShown={activeModalId === 'TOGGLE_VISIBILITY'}
              closeModal={() => {
                setActiveModalId('');
              }}
              hideDocumentsMode={hideDocumentsMode}
            />
          )}
          {devMode && (
            <UnprocessDocumentBulkAction
              ids={documentIds}
              modalIsShown={activeModalId === 'UNPROCESS'}
              closeModal={() => setActiveModalId('')}
            />
          )}
          <h1 className={styles.title}>Scanned documents</h1>
          <FilterTabs
            tabOptions={filterSenderTabOptions}
            filterName="filterSender"
            noFilterTabId="all"
          />
          {currentFilterSender === 'advigonOnly' && (
            <FilterTabs
              tabOptions={filterPolicyTypeTabOptions}
              filterName="filterPolicyType"
              noFilterTabId="ALL"
            />
          )}
          {currentFilterSender === 'barmeniaOnly' && (
            <FilterTabs
              tabOptions={filterPolicyTypeTabOptionsBarmenia}
              filterName="filterPolicyType"
              noFilterTabId="ALL"
            />
          )}
          <Table
            title=""
            query={GET_SCANNED_DOCUMENTS}
            itemsPerPage={DEFAULT_NUMBER_OF_DOCUMENTS_PER_PAGE}
            enableItemsPerPageDropdown
            searchPlaceholder="Search by name, subject, policy number or text content"
            headers={tableHeaders}
            rowConfig={getRowConfig({ relatedPolicies: matchingPolicies })}
            filterConfig={tableFilters}
            emptyStateLabel={defaultEmptyStateMessage}
            viewConfig={viewConfig}
            queryVariables={{
              filterSender: querySearchParam.get('filterSender') ?? 'all',
              ...(senderFiltersWithPolicyFilter.includes(
                querySearchParam.get('filterSender') ?? ''
              ) && querySearchParam.get('filterPolicyType')
                ? {
                    filterPolicyType: querySearchParam.get('filterPolicyType'),
                  }
                : {}),
            }}
            skipQuery={categoriesLoading}
            onError={(error) => setTableError(error)}
            onCompleted={async (data?: GetScannedDocumentsData) => {
              const policyNumbers = data?.scannedDocuments.flatMap(
                ({ policyNumber }) => (policyNumber ? [policyNumber] : [])
              );
              await new Promise((resolve) => setTimeout(resolve, 3000));
              await getPoliciesByPolicyNumbers({
                variables: {
                  policyNumbers,
                },
              });
            }}
            onBulkSelection={(selectedItems) =>
              setSelectedDocuments(selectedItems)
            }
            actionButton={
              <div className="flex gap-4">
                {devMode && <CategorizeDocumentsAction />}
                {!!countData?.unprocessedScannedDocumentsCount && (
                  <Button
                    buttonType="white"
                    loading={busyProcessingDocuments}
                    loadingText="Processing..."
                    onClick={async () => {
                      await processScannedDocuments();
                    }}
                  >
                    Process documents (
                    {countData?.unprocessedScannedDocumentsCount})
                  </Button>
                )}
                <Button
                  buttonType="transparent"
                  onClick={() =>
                    window
                      .open(paths.scannedDocuments.categories.path, '_blank')
                      ?.focus()
                  }
                >
                  Edit Categories
                </Button>
                <DropdownButton
                  options={[
                    {
                      id: 'ARCHIVE',
                      label: isOnArchivedView ? 'Un-Archive' : 'Archive',
                      show: true,
                    },
                    {
                      id: 'CHANGE_STATUS',
                      label: 'Change status',
                      show: true,
                    },
                    {
                      id: 'RENAME',
                      label: 'Rename',
                      show: true,
                    },
                    {
                      id: 'TRIGGER_CIO_EVENT',
                      label: 'Trigger customer.io event',
                      show: showTriggerCioEventButton,
                    },
                    {
                      id: 'TOGGLE_VISIBILITY',
                      label: hideDocumentsMode
                        ? 'Hide from user account'
                        : 'Show in user account',
                      show: true,
                    },
                    {
                      id: 'UNPROCESS',
                      label: 'Un-process',
                      show: devMode,
                    },
                  ]}
                  onSelectOption={(s) => setActiveModalId(s.id)}
                  disabled={!selectedDocuments.length}
                >
                  Actions
                  {`${
                    selectedDocuments.length
                      ? ` (${selectedDocuments.length})`
                      : ''
                  }`}
                </DropdownButton>
              </div>
            }
          />
        </>
      </OverviewPage>
    </>
  );
};
