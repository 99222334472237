import { useMutation, useReactiveVar } from '@apollo/client';
import Button from 'components/Button';
import QuestionnaireCard from 'components/QuestionnaireCard';
import {
  RUN_PREMODERATON,
  UPDATE_PUBLIC_QUESTIONNAIRE,
} from 'graphql/publicHealth/internal/mutations';
import { PublicHealthPolicy } from 'models/publicHealthPolicy';
import CreateRequest from 'pages/tkApiRequests/components/CreateRequest/CreateRequest';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';

import PremoderationIssues from '../PremoderationIssues/PremoderationIssues';
import SideSection from '../SideSection';
import TkApiRequestErrors from '../TkApiRequestErrors';

const QuestionnaireTabPublicInternal = ({
  policy,
}: {
  policy: PublicHealthPolicy;
}) => {
  const alertBannersState = useReactiveVar(alertBanners);

  const [runPremoderation] = useMutation(RUN_PREMODERATON, {
    variables: { policyId: policy.id },
    onCompleted: () => {
      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'SUCCESS',
        message:
          'Premoderation running in the background. Refresh page after a few seconds.',
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },
    onError: () => {
      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'WARNING',
        message: 'Something went wrong. Please try again.',
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },
  });
  return (
    <>
      <div className="flex w-full flex-col">
        {policy.premoderationIssues && (
          <PremoderationIssues issues={policy.premoderationIssues} />
        )}
        {!policy.applicationSentAt && (
          <TkApiRequestErrors requests={policy.tkApiRequests} />
        )}
        <QuestionnaireCard
          questionnaireId={policy.questionnaire?.id ?? ''}
          policyId={policy.id}
          answers={policy.questionnaire?.answers ?? {}}
          cardTitle="Questionnaire answers"
          editable={
            !['COVERED', 'CANCELED', 'CANT_BE_COVERED'].includes(
              policy.publicStatus
            )
          }
          mutation={UPDATE_PUBLIC_QUESTIONNAIRE}
          editDescription="After saving, new application document will be generated and
                resubmitted to the provider."
          additionalAction={
            <>
              <Button buttonType="primary" onClick={() => runPremoderation()}>
                Rerun premoderation
              </Button>
              {policy.providerId === 'TK' && <CreateRequest policy={policy} />}
            </>
          }
        />
      </div>
      <div className="w-full lg:ml-[20px] lg:w-auto">
        <SideSection policy={policy} />
      </div>
    </>
  );
};

export default QuestionnaireTabPublicInternal;
