import Badge from 'components/Badge';

import TableCell, { TableCellProps } from '../TableCell';
import * as styles from './styles';

const TableCellDot = ({
  cell,
  badgeCaption,
  badgeColor,
  header,
  textColor,
  divider,
  link,
  resourceOnClick,
}: TableCellProps) => {
  return (
    <>
      <TableCell
        cell={
          cell ? (
            <>
              <Badge
                badgeType="dot"
                color={badgeColor ?? 'yellow'}
                className="peer"
              />
              {badgeCaption && (
                <div className={styles.badgeCaption}>{badgeCaption}</div>
              )}
            </>
          ) : (
            <></>
          )
        }
        header={header}
        textColor={textColor}
        divider={divider}
        className="relative z-10"
        link={link}
        resourceOnClick={resourceOnClick}
      />
    </>
  );
};

export default TableCellDot;
