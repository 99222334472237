import { GET_DENTAL_POLICIES } from 'pages/policies/dental/graphql/policies';
import { GET_EXPAT_POLICIES } from 'pages/policies/expatHealth/graphql/policies';

import { GET_EXPAT_SPAIN_POLICIES } from '../../../policies/expatSpain/graphql/policies';
import { TransactionInsuranceType } from '../models/TransactionInsuranceType';

export const getPoliciesQuery = (insuranceType: TransactionInsuranceType) => {
  switch (insuranceType) {
    case 'INCOMING':
      return GET_EXPAT_POLICIES;
    case 'DENTAL':
      return GET_DENTAL_POLICIES;
    case 'INCOMING_ES':
      return GET_EXPAT_SPAIN_POLICIES;
  }
};
