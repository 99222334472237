import classNames from 'classnames';

import { HeaderInformation } from '..';
import * as styles from './styles';

interface ColumnHeaderProps {
  header: HeaderInformation;
}

const ColumnHeader = ({ header }: ColumnHeaderProps) => {
  return (
    <th
      key={header.id}
      scope="col"
      className={classNames(
        styles.columnHeader,
        header.minWidth,
        header.width,
        {
          [header.threshold]: header.sticky,
          [styles.sticky]: header.sticky,
          [styles.borderRight]: header.border === 'right',
          [styles.borderLeft]: header.border === 'left',
        }
      )}
    >
      <div className={styles.contentWrapper}>
        <span>{header.label}</span>
      </div>
    </th>
  );
};

export default ColumnHeader;
