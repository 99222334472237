import Badge from 'components/Badge';
import Dropdown from 'components/Dropdown';
import { DropdownOption } from 'components/Dropdown/Dropdown';
import useQueryParams from 'hooks/useQueryParams';
import { Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router';

interface FilterViewDropdownProps {
  selected: DropdownOption;
  options: DropdownOption[];
  generalActivityDot?: boolean;
  setOffset?: Dispatch<SetStateAction<number>>;
}

const FilterViewDropdown = ({
  selected,
  options,
  generalActivityDot,
  setOffset,
}: FilterViewDropdownProps) => {
  const querySearchParam = useQueryParams();
  const history = useHistory();

  const handleFilterChange = (option: DropdownOption) => {
    if (option.id === 'all') {
      querySearchParam.delete('filterView');
    } else {
      querySearchParam.set('filterView', option.id);
    }
    setOffset?.(0);
    history.replace(`?${querySearchParam.toString()}`);
  };

  return (
    <div className="z-30 flex items-center">
      {generalActivityDot && <Badge badgeType="dot" color="yellow" />}
      <Dropdown
        selected={selected}
        setSelected={handleFilterChange}
        options={options}
        disabled={false}
        type="transparent"
        smallVersion={false}
      />
    </div>
  );
};

export default FilterViewDropdown;
