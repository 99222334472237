import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

import * as styles from './styles';

interface ArrowButtonProps {
  next: boolean;
  disabled: boolean;
  handleClick: (next: boolean) => void;
}

const ArrowButton = ({ next, handleClick, disabled }: ArrowButtonProps) => {
  return (
    <button
      type="button"
      onClick={() => handleClick(next)}
      disabled={disabled}
      className={classNames(styles.button, {
        [styles.borderRight]: next,
        [styles.borderLeft]: !next,
      })}
    >
      <span className="sr-only">{next ? 'Next' : 'Previous'}</span>
      {next ? (
        <ChevronRightIcon className={styles.icon} aria-hidden="true" />
      ) : (
        <ChevronLeftIcon className={styles.icon} aria-hidden="true" />
      )}
    </button>
  );
};

export default ArrowButton;
