import { gql } from '@apollo/client';

export const UPDATE_SCANNED_DOCUMENT = gql`
  mutation updateScannedDocument(
    $id: String!
    $policyNumber: String
    $status: String
    $documentDate: String
    $friendlyName: String
    $processed: String
    $recipientName: String
    $subject: String
    $senderName: String
    $userPolicyId: String
    $visibleInUserAccount: Boolean
  ) {
    updateScannedDocument(
      scannedDocumentId: $id
      policyNumber: $policyNumber
      status: $status
      documentDate: $documentDate
      friendlyName: $friendlyName
      processed: $processed
      recipientName: $recipientName
      subject: $subject
      senderName: $senderName
      userPolicyId: $userPolicyId
      visibleInUserAccount: $visibleInUserAccount
    ) {
      id
    }
  }
`;

export const UPDATE_MANY_SCANNED_DOCUMENTS = gql`
  mutation updateManyScannedDocuments(
    $ids: [String]!
    $status: String
    $processed: String
    $visibleInUserAccount: Boolean
    $archivedAt: Float
    $friendlyName: String
  ) {
    updateManyScannedDocuments(
      scannedDocumentIds: $ids
      status: $status
      processed: $processed
      visibleInUserAccount: $visibleInUserAccount
      archivedAt: $archivedAt
      friendlyName: $friendlyName
    )
  }
`;

export const HIDE_SCANNED_DOCUMENTS = gql`
  mutation hideScannedDocuments($scannedDocumentIds: [String]!) {
    hideScannedDocuments(scannedDocumentIds: $scannedDocumentIds)
  }
`;

export const SHOW_SCANNED_DOCUMENTS = gql`
  mutation showScannedDocuments(
    $showScannedDocumentsDataArray: [ScannedDocumentShowDataInput]!
  ) {
    showScannedDocuments(
      showScannedDocumentsDataArray: $showScannedDocumentsDataArray
    )
  }
`;

export const UPDATE_SCANNED_DOCUMENTS_USER_CLAIMS_RELATION = gql`
  mutation updateScannedDocumentsUserClaimsRelation(
    $scannedDocumentId: String!
    $connectedClaimsData: [ClaimConnectionData]!
  ) {
    updateScannedDocumentsUserClaimsRelation(
      scannedDocumentId: $scannedDocumentId
      connectedClaimsData: $connectedClaimsData
    )
  }
`;

export const PROCESS_SCANNED_DOCUMENTS = gql`
  mutation processScannedDocuments {
    processScannedDocuments
  }
`;

export const UPDATE_CATEGORY_RELATIONS = gql`
  mutation updateCategoryRelations($selectionType: String!) {
    updateCategoryRelations(selectionType: $selectionType)
  }
`;

export const ACTIVATE_DENTAL_POLICY = gql`
  mutation activateDentalPolicy(
    $scannedDocumentId: String!
    $userPolicyId: String!
    $policyNumber: String!
  ) {
    activateDentalPolicy(
      scannedDocumentId: $scannedDocumentId
      userPolicyId: $userPolicyId
      policyNumber: $policyNumber
    ) {
      id
    }
  }
`;

export const ACTIVATE_PRIVATE_POLICY = gql`
  mutation activatePrivatePolicy(
    $scannedDocumentId: String!
    $userPolicyId: String!
    $policyNumber: String!
    $startDate: String!
  ) {
    activatePrivatePolicy(
      scannedDocumentId: $scannedDocumentId
      userPolicyId: $userPolicyId
      policyNumber: $policyNumber
      startDate: $startDate
    ) {
      id
    }
  }
`;

export const BATCH_PUBLISH_CUSTOMER_IO_EVENTS = gql`
  mutation batchPublishCustomerIoEvents($payloads: [CustomerIoTrackPayload!]!) {
    batchPublishCustomerIoEvents(payloads: $payloads)
  }
`;
