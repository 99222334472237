import { InsurancePathName } from './models';

export const textByInsuranceMapper: Record<
  InsurancePathName,
  {
    taskContext: string;
    pageTitle: { overviewPage: string };
    windowTitle: { overviewPage: string; detailsPage: string };
    policyDetailsButtonTitle: string;
  }
> = {
  'private-health': {
    pageTitle: {
      overviewPage: 'Private health claims',
    },
    windowTitle: {
      overviewPage: 'Private health claims - Feather Admin Panel',
      detailsPage: 'Private health claim details - Feather Admin Panel',
    },
    policyDetailsButtonTitle: 'Private health policy',
    taskContext: 'Private health claim',
  },
  'expat-health': {
    pageTitle: {
      overviewPage: 'Expat health claims',
    },
    windowTitle: {
      overviewPage: 'Expat health claims - Feather Admin Panel',
      detailsPage: 'Expat health claim details - Feather Admin Panel',
    },
    policyDetailsButtonTitle: 'Expat health policy',
    taskContext: 'Expat health claim',
  },
  'expat-health-long-term': {
    pageTitle: {
      overviewPage: 'Expat health long-term claims',
    },
    windowTitle: {
      overviewPage: 'Expat health long-term claims - Feather Admin Panel',
      detailsPage: 'Expat health long-term claim details - Feather Admin Panel',
    },
    policyDetailsButtonTitle: 'Expat health long-term policy',
    taskContext: 'Expat health long-term claim',
  },
  dental: {
    pageTitle: {
      overviewPage: 'Dental claims',
    },
    windowTitle: {
      overviewPage: 'Dental claims - Feather Admin Panel',
      detailsPage: 'Dental claim details - Feather Admin Panel',
    },
    policyDetailsButtonTitle: 'Dental policy',
    taskContext: 'Dental claim',
  },
  liability: {
    pageTitle: {
      overviewPage: 'Liability claims',
    },
    windowTitle: {
      overviewPage: 'Liability claims - Feather Admin Panel',
      detailsPage: 'Liability claim details - Feather Admin Panel',
    },
    policyDetailsButtonTitle: 'Liability policy',
    taskContext: 'Liability claim',
  },
  household: {
    pageTitle: {
      overviewPage: 'Household claims',
    },
    windowTitle: {
      overviewPage: 'Household claims - Feather Admin Panel',
      detailsPage: 'Household claim details - Feather Admin Panel',
    },
    policyDetailsButtonTitle: 'Household policy',
    taskContext: 'Household claim',
  },
  travel: {
    pageTitle: {
      overviewPage: 'Travel claims',
    },
    windowTitle: {
      overviewPage: 'Travel claims - Feather Admin Panel',
      detailsPage: 'Travel claim details - Feather Admin Panel',
    },
    policyDetailsButtonTitle: 'Travel policy',
    taskContext: 'Travel claim',
  },
  'pet-health': {
    pageTitle: {
      overviewPage: 'Pet health claims',
    },
    windowTitle: {
      overviewPage: 'Pet health claims - Feather Admin Panel',
      detailsPage: 'Pet health claim details - Feather Admin Panel',
    },
    policyDetailsButtonTitle: 'Pet health policy',
    taskContext: 'Pet health claim',
  },
  disability: {
    pageTitle: {
      overviewPage: 'Disability claims',
    },
    windowTitle: {
      overviewPage: 'Disability claims - Feather Admin Panel',
      detailsPage: 'Disability claim details - Feather Admin Panel',
    },
    policyDetailsButtonTitle: 'Disability policy',
    taskContext: 'Disability claim',
  },
  'company-health': {
    pageTitle: {
      overviewPage: 'Company health claims',
    },
    windowTitle: {
      overviewPage: 'Company health claims - Feather Admin Panel',
      detailsPage: 'Company health claim details - Feather Admin Panel',
    },
    policyDetailsButtonTitle: 'Company health policy',
    taskContext: 'Company health claim',
  },
  'company-life': {
    pageTitle: {
      overviewPage: 'Company life claims',
    },
    windowTitle: {
      overviewPage: 'Company life claims - Feather Admin Panel',
      detailsPage: 'Company life claim details - Feather Admin Panel',
    },
    policyDetailsButtonTitle: 'Company life policy',
    taskContext: 'Company life claim',
  },
  bike: {
    pageTitle: {
      overviewPage: 'Bike claims',
    },
    windowTitle: {
      overviewPage: 'Bike claims - Feather Admin Panel',
      detailsPage: 'Bike claim details - Feather Admin Panel',
    },
    policyDetailsButtonTitle: 'Bike policy',
    taskContext: 'Bike claim',
  },
  legal: {
    pageTitle: {
      overviewPage: 'Legal claims',
    },
    windowTitle: {
      overviewPage: 'Legal claims - Feather Admin Panel',
      detailsPage: 'Legal claim details - Feather Admin Panel',
    },
    policyDetailsButtonTitle: 'Legal policy',
    taskContext: 'Legal claim',
  },
  'dog-liability': {
    pageTitle: {
      overviewPage: 'Dog liability claims',
    },
    windowTitle: {
      overviewPage: 'Dog liability claims - Feather Admin Panel',
      detailsPage: 'Dog liability claim details - Feather Admin Panel',
    },
    policyDetailsButtonTitle: 'Dog liability policy',
    taskContext: 'Dog liability claim',
  },
  'expat-health-es': {
    pageTitle: {
      overviewPage: 'Expat health claims',
    },
    windowTitle: {
      overviewPage: 'Expat health claims - Feather Admin Panel',
      detailsPage: 'Expat health claim details - Feather Admin Panel',
    },
    policyDetailsButtonTitle: 'Expat health policy',
    taskContext: 'Expat health claim',
  },
};
