import { gql } from '@apollo/client';

export const UPLOAD_LIFE_DOCUMENT = gql`
  mutation UploadLifeDocument(
    $policyId: String!
    $newFilename: String
    $file: Upload
  ) {
    uploadLifeDocument(id: $policyId, newFilename: $newFilename, file: $file) {
      id
      url
      name
    }
  }
`;

export const DELETE_LIFE_DOCUMENT = gql`
  mutation DeleteLifeDocument(
    $id: String!
    $blobName: String!
    $documentType: String!
  ) {
    deleteLifePolicyFile(id: $id, blobName: $blobName, type: $documentType) {
      id
    }
  }
`;
