import { gql } from '@apollo/client';

export const DELETE_GENERIC_POLICY_FILE = gql`
  mutation DeleteGenericPolicyFile(
    $blobName: String!
    $documentType: String!
    $id: String!
  ) {
    deleteGenericPolicyFile(blobName: $blobName, type: $documentType, id: $id) {
      id
    }
  }
`;
