import classNames from 'classnames';
import { BadgeColor } from 'components/Badge/Badge';
import { CheckBadgeType } from 'components/CheckBadge/CheckBadge';
import { PolicyStatus } from 'models/publicHealthStatus';
import { PublicHealthStatus, Status } from 'models/statusColor';

import { HeaderInformation, TextColor } from '..';
import * as styles from './styles';

export interface TableCellProps {
  cell: JSX.Element | string | boolean;
  header: HeaderInformation | null;
  textColor: TextColor;
  divider: boolean;
  statusMapping?:
    | ((status: PolicyStatus) => PublicHealthStatus)
    | ((status: string) => Status);
  completedStudentChecklist?: boolean;
  check?: CheckBadgeType;
  badgeColor?: BadgeColor;
  className?: string;
  badgeCaption?: string;
  resourceOnClick?: (data: any) => void;
  link?: string;
  title?: string;
  onClick?: (metaData: Record<string, any>) => void;
  href?: string;
}

const TableCell = ({
  cell,
  header,
  divider,
  textColor,
  className,
  link,
  resourceOnClick,
}: TableCellProps) => {
  if (header === null) {
    return <td>{cell}</td>;
  }

  if (resourceOnClick) {
    return (
      <td
        className={classNames('row-cell', className, {
          [`${header.threshold} ${styles.sticky}`]: header.sticky,
          [styles.borderRight]: header.border === 'right',
          [styles.borderLeft]: header.border === 'left',
          [styles.divider]: divider,
        })}
      >
        <button
          type="button"
          className={classNames(
            styles.nestedAnchor,
            className,
            header.minWidth,
            header.width,
            'flex',
            {
              [styles.lightTextColor]: textColor === 'light',
              [styles.darkTextColor]: textColor === 'dark',
            }
          )}
          onClick={resourceOnClick}
        >
          {cell}
        </button>
      </td>
    );
  }

  if (!link) {
    return (
      <td
        className={classNames(
          styles.cell,
          className,
          header.minWidth,
          header.width,
          {
            [styles.lightTextColor]: textColor === 'light',
            [styles.darkTextColor]: textColor === 'dark',
            [`${header.threshold} ${styles.sticky}`]: header.sticky,
            [styles.borderRight]: header.border === 'right',
            [styles.borderLeft]: header.border === 'left',
            [styles.divider]: divider,
          }
        )}
      >
        {cell}
      </td>
    );
  }

  return (
    <td
      className={classNames('row-cell', className, {
        [`${header.threshold} ${styles.sticky}`]: header.sticky,
        [styles.borderRight]: header.border === 'right',
        [styles.borderLeft]: header.border === 'left',
        [styles.divider]: divider,
      })}
    >
      <a
        className={classNames(
          styles.nestedAnchor,
          className,
          header.minWidth,
          header.width,
          {
            [styles.lightTextColor]: textColor === 'light',
            [styles.darkTextColor]: textColor === 'dark',
          }
        )}
        href={link}
      >
        {cell}
      </a>
    </td>
  );
};

export default TableCell;
