import { FolderOpenIcon } from '@heroicons/react/outline';
import { paths } from 'routes/definedPaths';

import { NavigationLink } from '../NavBar';

export const recordsLinks: NavigationLink = {
  name: 'Records',
  icon: FolderOpenIcon,
  id: 'records',
  children: [
    {
      name: 'Scanned documents',
      id: 'scannedDocuments',
      href: paths.scannedDocuments.path,
    },
    {
      name: 'Questionnaires',
      id: 'questionnaires',
      href: paths.questionnaires.path,
    },
    {
      name: 'Data removal requests',
      id: 'dataRemovalRequests',
      href: paths.dataRemovalRequests.path,
    },
    {
      name: 'TK API requests',
      id: 'tkApiRequests',
      href: paths.tkApiRequests.path,
    },
  ],
};
