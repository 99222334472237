import { gql } from '@apollo/client';

import { DENTAL_POLICY_ACTIVITY } from './activity';

export const GET_DENTAL_POLICY = gql`
  ${DENTAL_POLICY_ACTIVITY}
  query GetDentalPolicy($id: String!) {
    dentalPolicy(id: $id) {
      id
      policyNumber
      createdAt
      startDate
      archivedAt
      price
      paymentReceivedAt
      source
      campaign
      medium
      content
      term
      numberOfClaims
      publicStatus
      activeUntil
      plan
      endDate
      providerId

      # insured person
      insuredPerson {
        firstName
        lastName
        dateOfBirth
        email
        gender
      }

      # user
      user {
        id
        firstName
        lastName
        stripeToken
        intercomEUId
        email
        isDelinquent
        isFraudulent
        fraudulentAdditionalInfo
        dentalPolicies {
          id
          publicStatus
          insuredPerson {
            firstName
            lastName
          }
        }
      }

      # documents
      policyDocuments {
        url
        id
        name
        blobName
        isRegeneratable
        resizedUrl
      }
      confirmationOfCoverageDocuments {
        id
        url
        name
        blobName
      }
      questionnaireDownloadDocuments {
        id
        url
        blobName
      }
      scannedDocuments {
        id
        blobName
        url
        friendlyName
        visibleInUserAccount
      }

      # policy cancelation request
      policyCancelationRequest {
        id
        cancelationConfirmedByProviderAt
        createdAt
        canceledOnStripeAt
        requestSentToProviderAt
      }

      # questionnaire
      questionnaire {
        id
        answers
      }

      # quote
      quote {
        id
        plan
        toothReplacementCoverage
      }

      pinnedNotes {
        id
        body
        createdAt
        adminUser {
          firstName
          lastName
        }
        updatedAt
      }

      # policy activity
      ...DentalPolicyActivity

      # referral data
      referral {
        referralCode
        status
      }
    }
  }
`;
