import { SelectButtonOption } from 'components/SelectButton';
import { SortingKey, SortingOrder } from 'components/Table';
import hashSum from 'hash-sum';
import { useEffect, useState } from 'react';

import useQueryParams from './useQueryParams';

export interface FilterDetails {
  options?: SelectButtonOption[];
  multiple?: boolean;
  dateRange?: boolean;
  id: string;
}

const useTableQueryParams = (filterDetails?: FilterDetails[]) => {
  const query = useQueryParams();
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState<SortingKey>({
    column: 'none',
    order: 'none',
  });

  const empty: {
    [key: string]: SelectButtonOption | SelectButtonOption[] | undefined;
  } = {};

  const createFilterState = () => {
    return filterDetails?.reduce((prev, cur) => {
      prev[cur.id] = cur.multiple ? [] : undefined;
      return prev;
    }, empty);
  };

  const [filter, setFilter] = useState<{
    [key: string]: SelectButtonOption | SelectButtonOption[] | undefined;
  }>(createFilterState() ?? {});

  const [dateFilter, setDateFilter] = useState<{
    [key: string]: [Date | undefined, Date | undefined] | undefined;
  }>({});

  useEffect(() => {
    const querySearchParam = query.get('search') ?? '';
    setSearch(querySearchParam);

    const sortColumnParam = query.get('sortColumn') ?? 'none';
    const sortOrderParam = query.get('sortOrder') ?? 'none';
    setSort({ column: sortColumnParam, order: sortOrderParam as SortingOrder });

    filterDetails?.forEach((filterDetail) => {
      if (filterDetail.dateRange) {
        const selectedDateRange = query.get(filterDetail.id)?.split(',');
        if (!selectedDateRange) return;
        setDateFilter((prev) => ({
          ...prev,
          [filterDetail.id]: [
            selectedDateRange[0] ? new Date(selectedDateRange[0]) : undefined,
            selectedDateRange[1] ? new Date(selectedDateRange[1]) : undefined,
          ],
        }));
        return;
      }

      if (!filterDetail?.multiple) {
        const filterResults = filterDetail?.options?.filter(
          (option) => option.id === query.get(filterDetail.id)
        );
        if (filterResults && filterResults.length > 0) {
          setFilter((prev) => ({
            ...prev,
            [filterDetail.id]: filterResults?.[0],
          }));
          return;
        }
        setFilter((prev) => ({
          ...prev,
          [filterDetail.id]: filterDetail.options?.[0],
        }));
      } else {
        const selectedOptions = query.get(filterDetail.id)?.split(',');
        if (!selectedOptions) {
          setFilter((prev) => ({ ...prev, [filterDetail.id]: [] }));
          return;
        }
        const filterResults = filterDetail?.options?.filter((option) =>
          selectedOptions.find((selected) => selected === option.id)
        );
        setFilter((prev) => ({ ...prev, [filterDetail.id]: filterResults }));
      }
    });
  }, [query, hashSum(filterDetails)]);

  return {
    search,
    setSearch,
    sort,
    setSort,
    filter,
    setFilter,
    dateFilter,
    setDateFilter,
  };
};

export default useTableQueryParams;
