import { useTranslation } from '@getpopsure/i18n-react';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { CustomerDelinquencyBanner } from 'components/CustomerDelinquencyBanner';
import { CustomerFraudulentBanner } from 'components/CustomerFraudulentBanner';
import FileErrorCard from 'components/FileErrorCard';
import InformationCard, {
  InformationSection,
} from 'components/InformationCard';
import ChecklistInformation from 'components/InformationCard/ChecklistInformation';
import { CurrencyInformation } from 'components/InformationCard/CurrencyInformation';
import DateInformation from 'components/InformationCard/DateInformation';
import DropdownInformation from 'components/InformationCard/DropdownInformation/DropdownInformation';
import EditableInformationCard from 'components/InformationCard/EditableInformationCard';
import LinkInformation from 'components/InformationCard/LinkInformation';
import StatusInformation from 'components/InformationCard/StatusInformation';
import TextInformation from 'components/InformationCard/TextInformation';
import TextWithDetailsInformation from 'components/InformationCard/TextWithDetailsInformation';
import { ReferralCode } from 'components/ReferralCode/ReferralCode';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'models/date';
import { PrivateHealthPolicy } from 'models/privateHealthPolicy';
import { PolicyStatus } from 'models/publicHealthStatus';
import { retrieveClaimsOverviewPath } from 'pages/claims/template/utils/utils';
import {
  UPDATE_IBAN,
  UPDATE_PRIVATE_POLICY,
} from 'pages/policies/privateHealth/graphql/mutations';
import { GET_PRIVATE_POLICY } from 'pages/policies/privateHealth/graphql/queries';
import {
  getAddon,
  getAddonsForTariff,
} from 'pages/policies/privateHealth/utils/mapAddons';
import { getDeductibleOptions } from 'pages/policies/privateHealth/utils/mapDeductibleOptions';
import {
  getPrivateTariffName,
  tariffs,
} from 'pages/policies/privateHealth/utils/mapTariffNames';
import ChangeAccount from 'pages/policies/template/ChangeAccount/ChangeAccount';
import { useEffect, useState } from 'react';
import { mapGender } from 'shared/mapGender';
import { getPrivateHealthStatus } from 'shared/mapPrivateHealthStatus';
import { mapUserOccupation } from 'shared/mapUserOccupation';
import { setQueryToUrl } from 'shared/utils/setQueryToUrl';

import { paymentMethods } from '../../utils/mapPaymentMethods';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import NewTariffInfo from '../NewTariffInfo/NewTariffInfo';
import PriceDetails from '../PriceDetails';
import SideSection from '../SideSection/SideSection';

interface OverviewTabProps {
  policy: PrivateHealthPolicy;
}

const OverviewTab = ({ policy }: OverviewTabProps) => {
  const { t } = useTranslation();
  const [selectedTariff, setSelectedTariff] = useState(
    policy.currentTariffInfo?.tariff
  );

  useEffect(() => {
    setSelectedTariff(policy.currentTariffInfo?.tariff);
  }, [policy.currentTariffInfo]);

  const deductibleOptions = getDeductibleOptions(
    selectedTariff && selectedTariff !== ''
      ? selectedTariff
      : policy.currentTariffInfo?.tariff ?? ''
  );

  const tariffInfoAddons = policy.currentTariffInfo?.addOns?.map(
    (addon) => addon.add_on
  );

  const activeSickpayAddOn = policy.currentTariffInfo?.addOns?.some(
    (addOn) => addOn.add_on === 'KT'
  );

  const editablePolicyInformation = {
    policyNumber: policy.policyNumber ?? '',
    startDate: policy.startDate ?? '',
    endDate: policy.endDate ?? '',
    tariffStartDate: policy.currentTariffInfo?.startDate ?? '',
    source: policy.source ?? '',
    campaign: policy?.campaign ?? '',
    medium: policy?.medium ?? '',
    content: policy?.content ?? '',
    term: policy?.term ?? '',
    addOns: tariffInfoAddons ?? [],
    sickDayPayout: activeSickpayAddOn
      ? policy.currentTariffInfo?.sickDayPayout
      : undefined,
    tariff:
      tariffs.find(
        (tariff) =>
          tariff.label ===
          getPrivateTariffName(
            selectedTariff ?? policy.currentTariffInfo?.tariff ?? ''
          )
      )?.id ?? policy.currentTariffInfo?.tariff,
    deductible:
      deductibleOptions.find(
        (deductible) =>
          deductible.id === (policy.currentTariffInfo?.deductible ?? '')
      )?.id ?? deductibleOptions[0].id,
  };

  const nextTariffInfo = {
    details: policy.nextActiveTariffInfo ? (
      <NewTariffInfo
        tariffInfo={policy.nextActiveTariffInfo}
        policyId={policy.id}
      />
    ) : undefined,
    detailsLabel: `Updates on ${dayjs(
      policy.nextActiveTariffInfo?.startDate
    ).format(DATE_FORMAT)}`,
    detailsTitle: 'New tariff info',
  };

  const customerInformation: InformationSection[] = [
    {
      title: 'Insured person',
      rows: [
        {
          id: 'firstName',
          title: 'First name',
          data: policy.insuredPerson?.firstName ?? policy.user.firstName ?? '',
          type: 'TEXT',
          component: TextInformation,
        },
        {
          id: 'lastName',
          title: 'Last name',
          data: policy.insuredPerson?.lastName ?? policy.user.lastName ?? '',
          type: 'TEXT',
          component: TextInformation,
        },
        {
          id: 'dateOfBirth',
          title: 'Date of birth',
          data:
            policy.insuredPerson?.dateOfBirth ?? policy.user.dateOfBirth ?? '',
          type: 'DATE',
          component: DateInformation,
        },
        {
          id: 'gender',
          title: 'Gender',
          data: mapGender(t)[
            policy.insuredPerson?.gender ?? policy.user.gender ?? 'OTHER'
          ],
          type: 'TEXT',
          component: TextInformation,
        },
        {
          id: 'occupation',
          title: 'Occupation',
          data: mapUserOccupation(policy.insuredPerson?.occupation ?? 'OTHER'),
          type: 'TEXT',
          component: TextInformation,
        },
        {
          id: 'email',
          title: 'Email',
          data: policy?.user?.email ?? '',
          component: TextWithDetailsInformation,
          type: 'TEXT_WITH_DETAILS',
          detailsTitle: 'Change account',
          detailsLabel: 'Change account',
          renderModalDetails: (setOpen, modalOpen) => (
            <ChangeAccount
              policyDetailsMutation={GET_PRIVATE_POLICY}
              setOpen={setOpen}
              modalOpen={modalOpen}
            />
          ),
        },
      ],
    },
  ];

  const policyInformation: InformationSection[] = [
    {
      title: 'Policy information',
      rows: [
        {
          id: 'policyNumber',
          title: 'Policy number',
          data: policy.policyNumber,
          type: 'TEXT',
          component: TextInformation,
          editable: true,
          placeholder: 'Enter policy number',
        },
        {
          id: 'provider',
          title: 'Provider',
          data: policy.providerId,
          type: 'TEXT',
          component: TextInformation,
        },
        {
          id: 'status',
          title: 'Status',
          data: policy.publicStatus as PolicyStatus,
          type: 'STATUS',
          component: StatusInformation,
          statusMapping: getPrivateHealthStatus,
        },
        {
          id: 'startDate',
          title: 'Start date',
          data: policy.startDate ?? '',
          type: 'DATE',
          component: DateInformation,
          editable: true,
        },
        ...(policy.publicStatus === 'CANCELED'
          ? [
              {
                id: 'endDate',
                title: 'End date',
                data: policy.endDate ?? '',
                type: 'DATE' as const,
                component: DateInformation,
                editable: true,
              },
            ]
          : []),
        {
          id: 'tariffStartDate',
          title: 'Tariff start date',
          data:
            policy.publicStatus === 'ACTIVE'
              ? policy.currentTariffInfo?.startDate ?? ''
              : policy.startDate ?? '',
          type: 'DATE',
          component: DateInformation,
          editable: policy.publicStatus === 'ACTIVE',
          ...nextTariffInfo,
        },
        {
          id: 'tariff',
          title: 'Tariff',
          data:
            tariffs.find(
              (tariff) =>
                tariff.label ===
                getPrivateTariffName(policy.currentTariffInfo?.tariff ?? '')
            )?.id ?? '',
          type: 'DROPDOWN',
          component: DropdownInformation,
          dropdownOptions: tariffs,
          multipleOptions: false,
          placeholder: 'Select tariff',
          optionMapping: (tariffId: string) => {
            return tariffs.find((tariff) => tariff.id === tariffId)?.label;
          },
          editable: true,
          ...nextTariffInfo,
        },
        {
          id: 'deductible',
          title: 'Deductible',
          data:
            deductibleOptions.find(
              (deductible) =>
                deductible.id === (policy.currentTariffInfo?.deductible ?? '')
            )?.id ?? deductibleOptions[0].id,
          type: 'DROPDOWN',
          component: DropdownInformation,
          dropdownOptions: deductibleOptions,
          multipleOptions: false,
          placeholder: 'Select deductible',
          optionMapping: (deductibleId: string) => {
            return deductibleOptions.find(
              (deductible) => deductible.id === deductibleId
            )?.label;
          },
          editable: true,
          ...nextTariffInfo,
        },
        {
          id: 'addOns',
          title: 'Add-ons',
          type: 'CHECKLIST',
          component: ChecklistInformation,
          checklist: getAddonsForTariff(
            policy.currentTariffInfo?.tariff ?? '',
            tariffInfoAddons ?? []
          ).map((addonOption) => ({
            id: addonOption,
            title: getAddon(addonOption),
            check: !!policy.currentTariffInfo?.addOns?.find(
              (addon) => addon.add_on === addonOption
            ),
          })),
          editable: true,
          ...nextTariffInfo,
        },
        {
          id: 'sickDayPayout',
          title: 'Sick day payout amount',
          data: activeSickpayAddOn
            ? policy.currentTariffInfo?.sickDayPayout ?? 0
            : undefined,
          type: 'CURRENCY',
          component: CurrencyInformation,
          editable: true,
          ...nextTariffInfo,
        },
        ...(policy.numberOfClaims !== 0
          ? [
              {
                id: 'claims',
                title: 'Claims',
                data: `${policy.numberOfClaims} claims`,
                href: setQueryToUrl(
                  retrieveClaimsOverviewPath('private-health'),
                  [{ key: 'search', value: policy.policyNumber }]
                ),
                externalLink: true,
                type: 'LINK' as const,
                component: LinkInformation,
              },
            ]
          : [
              {
                id: 'claims',
                title: 'Claims',
                data: '',
                type: 'TEXT' as const,
                component: LinkInformation,
              },
            ]),
        {
          id: 'created-at',
          title: 'Created on',
          data: policy.createdAt,
          type: 'DATE',
          component: DateInformation,
        },
        {
          id: 'source',
          title: 'Source',
          data: policy.source,
          type: 'TEXT',
          component: TextInformation,
          editable: true,
          placeholder: 'Enter source',
        },
        {
          id: 'campaign',
          title: 'Campaign',
          data: policy?.campaign ?? '',
          component: TextInformation,
          type: 'TEXT',
          editable: true,
          placeholder: 'Enter campaign',
        },
        {
          id: 'medium',
          title: 'Medium',
          data: policy?.medium ?? '',
          component: TextInformation,
          type: 'TEXT',
          editable: true,
          placeholder: 'Enter medium',
        },
        {
          id: 'content',
          title: 'Content',
          type: 'TEXT',
          component: TextInformation,
          data: policy?.content ?? '',
          editable: true,
          placeholder: 'Enter content',
        },
        {
          id: 'term',
          title: 'Term',
          data: policy?.term ?? '',
          component: TextInformation,
          type: 'TEXT',
          editable: true,
          placeholder: 'Enter term',
        },
        {
          id: 'referralCode',
          title: 'Referral code',
          data: policy?.referral?.referralCode ?? '',
          type: policy?.referral?.status ? 'TEXT_WITH_DETAILS' : 'TEXT',
          detailsTitle: 'Add referral code',
          detailsLabel: 'Add referral code',
          component: TextWithDetailsInformation,
          renderModalDetails: (setOpen, modalOpen) => (
            <ReferralCode
              policyId={policy?.id ?? ''}
              userId={policy?.user?.id ?? ''}
              setOpen={setOpen}
              modalOpen={modalOpen}
            />
          ),
        },
      ],
    },
  ];

  const policyPayment: InformationSection[] = [
    {
      title: 'Policy payment',
      rows: [
        {
          id: 'monthly-price',
          title: 'Monthly price',
          data: englishFormattedEuroCurrency(
            policy.currentTariffInfo?.totalMonthlyPrice ?? 0
          ),
          type: 'TEXT_WITH_DETAILS',
          component: TextWithDetailsInformation,
          detailsTitle: 'Price details',
          renderModalDetails: () => (
            <PriceDetails
              tariffInfo={policy.currentTariffInfo}
              occupation={policy.insuredPerson?.occupation ?? 'OTHER'}
              policyId={policy.id}
            />
          ),
          isModalScrollable: true,
        },
        {
          id: 'family-price',
          title: 'Family price',
          data: '',
          type: 'TEXT',
          component: TextInformation,
        },
        {
          id: 'risk-surcharge',
          title: 'Risk surcharge',
          data:
            (policy.currentTariffInfo?.riskFactorSurcharge ?? 0) *
            (policy.currentTariffInfo?.tariffMonthlyPrice ?? 0),
          type: 'CURRENCY',
          component: CurrencyInformation,
          editable: false,
        },
        {
          id: 'paymentMethod',
          title: 'Payment method',
          data: policy.privateHealthPaymentMethod ?? 'DIRECT_DEBIT',
          type: 'DROPDOWN',
          component: DropdownInformation,
          editable: true,
          dropdownOptions: paymentMethods,
          optionMapping: (paymentMethodId: string) => {
            return paymentMethods.find(
              (paymentMethod) => paymentMethod.id === paymentMethodId
            )?.label;
          },
          multipleOptions: false,
          placeholder: 'Select payment method',
        },
        {
          id: 'iban',
          title: 'IBAN',
          data: policy.iban,
          type: 'TEXT',
          component: TextInformation,
          editable: true,
          placeholder: 'Enter IBAN',
        },
      ],
    },
  ];

  const editablePriceInformation = {
    iban: policy.iban,
    paymentMethod: policy.privateHealthPaymentMethod ?? 'DIRECT_DEBIT',
  };

  return (
    <>
      <div className="relative flex w-full flex-col">
        <CustomerDelinquencyBanner customer={policy.user} linkToCustomer />
        <CustomerFraudulentBanner customer={policy.user} />
        {policy?.archivedAt && (
          <FileErrorCard
            open
            hideCloseButton
            handleClose={() => {}}
            title="This policy is archived"
            errorType="WARNING"
            className="mb-[16px] mt-0"
          />
        )}
        <InformationCard sections={customerInformation} />
        <EditableInformationCard
          sections={policyInformation}
          editableData={editablePolicyInformation}
          mutation={UPDATE_PRIVATE_POLICY}
          resourceId={policy.id}
          ConfirmationModal={ConfirmationModal}
          oldConfirmationValue={[
            {
              id: 'startDate',
              value: policy.startDate ?? '',
            },
            {
              id: 'tariffStartDate',
              value: policy.currentTariffInfo?.startDate ?? '',
            },
            {
              id: 'tariff',
              value: policy.currentTariffInfo?.tariff ?? '',
            },
            {
              id: 'deductible',
              value: policy.currentTariffInfo?.deductible ?? '',
            },
            {
              id: 'sickDayPayout',
              value: policy.currentTariffInfo?.sickDayPayout ?? '',
            },
            {
              id: 'sickDayPayout',
              value: policy.currentTariffInfo?.sickDayPayout ?? '',
            },
            {
              id: 'addOns',
              value:
                policy.currentTariffInfo?.addOns
                  ?.map((addon) => getAddon(addon.add_on))
                  .join(', ') ?? '',
            },
          ]}
          confirmationModalAdditionalData={{
            status: policy.publicStatus,
            occupation: policy.insuredPerson?.occupation,
          }}
          refetchQueries={[GET_PRIVATE_POLICY]}
          notifyChangeId="tariff"
          notifyChangeFunction={setSelectedTariff}
          successMessage="You have successfully saved the changes"
        />
        <EditableInformationCard
          sections={policyPayment}
          editableData={editablePriceInformation}
          mutation={UPDATE_IBAN}
          resourceId={policy.id}
          successMessage="You have successfully saved the changes"
        />
      </div>
      <div className="w-full lg:ml-[20px] lg:w-auto">
        <SideSection policy={policy} />
      </div>
    </>
  );
};

export default OverviewTab;
