import { useTranslation } from '@getpopsure/i18n-react';
import { AdjustmentsIcon } from '@heroicons/react/outline';
import Button from 'components/Button';
import SelectButton, { SelectButtonOption } from 'components/SelectButton';
import useQueryParams from 'hooks/useQueryParams';
import { useState } from 'react';
import { useHistory } from 'react-router';

import MoreFiltersForm from './MoreFiltersForm';

interface FiltersProps {
  fetchFiltered: (
    newFilterOption: SelectButtonOption | SelectButtonOption[] | undefined,
    filterId: string
  ) => void;
  fetchFilteredAll: (options: {
    [key: string]: SelectButtonOption | SelectButtonOption[] | undefined;
  }) => void;
  fetchFilteredDates: (options: {
    [key: string]: [Date | undefined, Date | undefined] | undefined;
  }) => void;
  loading: boolean;
  filters: Filter[];
}

export type Filter =
  | {
      filterType: 'TEXT';
      selected: SelectButtonOption | undefined;
      options: SelectButtonOption[];
      label: string;
      id: string;
      placeholder?: string;
    }
  | {
      filterType: 'MULTIPLE';
      selected: SelectButtonOption[] | undefined;
      options: SelectButtonOption[];
      label: string;
      id: string;
      placeholder?: string;
    }
  | {
      filterType: 'DATE_RANGE';
      selected: [Date | undefined, Date | undefined] | undefined;
      options?: undefined;
      label: string;
      id: string;
      placeholder?: string;
    };

const Filters = ({
  fetchFiltered,
  loading,
  filters,
  fetchFilteredAll,
  fetchFilteredDates,
}: FiltersProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const queryParams = useQueryParams();
  const [moreFiltersForm, setMoreFiltersForm] = useState(false);

  const handleSubmitMultipleFilter = (
    filterOptions: SelectButtonOption[] | SelectButtonOption,
    id: string
  ) => {
    if (Array.isArray(filterOptions)) {
      /**
       * Below is a temporary solution removing duplicating options for dropdown.
       * Clicking on selected item does not remove the item from selected options
       * eg. Generic claims claim type
       */
      const duplicateRemovedOptionIds: string[] = [];

      filterOptions.forEach(({ id }) => {
        if (!duplicateRemovedOptionIds.includes(id)) {
          duplicateRemovedOptionIds.push(id);
        }
      });

      const duplicateRemovedOptions = duplicateRemovedOptionIds.map((option) =>
        filterOptions.find(({ id }) => id === option)
      ) as SelectButtonOption<void>[];

      if (!duplicateRemovedOptions || duplicateRemovedOptions.length === 0) {
        queryParams.delete(id);
      } else {
        queryParams.set(
          id,
          duplicateRemovedOptions.map((option) => option.id).join(',')
        );
      }
      fetchFiltered(duplicateRemovedOptions, id);
      history.replace(`?${queryParams.toString()}`);
    } else {
      if (filterOptions.id === 'any') {
        queryParams.delete(id);
      } else {
        queryParams.set(id, filterOptions.id);
      }
      fetchFiltered(filterOptions, id);
      history.replace(`?${queryParams.toString()}`);
    }
  };

  const numberOfActiveFilters = (filterList: Filter[]) => {
    return filterList.reduce((prev, cur) => {
      if (!cur.selected) return prev;
      if (cur.filterType === 'TEXT' && cur.selected.id === 'any') return prev;
      if (cur.filterType === 'MULTIPLE' && cur.selected.length === 0)
        return prev;
      return prev + 1;
    }, 0);
  };

  const clearAllFilters = () => {
    const empty: {
      [key: string]: SelectButtonOption | SelectButtonOption[] | undefined;
    } = {};
    const emptyDates: {
      [key: string]: [Date, Date] | undefined;
    } = {};

    const emptyFilters = filters?.reduce((prev, cur) => {
      prev[cur.id] =
        cur.filterType === 'MULTIPLE' ? [] : { label: 'Any', id: 'any' };
      queryParams.delete(cur.id);
      return prev;
    }, empty);
    const emptyDateFilters = filters?.reduce((prev, cur) => {
      if (cur.filterType === 'DATE_RANGE') {
        queryParams.delete(cur.id);
        prev[cur.id] = undefined;
      }
      return prev;
    }, emptyDates);

    history.replace(`?${queryParams.toString()}`);
    fetchFilteredAll(emptyFilters);
    fetchFilteredDates(emptyDateFilters);
  };

  return (
    <>
      {filters.slice(0, 2).map((filter) => {
        if (filter.filterType === 'MULTIPLE' || filter.filterType === 'TEXT') {
          return (
            <SelectButton
              key={filter.id}
              withDot={filter.options.some(({ color }) => color)}
              disabled={loading}
              selected={filter.selected}
              handleOnChange={(
                options: SelectButtonOption[] | SelectButtonOption
              ) => handleSubmitMultipleFilter(options, filter.id)}
              options={filter.options}
              label={filter.label}
              multiple={filter.filterType === 'MULTIPLE'}
            />
          );
        }
        return null;
      })}
      {filters.length > 2 && (
        <>
          <Button
            buttonType="white"
            Icon={AdjustmentsIcon}
            onClick={() => setMoreFiltersForm(true)}
            disabled={loading}
            className={
              numberOfActiveFilters(filters.slice(2)) ? '!text-indigo-500' : ''
            }
          >
            Filters{' '}
            {numberOfActiveFilters(filters.slice(2))
              ? `(${numberOfActiveFilters(filters.slice(2))})`
              : ''}
          </Button>
          {moreFiltersForm && (
            <MoreFiltersForm
              open={moreFiltersForm}
              setOpen={setMoreFiltersForm}
              filterDetails={filters}
              fetchFilteredAll={fetchFilteredAll}
              fetchFilteredDates={fetchFilteredDates}
            />
          )}
        </>
      )}
      {!!numberOfActiveFilters(filters) && filters.length > 1 && (
        <Button
          buttonType="transparent"
          disabled={loading}
          onClick={clearAllFilters}
        >
          {t('components.table.filter.reset', 'Reset')}
        </Button>
      )}
    </>
  );
};

export default Filters;
