import { TFunction } from '@getpopsure/i18n-react';
import { DocumentsSectionData } from 'components/DocumentsCard';
import { PublicHealthPolicy } from 'models/publicHealthPolicy';

export const getDocumentSections =
  (t: TFunction) =>
  (data?: {
    hrPublicHealthPolicy: PublicHealthPolicy;
  }): DocumentsSectionData[] => {
    const policyDocuments: DocumentsSectionData = {
      title: t(
        'admin.hr.publicHealth.policyDocuments.label',
        'Policy documents'
      ),
      documents: [
        ...(data?.hrPublicHealthPolicy.preliminaryConfirmationOfCoverage
          ? [
              {
                id: 'preliminary',
                fileLink:
                  data?.hrPublicHealthPolicy.preliminaryConfirmationOfCoverage
                    ?.url,
                fileName: 'Preliminary confirmation of coverage',
              },
            ]
          : []),
        ...(data?.hrPublicHealthPolicy.confirmationOfCoverageFromProviderUrl ||
        data?.hrPublicHealthPolicy.confirmationOfCoverageUrl
          ? [
              {
                id: 'final',
                fileLink:
                  data?.hrPublicHealthPolicy
                    .confirmationOfCoverageFromProviderUrl ??
                  data.hrPublicHealthPolicy.confirmationOfCoverageUrl,
                fileName: 'Confirmation of coverage',
              },
            ]
          : []),
      ],
    };

    return [policyDocuments];
  };
