import classNames from 'classnames';

export const noDocumentsContainer = classNames(
  'px-[24px]',
  'py-[32px]',
  'border-t',
  'border-gray-200',
  'flex',
  'justify-center'
);

export const noDocuments = classNames('text-sm', 'text-gray-600');

export const deleteModalDescription = classNames('text-sm', 'text-gray-600');
