import { GenericClaimStatus } from './models';

export const statusNameMapper: Record<GenericClaimStatus, string> = {
  RECEIVED: 'Received',
  INCOMPLETE: 'Missing info',
  MISSING_INFO_RECEIVED: 'Info received',
  SUBMITTED_TO_PROVIDER: 'Sent',
  OTHER_PAID_OUT: 'Paid to 3rd party',
  FEATHER_PAID_OUT: 'Feather paid out',
  CUSTOMER_PAID_OUT: 'Customer paid out',
  DENIED: 'Denied',
  DUPLICATE: 'Duplicate',
  DROPPED_OUT: 'Dropped out',
  CLOSED: 'Closed',
};
