import { ApolloQueryResult } from '@apollo/client';
import { BadgeColor } from 'components/Badge/Badge';
import { CheckBadgeType } from 'components/CheckBadge/CheckBadge';
import { CheckBox } from 'components/CheckBox';
import Dropdown from 'components/Dropdown';
import SearchBar from 'components/SearchBar';
import { SelectButtonOption } from 'components/SelectButton';
import { PolicyStatus } from 'models/publicHealthStatus';
import { PublicHealthStatus, Status } from 'models/statusColor';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import Filters, { Filter } from './Filters';
import Pagination from './Pagination';
import type { SortableColumnHeaderProps } from './SortableColumnHeader';
import * as styles from './styles';
import type { TableCellProps } from './TableCell';
import TableEmptyState, { EmptyStateMessage } from './TableEmptyState';
import TableLoadingState from './TableLoadingState';
import TableRow from './TableRow';
import { markSelectionRange } from './utils';

export type TextColor = 'light' | 'dark';
export type BorderType = 'right' | 'left' | 'none';
export type SortingOrder = 'asc' | 'desc' | 'none';

export interface TableData {
  numberOfRows: number;
  numberOfRowsPerPage: number;
  headers: HeaderInformation[];
  rows: Row[];
}
export interface HeaderInformation {
  id: string;
  label: string;
  minWidth: string;
  width: string;
  sticky: boolean;
  threshold: string;
  border: BorderType;
  component: React.ComponentType<SortableColumnHeaderProps>;
}

export interface Row {
  cells: Array<Cell>;
  resourceLink?: string;
  resourceOnClick?: (data: any) => void;
}

type CellType =
  | 'TEXT'
  | 'DATE'
  | 'STATUS'
  | 'STATUS_WITH_LINK'
  | 'OCCUPATION'
  | 'FILE'
  | 'CHECK'
  | 'DOT'
  | 'TEXT_WITH_CHECK'
  | 'LINK';

/**
 * TODO: [KONG] Below type and related components need refactor to accurately
 * determine which props are needed and what not
 */

export type Cell =
  | {
      cellType: Exclude<
        CellType,
        'STATUS' | 'STATUS_WITH_LINK' | 'OCCUPATION' | 'DOT' | 'TEXT_WITH_CHECK'
      >;
      component: React.ComponentType<TableCellProps>;
      textColor?: string;
      data: string;
      statusMapping?: undefined;
      check?: undefined;
      completedStudentChecklist?: undefined;
      badgeColor?: undefined;
      badgeCaption?: string;
      title?: string;
      onClick?: (metaData: Record<string, any>) => void;
      href?: undefined;
    }
  | {
      cellType: 'STATUS';
      component: React.ComponentType<TableCellProps>;
      textColor?: string;
      data: string;
      statusMapping:
        | ((status: PolicyStatus) => PublicHealthStatus)
        | ((status: string) => Status);
      check?: undefined;
      completedStudentChecklist?: undefined;
      badgeColor?: undefined;
      badgeCaption?: undefined;
      title?: string;
      onClick?: (metaData: Record<string, any>) => void;
      href?: undefined;
    }
  | {
      cellType: 'STATUS_WITH_LINK';
      href: string;
      component: React.ComponentType<TableCellProps>;
      textColor?: string;
      data: string;
      statusMapping:
        | ((status: PolicyStatus) => PublicHealthStatus)
        | ((status: string) => Status);
      check?: undefined;
      completedStudentChecklist?: undefined;
      badgeColor?: undefined;
      badgeCaption?: undefined;
      title?: string;
      onClick?: (metaData: Record<string, any>) => void;
    }
  | {
      cellType: 'OCCUPATION';
      component: React.ComponentType<TableCellProps>;
      textColor?: string;
      data: string;
      completedStudentChecklist: boolean;
      check?: undefined;
      statusMapping?: undefined;
      badgeColor?: undefined;
      badgeCaption?: undefined;
      title?: string;
      onClick?: (metaData: Record<string, any>) => void;
      href?: undefined;
    }
  | {
      cellType: 'TEXT_WITH_CHECK';
      component: React.ComponentType<TableCellProps>;
      textColor?: string;
      data: string;
      completedStudentChecklist?: undefined;
      check?: CheckBadgeType;
      statusMapping?: undefined;
      badgeColor?: undefined;
      badgeCaption?: string;
      title?: string;
      onClick?: (metaData: Record<string, any>) => void;
      href?: undefined;
    }
  | {
      cellType: 'DOT';
      component: React.ComponentType<TableCellProps>;
      textColor?: string;
      data: boolean;
      statusMapping?: undefined;
      check?: undefined;
      completedStudentChecklist?: undefined;
      badgeColor: BadgeColor;
      badgeCaption: string;
      title?: string;
      onClick?: (metaData: Record<string, any>) => void;
      href?: undefined;
    }
  | {
      cellType: 'BUTTON';
      component: React.ComponentType<TableCellProps>;
      title: string;
      onClick: (metaData: Record<string, any>) => void;

      // These fields are not used by this component, see above for TODO
      data: string;
      textColor?: string;
      statusMapping?: undefined;
      check?: undefined;
      completedStudentChecklist?: undefined;
      badgeColor?: BadgeColor;
      badgeCaption?: string;
      href?: undefined;
    };

export type FetchMoreArgs = {
  variables: {
    offset: number;
    searchString?: string;
    sortColumn?: string;
    sortOrder?: string;
    filterStatus?: string | string[];
    filterType?: string;
    filterCompany?: string;
  };
};

export interface SortingKey {
  column: string;
  order: SortingOrder;
}

export interface TableProps {
  selectedRows?: Record<number, boolean>;
  setSelectedRows?: React.Dispatch<
    React.SetStateAction<Record<number, boolean>>
  >;
  data: {
    headers: Array<HeaderInformation>;
    rows: Array<Row>;
    numberOfRows: number;
    numberOfRowsPerPage: number;
  };
  title: string;
  fetchMore: (args: FetchMoreArgs) => Promise<ApolloQueryResult<any>>;
  setOffset: (args: number) => void;
  offset: number;
  limit: number;
  searchString: string;
  setSearchString: (searchString: string) => void;
  searchPlaceholder: string;
  sortKey: SortingKey;
  setSortKey: (sortKey: SortingKey) => void;
  filterDetails?: Filter[];
  setFilterOption?: (newFilter: {
    [key: string]: SelectButtonOption | SelectButtonOption[] | undefined;
  }) => void;
  filters?: {
    [key: string]: SelectButtonOption | SelectButtonOption[] | undefined;
  };
  setDateFilter?: (newFilter: {
    [key: string]: [Date | undefined, Date | undefined] | undefined;
  }) => void;
  dateFilter?: {
    [key: string]: [Date | undefined, Date | undefined] | undefined;
  };
  loading: boolean;
  emptyStateMessage: EmptyStateMessage;
  actionButton?: React.ReactNode;
  setLimit?: (newLimit: number) => void;
}

const CUSTOM_ROWS_PER_PAGE_OPTIONS = ['12', '25', '100', '250', '500'] as const;

const limitDropdownOptions = CUSTOM_ROWS_PER_PAGE_OPTIONS.map((n) => ({
  id: String(n),
  label: String(n),
  show: true,
}));

const Table = ({
  data,
  title,
  fetchMore,
  setOffset,
  offset,
  limit,
  searchString,
  setSearchString,
  searchPlaceholder,
  sortKey,
  setSortKey,
  filters,
  setFilterOption,
  loading,
  emptyStateMessage,
  actionButton,
  filterDetails,
  dateFilter,
  setDateFilter,
  selectedRows,
  setSelectedRows,
  setLimit,
}: TableProps) => {
  const [currentPage, setCurrentPage] = useState(offset / limit + 1);

  useEffect(() => {
    setCurrentPage(offset / limit + 1);
  }, [offset, limit]);

  const history = useHistory();
  const handleResourceClick = (link: string | undefined) => {
    if (link) {
      history.push(link);
    }
  };

  const fetchSearch = (query: string) => {
    setSearchString(query);
    setOffset(0);
    fetchMore({
      variables: {
        offset: 0,
        searchString: query,
      },
    }).catch(() => {});
  };

  const fetchPage = (page: number) => {
    const newOffset = (page - 1) * data.numberOfRowsPerPage;
    setOffset(newOffset);
    fetchMore({
      variables: {
        offset: newOffset,
      },
    }).catch(() => {});
  };

  const fetchSorted = (newSortingKey: SortingKey) => {
    setSortKey(newSortingKey);
    setOffset(0);
    fetchMore({
      variables: {
        offset: 0,
        sortColumn: newSortingKey.column,
        sortOrder: newSortingKey.order,
      },
    }).catch(() => {});
  };

  const fetchFiltered = (
    newFilterOption: SelectButtonOption | SelectButtonOption[] | undefined,
    filterId: string
  ) => {
    setFilterOption?.({ ...filters, [filterId]: newFilterOption });
    setOffset(0);
    fetchMore({
      variables: {
        offset: 0,
        [filterId]: Array.isArray(newFilterOption)
          ? newFilterOption.map((option) => option.id)
          : newFilterOption?.id,
      },
    }).catch(() => {});
  };

  const fetchFilteredAll = (options: {
    [key: string]: SelectButtonOption | SelectButtonOption[] | undefined;
  }) => {
    setFilterOption?.(options);
    setOffset(0);
  };

  const fetchFilteredDates = (options: {
    [key: string]: [Date | undefined, Date | undefined] | undefined;
  }) => {
    setDateFilter?.(options);
    setOffset(0);
  };

  const hasSelectedFilter = (): boolean => {
    return !!filterDetails?.find((filter) => {
      return Array.isArray(filter?.selected)
        ? filter?.selected.length !== 0
        : filter?.selected?.id !== 'any';
    });
  };

  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [lastSelectedRowIndex, setLastSelectedRowIndex] = useState<
    number | undefined
  >();

  const onClickSelectAll = () => {
    setSelectAll((oldStatus) => {
      const newStatus = !oldStatus;

      const rowSelection = markSelectionRange(
        0,
        data.rows.length ?? 0,
        newStatus
      );

      setSelectedRows?.(rowSelection);

      return newStatus;
    });
  };

  const onCheckBoxClick = (clickedRowIndex: number) => () => {
    setLastSelectedRowIndex(clickedRowIndex);
    setSelectedRows?.((currentlySelectedRows) => ({
      ...currentlySelectedRows,
      [clickedRowIndex]: !currentlySelectedRows[clickedRowIndex],
    }));
  };

  const onShiftCheckBoxClick = (clickedRowIndex: number) => () => {
    if (lastSelectedRowIndex === undefined) {
      setLastSelectedRowIndex(clickedRowIndex);
      return;
    }

    const newStatus = !selectedRows?.[clickedRowIndex];

    let updatedRowSelection: Record<number, boolean>;

    if (clickedRowIndex < lastSelectedRowIndex) {
      // Shift-clicking triggers both this and the regular on-click handler so we need to omit the clicked checkbox from the range. (shift the startIndex by 1)
      updatedRowSelection = markSelectionRange(
        clickedRowIndex + 1,
        lastSelectedRowIndex,
        newStatus
      );
    } else {
      // Nothing needs to be done here to omit the clicked checkbox since markSelectionRange's loop exits on the endIndex (=clickedRowIndex) anyway
      updatedRowSelection = markSelectionRange(
        lastSelectedRowIndex,
        clickedRowIndex,
        newStatus
      );
    }

    setSelectedRows?.((currentlySelectedRows) => ({
      ...currentlySelectedRows,
      ...updatedRowSelection,
    }));

    setLastSelectedRowIndex(clickedRowIndex);
  };

  const anySelected = Object.values(selectedRows ?? {}).some((value) => value);

  useEffect(() => {
    // Clear selections when data is changing
    setSelectedRows?.({});
    setSelectAll(false);
    setLastSelectedRowIndex(undefined);
  }, [data.numberOfRows, offset]);

  useEffect(() => {
    // Clear "selectAll" checkbox if no row is selected
    if (!anySelected && selectAll) {
      setSelectAll(false);
    }
  }, [anySelected]);

  return (
    <div className={styles.tableWrapper}>
      <h1 className={styles.title}>{title}</h1>
      <div className={styles.actionsBar}>
        <div className={styles.searchBar}>
          <SearchBar
            searchString={searchString}
            fetchSearch={fetchSearch}
            loading={loading}
            placeholder={searchPlaceholder}
            includePlaceholderDropdown
          />
          {filters && (
            <Filters
              fetchFiltered={fetchFiltered}
              fetchFilteredAll={fetchFilteredAll}
              loading={loading}
              filters={filterDetails ?? []}
              fetchFilteredDates={fetchFilteredDates}
            />
          )}
        </div>
        {actionButton && !loading ? actionButton : <></>}
      </div>
      <div className={styles.tableContent}>
        <table className={styles.table}>
          <thead>
            <tr className={styles.tableHeader}>
              {setSelectedRows && (
                <td className="p-3">
                  <CheckBox
                    checked={selectAll}
                    setChecked={onClickSelectAll}
                    muted={!anySelected}
                  />
                </td>
              )}
              {data.headers.map((header) => (
                <header.component
                  header={header}
                  key={header.id}
                  sortingKey={sortKey}
                  fetchSorted={fetchSorted}
                />
              ))}
            </tr>
          </thead>
          {!loading && (
            <tbody>
              {data.rows.map((row, rowIndex) => (
                <TableRow
                  key={`${data.headers[0].id}-${rowIndex}`}
                  rowIndex={rowIndex}
                  row={row}
                  headers={data.headers}
                  checkboxProps={
                    setSelectedRows
                      ? {
                          onCheckBoxClick: onCheckBoxClick(rowIndex),
                          onShiftCheckBoxClick: onShiftCheckBoxClick(rowIndex),
                          checked: selectedRows?.[rowIndex] ?? false,
                          muted: !anySelected,
                        }
                      : undefined
                  }
                />
              ))}
            </tbody>
          )}
        </table>
      </div>
      {data.numberOfRows === 0 && !loading && (
        <TableEmptyState
          defaultMessage={emptyStateMessage}
          searchString={searchString}
          filterOption={hasSelectedFilter()}
        />
      )}
      {!loading ? (
        <div className={styles.pagination}>
          <div className="flex gap-4">
            {setLimit && (
              <div className="mt-2 flex items-center gap-2">
                <p className="text-sm text-gray-500 "> Items per page</p>
                <Dropdown
                  type="white"
                  options={limitDropdownOptions}
                  disabled={false}
                  selected={
                    limitDropdownOptions.find(
                      ({ id }) => Number(id) === limit
                    ) || limitDropdownOptions[0]
                  }
                  setSelected={(option) => {
                    setOffset(0);
                    setLimit?.(Number(option.id));
                  }}
                />
              </div>
            )}
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalItems={data.numberOfRows}
              limitItems={data.numberOfRowsPerPage}
              fetchPage={fetchPage}
            />
          </div>
        </div>
      ) : (
        <TableLoadingState />
      )}
    </div>
  );
};

export default Table;
