import { DocumentsSectionData } from 'components/DocumentsCard';
import { SelectMenuOption } from 'components/SelectMenu';

import { retrieveFileFriendlyName } from '../../../../shared/utils/retrieveFileFriendlyName';
import { GenericFileUploadType } from '../models/GenericFileUploadType';
import { GenericPolicyData } from '../models/GenericPolicyData';

export const fileTypeOptions: SelectMenuOption<GenericFileUploadType>[] = [
  {
    id: 'CONFIRMATION_OF_COVERAGE',
    label: 'Confirmation of coverage',
  },
  {
    id: 'APPLICATION',
    label: 'Application summary',
  },
  {
    id: 'OTHER',
    label: 'Other',
  },
];

export const getDocumentSections = (
  data?: GenericPolicyData
): DocumentsSectionData<'POLICY_DOCUMENT'>[] => {
  const policyDocuments: DocumentsSectionData<'POLICY_DOCUMENT'> = {
    title: 'Policy documents',
    documents: [],
  };
  const featherUploads: DocumentsSectionData<'POLICY_DOCUMENT'> = {
    title: 'Feather uploads',
    documents: [],
  };

  data?.genericPolicy.policyDocuments.forEach((document) => {
    if (document.type === 'CONFIRMATION_OF_COVERAGE') {
      policyDocuments.documents.push({
        id: document.id,
        fileLink: document.url,
        fileName: 'Confirmation of coverage',
        resizedUrl: document.resizedUrl,
        blobName: document.blobName,
        canDelete: true,
        documentType: 'POLICY_DOCUMENT',
      });
    } else if (document.type === 'APPLICATION') {
      policyDocuments.documents.push({
        id: document.id,
        fileLink: document.url,
        fileName: 'Application summary',
        resizedUrl: document.resizedUrl,
        blobName: document.blobName,
        canDelete: true,
        documentType: 'POLICY_DOCUMENT',
      });
    } else {
      featherUploads.documents.push({
        id: document.id,
        fileLink: document.url,
        fileName: document.name,
        resizedUrl: document.resizedUrl,
        blobName: document.blobName,
        canDelete: true,
        documentType: 'POLICY_DOCUMENT',
      });
    }
  });

  // TODO: remove once Expat ES moved out of the generic template
  data?.genericPolicy.questionnaireDownloadDocuments.forEach(
    ({ url, blobName }) => {
      policyDocuments.documents.push({
        fileLink: url,
        fileName: retrieveFileFriendlyName(blobName),
        resizedUrl: url,
      });
    }
  );

  return [policyDocuments, featherUploads];
};
