import type { ApolloError } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import classNames from 'classnames';
import Button from 'components/Button';
import { FormInputLabel } from 'components/FormInputLabel';
import Input from 'components/Input';
import SelectMenu, { SelectMenuOption } from 'components/SelectMenu';
import TaskActionsBuilder from 'components/TaskEngine/TaskActionsBuilder/TaskActionsBuilder';
import { TaskActionsFormInput } from 'components/TaskEngine/TaskEngine.types';
import TextArea from 'components/TextArea';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { InsuranceType } from 'shared/insurances/types';

import { Tag, TaskTemplate, TaskTemplateSchema } from '../taskTemplate.types';
import * as styles from './styles';

interface TaskTemplateFormProps {
  setOpen: (open: boolean) => void;
  onSubmit: (payload: TaskTemplate) => void;
  loading: boolean;
  error?: ApolloError;
  formData?: TaskTemplate;
}

export const insuranceTypeDropdownOptions: Array<
  SelectMenuOption<InsuranceType>
> = [
  {
    id: 'PRIVATE_HEALTH',
    label: 'Private health',
  },
  {
    id: 'PUBLIC_HEALTH',
    label: 'Public health',
  },
  {
    id: 'INCOMING',
    label: 'Expat health',
  },
  {
    id: 'BIKE',
    label: 'Bike',
  },
  {
    id: 'COMPANY_HEALTH',
    label: 'Company health',
  },
  {
    id: 'DENTAL',
    label: 'Dental',
  },
  {
    id: 'DISABILITY',
    label: 'Disability',
  },
  {
    id: 'DOG_LIABILITY',
    label: 'Dog liability',
  },
  {
    id: 'HOUSEHOLD',
    label: 'Household',
  },
  {
    id: 'LEGAL',
    label: 'Legal',
  },
  {
    id: 'LIABILITY',
    label: 'Liability',
  },
  {
    id: 'PET_HEALTH',
    label: 'Pet health',
  },
  {
    id: 'TRAVEL',
    label: 'Travel',
  },
];

const tagDropdownOptions: Array<SelectMenuOption<Tag>> = [
  {
    id: 'POLICIES',
    label: 'Policies',
  },
  {
    id: 'CLAIMS',
    label: 'Claims',
  },
];

const TaskTemplateForm = ({
  setOpen,
  onSubmit,
  loading,
  error,
  formData,
}: TaskTemplateFormProps) => {
  const { control, register, handleSubmit, setValue, resetField } =
    useForm<TaskActionsFormInput>({
      defaultValues: formData
        ? {
            name: formData.name,
            title: formData.data.title,
            description: formData.data.description,
            actions: formData.data.actions,
          }
        : {
            name: '',
            title: '',
            description: '',
            actions: [{ type: 'INPUT' }],
          },
    });
  const defaultInsuranceType =
    formData &&
    insuranceTypeDropdownOptions.find(
      (option) => option.id === formData.insuranceType
    );
  const defaultTag =
    formData && tagDropdownOptions.find((option) => option.id === formData.tag);

  const [selectedTag, setSelectedTag] = useState<SelectMenuOption<Tag>>(
    defaultTag || tagDropdownOptions[0]
  );
  const [selectedInsuranceType, setSelectedInsuranceType] = useState<
    SelectMenuOption<InsuranceType>
  >(defaultInsuranceType || insuranceTypeDropdownOptions[0]);

  const { t } = useTranslation();

  const submit: SubmitHandler<TaskActionsFormInput> = (data) => {
    const { name, title, description, actions } = data;
    const taskTemplate = {
      name,
      insuranceType: selectedInsuranceType?.id,
      tag: selectedTag.id,
      data: {
        title,
        description,
        actions: actions.map((field) => ({
          type: field.type,
          attributes: {
            ...field.attributes,
          },
        })),
      },
    };
    const parsedTaskTemplate = TaskTemplateSchema.parse(taskTemplate);
    onSubmit(parsedTaskTemplate);
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className={styles.section}>
        <FormInputLabel
          htmlFor="insuranceType"
          title={t(
            'taskTemplates.createTaskTemplate.insuranceType.label',
            'Vertical'
          )}
        />
        <SelectMenu
          color="gray"
          options={insuranceTypeDropdownOptions}
          selected={selectedInsuranceType}
          setSelected={setSelectedInsuranceType}
          placeholder={t(
            'taskTemplates.createTaskTemplate.insuranceType.placeholder',
            'Select the related vertical'
          )}
        />
      </div>
      <div className={styles.section}>
        <FormInputLabel
          htmlFor="tag"
          title={t('taskTemplates.createTaskTemplate.tag.label', 'Area')}
        />
        <SelectMenu
          color="gray"
          options={tagDropdownOptions}
          selected={selectedTag}
          setSelected={setSelectedTag}
          placeholder={t(
            'taskTemplates.createTaskTemplate.tag.placeholder',
            'Select the related area'
          )}
        />
      </div>
      <div className={styles.section}>
        <FormInputLabel
          htmlFor="name"
          title={t(
            'taskTemplates.createTaskTemplate.name.label',
            'Template name'
          )}
        />
        <Input
          id="name"
          color="gray"
          placeholder={t(
            'taskTemplates.createTaskTemplate.name.placeholder',
            'e.g Task name — Language version'
          )}
          required
          {...register('name')}
        />
      </div>

      <div className={styles.section}>
        <FormInputLabel
          htmlFor="title"
          title={t(
            'taskTemplates.createTaskTemplate.title.label',
            'Task title'
          )}
        />
        <Input
          id="title"
          color="gray"
          placeholder={t(
            'taskTemplates.createTaskTemplate.title.placeholder',
            'Task title for customer'
          )}
          required
          {...register('title')}
        />
      </div>

      <div className={styles.section}>
        <FormInputLabel
          htmlFor="description"
          title={t(
            'taskTemplates.createTaskTemplate.description.label',
            'Task description'
          )}
        />
        <TextArea
          id="description"
          color="gray"
          placeholder={t(
            'taskTemplates.createTaskTemplate.description.placeholder',
            'Task description for customer'
          )}
          required
          {...register('description')}
        />
      </div>

      <TaskActionsBuilder
        control={control}
        register={register}
        setValue={setValue}
        resetField={resetField}
      />

      <div className={classNames('flex', 'justify-end', 'mt-[24px]')}>
        <Button
          type="button"
          buttonType="secondary"
          onClick={() => setOpen(false)}
        >
          {t('admin.modal.cancel.action', 'Cancel')}
        </Button>
        <Button
          loading={loading}
          type="submit"
          buttonType="primary"
          className={classNames('ml-2', 'min-w-[140px]')}
        >
          {formData
            ? t('taskTemplates.createTaskTemplate.save.action', 'Save')
            : t('taskTemplates.createTaskTemplate.create.action', 'Create')}
        </Button>
      </div>
      {error && <p className={styles.errorMessage}>{error.message}</p>}
    </form>
  );
};

export default TaskTemplateForm;
