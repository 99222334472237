import { useMutation, useQuery } from '@apollo/client';
import Button from 'components/Button';
import NavBar from 'components/NavBar';
import Table, { HeaderInformation, TableData } from 'components/Table';
import ColumnHeader from 'components/Table/ColumnHeader';
import { Filter } from 'components/Table/Filters';
import SortableColumnHeader from 'components/Table/SortableColumnHeader';
import TableCell from 'components/Table/TableCell';
import TableCellDot from 'components/Table/TableCellDot';
import { EmptyStateMessage } from 'components/Table/TableEmptyState';
import { useOffset } from 'hooks/useOffset';
import useTableQueryParams from 'hooks/useTableQueryParams';
import Page403 from 'pages/errors/403';
import PageGenericError from 'pages/errors/genericError';
import { Helmet } from 'react-helmet';
import { generatePath, useHistory } from 'react-router-dom';
import { paths } from 'routes/definedPaths';
import { checkUnauthenticatedErrors } from 'shared/errorHandling/unauthenticatedError';
import { checkUnauthorizedErrors } from 'shared/errorHandling/unauthorizedErrors';

import { CREATE_SD_CATEGORY } from '../sdCategories.mutations';
import { GET_SD_CATEGORIES } from '../sdCategories.queries';
import { SDCategory } from '../sdCategories.types';
import * as styles from './SDCategories.List.styles';

const filterSenderOptions = [
  {
    id: 'all',
    label: 'All senders',
    title: 'All senders',
    show: true,
  },
  {
    id: 'advigonOnly',
    label: 'Advigon',
    title: 'Advigon',
    show: true,
  },
  {
    id: 'barmeniaOnly',
    label: 'Barmenia',
    title: 'Barmenia',
    show: true,
  },
  {
    id: 'hallescheOnly',
    label: 'Hallesche',
    title: 'Hallesche',
    show: true,
  },
];

const NUMBER_OF_DOCUMENTS_PER_PAGE = 12;

type GetSDCategoriesData = {
  sdCategories: SDCategory[];
  totalSDCategoriesCount: number;
};

type CreateSDCategoryData = {
  createSDCategory: {
    id: string;
  } | null;
};

export const SDCategoriesListPage = () => {
  const history = useHistory();
  const { offset, setOffset } = useOffset();
  const { search, setSearch, sort, setSort, filter, setFilter } =
    useTableQueryParams([
      {
        options: filterSenderOptions,
        multiple: false,
        id: 'filterSender',
      },
    ]);

  const { loading, error, data, fetchMore } = useQuery<GetSDCategoriesData>(
    GET_SD_CATEGORIES,
    {
      variables: {
        limit: NUMBER_OF_DOCUMENTS_PER_PAGE,
        offset,
        searchString: search,
        sortColumn: sort.column,
        sortOrder: sort.order,
        filterSender: !Array.isArray(filter.filterSender)
          ? filter.filterSender?.id ?? 'all'
          : 'all',
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const [createSDCategory, { loading: isCreating }] =
    useMutation<CreateSDCategoryData>(CREATE_SD_CATEGORY, {
      refetchQueries: [GET_SD_CATEGORIES],
    });

  if (error && checkUnauthenticatedErrors(error)) {
    history.push(paths.auth.path);
  }
  if (error && checkUnauthorizedErrors(error)) return <Page403 />;
  if (error) return <PageGenericError />;

  const defaultEmptyStateMessage: EmptyStateMessage = {
    title: 'No Categories',
    description: 'It looks like there are no categories yet.',
  };

  const tableFilters: Filter[] = [
    {
      options: filterSenderOptions,
      filterType: 'TEXT',
      selected: Array.isArray(filter.filterSender)
        ? undefined
        : filter.filterSender,
      label: 'Sender',
      id: 'filterSender',
      placeholder: 'Select sender',
    },
  ];

  const tableHeaders: HeaderInformation[] = [
    {
      id: 'name',
      label: 'Name',
      width: 'w-none',
      minWidth: 'min-w-[180px]',
      sticky: false,
      threshold: 'left-[40px]',
      border: 'none',
      component: SortableColumnHeader,
    },
    {
      id: 'senderName',
      label: 'Sender',
      width: 'w-none',
      minWidth: 'min-w-[180px]',
      sticky: false,
      threshold: 'left-0',
      border: 'none',
      component: ColumnHeader,
    },
    {
      id: 'whitelist',
      label: 'Search terms',
      width: 'w-none',
      minWidth: 'min-w-[120px]',
      sticky: false,
      threshold: 'left-[220px]',
      border: 'right',
      component: ColumnHeader,
    },
    {
      id: 'blacklist',
      label: 'Blacklist',
      width: 'w-none',
      minWidth: 'min-w-[180px]',
      sticky: false,
      threshold: 'left-0',
      border: 'none',
      component: ColumnHeader,
    },
    {
      id: 'requiredActionCaption',
      label: 'Required action',
      width: 'w-none',
      minWidth: 'min-w-[180px]',
      sticky: false,
      threshold: 'left-0',
      border: 'none',
      component: ColumnHeader,
    },
    {
      id: 'requiredActionColor',
      label: '',
      width: 'w-[40px]',
      minWidth: 'min-w-[40px]',
      sticky: false,
      threshold: 'left-0',
      border: 'none',
      component: ColumnHeader,
    },
    {
      id: 'customerIoEvent',
      label: 'Customer.io event',
      width: 'w-none',
      minWidth: 'min-w-[80px]',
      sticky: false,
      threshold: 'left-0',
      border: 'none',
      component: ColumnHeader,
    },
  ];

  const tableData: TableData = {
    numberOfRows: data?.totalSDCategoriesCount ?? 0,
    numberOfRowsPerPage: NUMBER_OF_DOCUMENTS_PER_PAGE,
    headers: tableHeaders,
    rows:
      !data || !data.sdCategories
        ? []
        : data.sdCategories.map((sdCategory) => ({
            resourceLink: generatePath(
              paths.scannedDocuments.categories.details.path,
              {
                id: sdCategory?.id,
              }
            ),
            cells: [
              {
                component: TableCell,
                cellType: 'TEXT',
                data: sdCategory.name || '',
                textColor: 'light',
              },
              {
                component: TableCell,
                cellType: 'TEXT',
                data: sdCategory.attributes.senderName || 'All',
                textColor: 'light',
              },
              {
                component: TableCell,
                cellType: 'TEXT',
                data:
                  sdCategory.whitelist.map((s) => `"${s}"`)?.join(', ') || '-',
                textColor: 'light',
              },
              {
                component: TableCell,
                cellType: 'TEXT',
                data:
                  sdCategory.blacklist.map((s) => `"${s}"`)?.join(', ') || '-',
                textColor: 'light',
              },
              {
                component: TableCell,
                cellType: 'TEXT',
                data: sdCategory.attributes.requiredAction?.caption || '-',
                textColor: 'light',
              },
              {
                component: TableCellDot,
                cellType: 'DOT',
                textColor: 'dark',
                data: !!sdCategory.attributes.requiredAction,
                badgeColor:
                  sdCategory.attributes.requiredAction?.color ?? 'gray',
                badgeCaption:
                  sdCategory.attributes.requiredAction?.caption ?? '',
              },
              {
                component: TableCell,
                cellType: 'TEXT',
                data: sdCategory.attributes.customerIoEvent || '',
                textColor: 'light',
              },
            ],
          })),
  };

  const showLoading =
    loading ||
    !data ||
    (data.sdCategories.length === 0 && data.totalSDCategoriesCount > 0);

  return (
    <>
      <Helmet>
        <title>Categories - Feather Admin Panel</title>
      </Helmet>
      <div className={styles.pageWrapper}>
        <NavBar current="scannedDocuments" />
        <div className={styles.tableWrapper}>
          <Table
            data={tableData}
            title="Categories"
            fetchMore={fetchMore}
            setOffset={setOffset}
            offset={offset}
            limit={NUMBER_OF_DOCUMENTS_PER_PAGE}
            searchString={search}
            setSearchString={setSearch}
            searchPlaceholder="Search by name"
            sortKey={sort}
            setSortKey={setSort}
            loading={showLoading}
            emptyStateMessage={defaultEmptyStateMessage}
            filters={filter}
            filterDetails={tableFilters}
            setFilterOption={setFilter}
            actionButton={
              <Button
                buttonType="primary"
                loading={isCreating}
                onClick={async () => {
                  const { data: newCategoryData } = await createSDCategory();
                  const newCategoryId = newCategoryData?.createSDCategory?.id;

                  if (newCategoryId) {
                    history.push(
                      generatePath(
                        paths.scannedDocuments.categories.details.path,
                        {
                          id: newCategoryId,
                        }
                      )
                    );
                  }
                }}
              >
                Create category
              </Button>
            }
          />
        </div>
      </div>
    </>
  );
};
