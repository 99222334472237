import { gql } from '@apollo/client';

import { DOG_LIABILITY_POLICY_ACTIVITY } from './activity';

export const GET_DOG_LIABILITY_POLICY = gql`
  ${DOG_LIABILITY_POLICY_ACTIVITY}
  query GetDogLiabilityPolicy($id: String!) {
    dogLiabilityPolicy(id: $id) {
      id
      policyNumber
      createdAt
      startDate
      archivedAt
      price
      paymentReceivedAt
      source
      campaign
      medium
      content
      term
      numberOfClaims
      publicStatus
      activeUntil
      providerId
      endDate
      deductibleAmount

      # Address
      address {
        street
        postcode
        country
        additionalInformation
        houseNumber
        city
      }

      # insured person
      insuredPerson {
        firstName
        lastName
        dateOfBirth
        email
        gender
      }

      # user
      user {
        id
        firstName
        lastName
        stripeToken
        intercomEUId
        email
        isDelinquent
        isFraudulent
        fraudulentAdditionalInfo
      }

      # documents
      policyDocuments {
        url
        id
        name
        blobName
        isRegeneratable
        resizedUrl
      }
      questionnaireDownloadDocuments {
        id
        url
        blobName
      }

      # policy cancelation request
      policyCancelationRequest {
        id
        cancelationConfirmedByProviderAt
        createdAt
        canceledOnStripeAt
        requestSentToProviderAt
      }

      # questionnaire
      questionnaire {
        id
        answers
      }

      pinnedNotes {
        id
        body
        createdAt
        adminUser {
          firstName
          lastName
        }
        updatedAt
      }

      # policy activity
      ...DogLiabilityPolicyActivity

      # Referral data
      referral {
        referralCode
        status
      }
    }
  }
`;
