import ActivitySection from 'components/ActivitySection/ActivitySection';
import DocumentsCard, { DocumentsSectionData } from 'components/DocumentsCard';
import { PinnedNotes } from 'components/Notes/PinnedNotes';
import { SelectMenuOption } from 'components/SelectMenu';
import { Status } from 'models/statusColor';
import {
  DELETE_EXPAT_POLICY_FILE,
  EXPAT_DOCUMENT_UPLOAD,
  REGENERATE_EXPAT_POLICY_FILE,
} from 'pages/policies/expatHealth/graphql/document';
import { GET_EXPAT_HEALTH_POLICY } from 'pages/policies/expatHealth/graphql/policy';
import { ExpatHealthPolicy } from 'pages/policies/expatHealth/models/ExpatHealthPolicy';
import { policyMappers } from 'shared/insurances';

import { retrieveFileFriendlyName } from '../../../../../shared/utils/retrieveFileFriendlyName';
import OtherExpatPoliciesCard from '../OtherExpatPoliciesCard/OtherExpatPoliciesCard';

type ExpatHealthDocumentType =
  | 'EXPAT_POLICY_DOCUMENTS'
  | 'EXPAT_OTHER_DOCUMENTS';
interface SideSectionProps {
  policy: ExpatHealthPolicy;
}

const SideSection = ({ policy }: SideSectionProps) => {
  const policyDocuments: DocumentsSectionData<ExpatHealthDocumentType> = {
    title: 'Policy documents',
    documents: [],
  };

  policy.questionnaireDownloadDocuments.forEach(({ id, url, blobName }) => {
    policyDocuments.documents.push({
      id,
      fileLink: url,
      fileName: retrieveFileFriendlyName(blobName),
      blobName,
    });
  });

  const documents: DocumentsSectionData<ExpatHealthDocumentType>[] = [
    policyDocuments,
    {
      title: 'Feather uploads',
      documents: policy.otherDocuments.map(
        ({ id, url, name, blobName, isRegeneratable }) => ({
          id,
          fileLink: url,
          fileName: name,
          blobName,
          isRegeneratable,
          canDelete: true,
          documentType: 'EXPAT_OTHER_DOCUMENTS',
        })
      ),
    },
  ];
  const documentTypeOptions: SelectMenuOption[] = [
    {
      id: 'EXPAT',
      label: 'Expat health document',
    },
  ];

  const statusMapper = (status: string): Status => {
    const policyMapper = policyMappers.INCOMING;
    return {
      text: {
        id: status,
        text: policyMapper?.statusMapper[status] ?? '',
      },
      color: policyMapper?.badgeColorMapper[status] ?? 'gray',
    };
  };

  return (
    <>
      <PinnedNotes
        notes={policy.pinnedNotes ?? []}
        refetchQueries={[GET_EXPAT_HEALTH_POLICY]}
      />
      <ActivitySection
        activity={policy?.activity ?? []}
        policyId={policy?.id ?? ''}
        statusMapping={statusMapper}
      />
      <OtherExpatPoliciesCard policy={policy} />
      <DocumentsCard<ExpatHealthDocumentType>
        sections={documents}
        fileTypeOptions={documentTypeOptions}
        getPolicyMutation={GET_EXPAT_HEALTH_POLICY}
        policyId={policy.id}
        mutation={EXPAT_DOCUMENT_UPLOAD}
        deleteMutation={DELETE_EXPAT_POLICY_FILE}
        regenerateMutation={
          policy.user?.isDelinquent === false
            ? REGENERATE_EXPAT_POLICY_FILE
            : undefined
        }
      />
    </>
  );
};

export default SideSection;
