import { useTranslation } from '@getpopsure/i18n-react';
import Card from 'components/Card';
import CardSection from 'components/Card/CardSection';
import Modal from 'components/Modal';
import { DocumentNode } from 'graphql';

import { DocumentsSectionData } from '..';
import DocumentCard from '../DocumentCard';
import * as styles from './styles';

export interface DeletableDocument<DocumentType> {
  id: string;
  fileName: string;
  blobName: string;
  documentType: DocumentType;
}

/**
 * TODO: [KONG] Update field names for non-policy pages
 */
interface DocumentsCardProps<DocumentType> {
  sections: DocumentsSectionData<DocumentType>[];
  policyId?: string;
  deleteMutation?: DocumentNode;
  regenerateMutation?: DocumentNode;
  deleteFile?: () => void;
  loadingMutation?: boolean;
  deletableFile?: Partial<DeletableDocument<DocumentType>>;
  setDeletableFile?: (file: Partial<DeletableDocument<DocumentType>>) => void;
  fileUploadComponent?: JSX.Element;
  handleRegenerateFile?: (documentType?: string) => void;
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
}

const DocumentsCardView = <DocumentType extends string | void>({
  sections,
  policyId,
  deleteMutation,
  regenerateMutation,
  deleteFile,
  loadingMutation,
  deletableFile,
  fileUploadComponent,
  setDeletableFile,
  handleRegenerateFile,
  openModal,
  setOpenModal,
}: DocumentsCardProps<DocumentType>) => {
  const { t } = useTranslation();

  const hasDocuments = sections.some(({ documents }) => documents.length);

  return (
    <>
      <Modal
        open={openModal}
        setOpen={setOpenModal}
        title={t('modal.document.delete.title', 'Delete document?')}
        confirmButtonLabel={t('modal.document.delete.cta', 'Delete')}
        handleConfirm={deleteFile}
        loading={loadingMutation}
      >
        <div className={styles.deleteModalDescription}>
          <p>
            {t(
              'modal.document.delete.description.1',
              'Do you really want to delete this document “{{fileName}}”?',
              {
                fileName: deletableFile?.fileName ?? '',
              }
            )}{' '}
            <span>
              {t('modal.document.delete.description.2', 'This process')}
            </span>{' '}
            <span className="font-bold">
              {t('modal.document.delete.description.3', 'cannot be undone.')}
            </span>
          </p>
        </div>
      </Modal>
      <Card
        title={t('admin.documents.title', 'Documents')}
        boldTitle={false}
        actionButton={fileUploadComponent}
      >
        {hasDocuments ? (
          sections.map(
            (section) =>
              section.documents.find(
                (document) =>
                  !!document.fileLink ||
                  (!document.fileLink && document.disabled)
              ) && (
                <CardSection title={section.title} key={section.title}>
                  {section.documents.map(
                    ({
                      id,
                      fileName,
                      fileLink,
                      blobName,
                      resizedUrl,
                      isRegeneratable,
                      documentType,
                      canDelete,
                      disabled,
                    }) => {
                      const displayDoc = fileLink || (!fileLink && disabled);
                      return (
                        displayDoc && (
                          <DocumentCard
                            key={fileName}
                            fileLink={fileLink ?? ''}
                            fileName={fileName}
                            resizedUrl={resizedUrl}
                            documentType={documentType ?? undefined}
                            documentId={id}
                            disabled={disabled ?? false}
                            onDelete={
                              deleteMutation &&
                              id &&
                              blobName &&
                              fileName &&
                              canDelete
                                ? () => {
                                    setOpenModal(true);
                                    setDeletableFile?.({
                                      id,
                                      fileName,
                                      blobName,
                                      ...(documentType && { documentType }),
                                    });
                                  }
                                : undefined
                            }
                            onRegenerate={
                              regenerateMutation && isRegeneratable && policyId
                                ? handleRegenerateFile
                                : undefined
                            }
                          />
                        )
                      );
                    }
                  )}
                </CardSection>
              )
          )
        ) : (
          <div className={styles.noDocumentsContainer}>
            <p className={styles.noDocuments}>
              {t(
                'component.documentsCard.noDocuments',
                'No documents uploaded yet.'
              )}
            </p>
          </div>
        )}
      </Card>
    </>
  );
};

export default DocumentsCardView;
