import {
  DocumentNode,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import { PaperAirplaneIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import Loader from 'components/Loader';
import TextArea from 'components/TextArea';
import { TimedLoader } from 'components/TimedLoader';
import useStateWithLocalStorage from 'hooks/useStateWithLocalStorage';
import { Note } from 'models/Note';
import Page403 from 'pages/errors/403';
import { useState } from 'react';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';

import { updateCreateNoteCache } from '../apollo/cacheModifiers';
import { CREATE_NOTE } from '../graphql/Notes.mutations';
import { GET_NOTES } from '../graphql/Notes.queries';
import { NoteType } from '../models/models';
import { NotesCard } from './components/NotesCard';
import * as styles from './styles';

interface Props {
  type: NoteType;
  userOrPolicyOrClaimId: string;
  refetchQueries?: DocumentNode[];
}

const renderNote =
  ({
    userOrPolicyOrClaimId,
    type,
    adminUserId,
    refetchQueries,
  }: {
    userOrPolicyOrClaimId: string;
    type: NoteType;
    adminUserId: string;
    refetchQueries?: DocumentNode[];
  }) =>
  (note: Note): JSX.Element => {
    return (
      <div className="mt-[16px]" key={note.id}>
        <NotesCard
          note={note}
          adminUserId={adminUserId}
          refetchQueries={refetchQueries}
          userOrPolicyOrClaimId={userOrPolicyOrClaimId}
          type={type}
        />
      </div>
    );
  };

export const NotesTab = ({
  type,
  userOrPolicyOrClaimId,
  refetchQueries,
}: Props) => {
  const { t } = useTranslation();

  const [newNote, setNewNote] = useState('');

  const { data, loading: fetchNotesLoading } = useQuery<{ notes: Note[] }>(
    GET_NOTES,
    {
      variables: {
        ...(type === 'USER' && { userId: userOrPolicyOrClaimId }),
        ...(type === 'POLICY' && { policyId: userOrPolicyOrClaimId }),
        ...(type === 'CLAIM' && { claimId: userOrPolicyOrClaimId }),
      },
    }
  );

  const alertBannersState = useReactiveVar(alertBanners);

  const onCreateNoteCompleted = () => {
    setNewNote('');

    const newAlertBanner: AlertBannerState = {
      id: uuidv4(),
      type: 'SUCCESS',
      message: 'Note successfully created',
    };

    setNewAlertBanner({ state: alertBannersState, newAlertBanner });
  };

  const onCreateNoteErrored = () => {
    const newAlertBanner: AlertBannerState = {
      id: uuidv4(),
      type: 'WARNING',
      message: 'Something went wrong. Please try again.',
    };

    setNewAlertBanner({ state: alertBannersState, newAlertBanner });
  };

  const [createNote, { loading: createNoteLoading }] = useMutation<Note>(
    CREATE_NOTE,
    {
      variables: {
        ...(type === 'USER' && { userId: userOrPolicyOrClaimId }),
        ...(type === 'POLICY' && { policyId: userOrPolicyOrClaimId }),
        ...(type === 'CLAIM' && { claimId: userOrPolicyOrClaimId }),
        body: newNote,
      },

      onCompleted: onCreateNoteCompleted,
      onError: onCreateNoteErrored,

      update: updateCreateNoteCache,
    }
  );

  const fetchedNotes = data?.notes;
  const unpinnedNotes = fetchedNotes?.filter(({ pinned }) => !pinned);
  const pinnedNotes = fetchedNotes?.filter(({ pinned }) => Boolean(pinned));

  const loading = fetchNotesLoading || createNoteLoading;

  const { value } = useStateWithLocalStorage('userInformation');
  const adminUserId = JSON.parse(value)?.id;

  if (!adminUserId) {
    return <Page403 />;
  }

  return (
    <div className={styles.container}>
      <header className={styles.headerContainer}>
        <h1 className={styles.header}>Notes</h1>
      </header>

      {loading ? (
        <TimedLoader />
      ) : (
        <section className={styles.addNoteContainer}>
          {/* Add note */}
          <section className={styles.addNoteInnerContainer}>
            <TextArea
              className={styles.addNoteTextArea}
              color="white"
              placeholder={t('notes.addNote.placeholder', 'Add a note')}
              value={newNote}
              onChange={(e) => setNewNote(e.target.value)}
              data-testid="create-note-textarea"
            />

            <button
              className={classNames(styles.createNoteButton, {
                [styles.disabledCreateNoteButton]:
                  !newNote || createNoteLoading,
              })}
              type="button"
              onClick={() => createNote()}
              aria-label="submit note"
              disabled={!newNote}
            >
              {createNoteLoading ? (
                <Loader
                  className={styles.addNoteLoader}
                  primaryColor="stroke-white"
                />
              ) : (
                <PaperAirplaneIcon className={styles.createNoteButtonIcon} />
              )}
            </button>
          </section>

          {/* Pinned notes */}
          {pinnedNotes && pinnedNotes.length > 0 && (
            <section className={styles.pinnedNotesSection}>
              <h4>{t('notes.pinnedNotes.title', 'Pinned notes')}</h4>
              {pinnedNotes.map(
                renderNote({
                  adminUserId,
                  refetchQueries,
                  userOrPolicyOrClaimId,
                  type,
                })
              )}
            </section>
          )}

          {/* All notes */}
          {unpinnedNotes && unpinnedNotes.length > 0 && (
            <section className={styles.allNotesSection}>
              <h4>{t('notes.recentNotes.title', 'Recent notes')}</h4>
              {unpinnedNotes.map(
                renderNote({
                  adminUserId,
                  refetchQueries,
                  userOrPolicyOrClaimId,
                  type,
                })
              )}
            </section>
          )}
        </section>
      )}
    </div>
  );
};
