import { DocumentsSectionData } from 'components/DocumentsCard';
import { SelectMenuOption } from 'components/SelectMenu';
import { retrieveFileFriendlyName } from 'shared/utils/retrieveFileFriendlyName';

import { ExpatSpainDocumentType } from '../models/ExpatSpainDocumentType';
import { ExpatSpainPolicyData } from '../models/ExpatSpainPolicy';

export const fileTypeOptions: SelectMenuOption<'POLICY_DOCUMENTS'>[] = [
  {
    id: 'POLICY_DOCUMENTS',
    label: 'Policy documents',
  },
];

export const getDocumentSections = (
  data?: ExpatSpainPolicyData
): DocumentsSectionData<ExpatSpainDocumentType>[] => {
  const policyDocuments: DocumentsSectionData<ExpatSpainDocumentType> = {
    title: 'Policy documents',
    documents: [],
  };

  data?.expatSpainPolicy.policyDocuments.forEach(
    ({ url, name, resizedUrl, id, blobName }) => {
      policyDocuments.documents.push({
        fileLink: url,
        fileName: name,
        resizedUrl,
        id,
        blobName,
        canDelete: true,
        documentType: 'POLICY_DOCUMENT',
      });
    }
  );

  data?.expatSpainPolicy.questionnaireDownloadDocuments.forEach(
    ({ url, blobName }) => {
      policyDocuments.documents.push({
        fileLink: url,
        fileName: retrieveFileFriendlyName(blobName),
        resizedUrl: url,
      });
    }
  );

  return [policyDocuments];
};
