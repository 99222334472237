import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { SortingKey } from 'components/Table';
import useQueryParams from 'hooks/useQueryParams';
import { useHistory } from 'react-router';

import { SortableColumnHeaderProps } from '../types';
import * as styles from './styles';

const headerDefaults = {
  minWidth: 'min-w-[130px]',
  width: 'w-[130px]',
  sticky: false,
  threshold: '',
  border: 'none',
};

const SortableColumnHeader = ({
  header,
  sortingKey,
}: SortableColumnHeaderProps) => {
  const headerConfig = { ...headerDefaults, ...header };
  const queryParams = useQueryParams();
  const history = useHistory();

  const isLgScreen = window.innerWidth > 1024;

  const handleSortChange = (): SortingKey => {
    if (sortingKey.column !== headerConfig.id) {
      queryParams.set('sortColumn', headerConfig.id);
      queryParams.set('sortOrder', 'asc');
      return { column: headerConfig.id, order: 'asc' };
    }
    if (sortingKey.order === 'asc') {
      queryParams.set('sortColumn', headerConfig.id);
      queryParams.set('sortOrder', 'desc');
      return { ...sortingKey, order: 'desc' };
    }
    if (sortingKey.order === 'desc') {
      queryParams.delete('sortColumn');
      queryParams.delete('sortOrder');
      return { column: 'none', order: 'none' };
    }

    return { column: 'none', order: 'none' };
  };

  const handleSubmitSort = () => {
    handleSortChange();
    history.push(`?${queryParams.toString()}`);
  };

  const getIcon = () => {
    if (sortingKey.column === headerConfig.id) {
      if (sortingKey.order === 'asc') {
        return (
          <ArrowDownIcon
            className={classNames(styles.arrowIcon, styles.sortArrowIcon)}
          />
        );
      }
      if (sortingKey.order === 'desc') {
        return (
          <ArrowUpIcon
            className={classNames(styles.arrowIcon, styles.sortArrowIcon)}
          />
        );
      }
    } else {
      return (
        <ArrowDownIcon
          className={classNames(
            styles.arrowIcon,
            styles.hidden,
            styles.noSortArrowIcon,
            'icon-no-sorting'
          )}
        />
      );
    }
  };

  return (
    <th
      key={headerConfig.id}
      scope="col"
      tabIndex={0}
      onClick={handleSubmitSort}
      className={classNames(
        styles.columnHeader,
        headerConfig.minWidth,
        headerConfig.width,
        'sortable-column-header',
        {
          [`${headerConfig.threshold} ${styles.sticky}`]:
            headerConfig.sticky && isLgScreen,
          [styles.borderRight]: headerConfig.border === 'right' && isLgScreen,
          [styles.borderLeft]: headerConfig.border === 'left' && isLgScreen,
        }
      )}
    >
      <div className={styles.contentWrapper}>
        <span>{headerConfig.label}</span>
        {getIcon()}
      </div>
    </th>
  );
};

export default SortableColumnHeader;
