import classNames from 'classnames';

export const filterWrapper = classNames('flex-col', 'space-y-3');

export const divider = classNames(
  'w-full',
  'border-t',
  'border-gray-300',
  'my-[24px]'
);

export const calendarPickerWrapper = classNames('flex', 'items-center');

export const calendarPickerLabel = classNames(
  'font-bold',
  'text-sm',
  'w-[128px]',
  'mr-[24px]'
);

export const calendarPicker = classNames('!w-[280px]');
