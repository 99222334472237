import CalendarDatePicker from 'components/CalendarDatePicker';
import Modal from 'components/Modal';
import { SelectButtonOption } from 'components/SelectButton';
import SelectMenu, { SelectMenuOption } from 'components/SelectMenu';
import useQueryParams from 'hooks/useQueryParams';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { getISODate } from 'shared/getISODate/getISODate';

import { Filter } from '..';
import * as styles from './styles';

interface MoreFiltersFormProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  filterDetails: Filter[];
  fetchFilteredAll: (options: {
    [key: string]: SelectButtonOption | SelectButtonOption[] | undefined;
  }) => void;
  fetchFilteredDates: (options: {
    [key: string]: [Date | undefined, Date | undefined] | undefined;
  }) => void;
}

const MoreFiltersForm = ({
  open,
  setOpen,
  filterDetails,
  fetchFilteredAll,
  fetchFilteredDates,
}: MoreFiltersFormProps) => {
  const queryParams = useQueryParams();
  const history = useHistory();
  const empty: {
    [key: string]: SelectMenuOption | SelectMenuOption[] | undefined;
  } = {};

  const emptyDates: {
    [key: string]: [Date | undefined, Date | undefined] | undefined;
  } = {};

  const createFilterState = () => {
    return filterDetails?.reduce((prev, cur) => {
      if (cur.filterType === 'MULTIPLE' || cur.filterType === 'TEXT') {
        prev[cur.id] = cur.selected;
      }
      return prev;
    }, empty);
  };

  const createDateFilterState = () => {
    return filterDetails?.reduce((prev, cur) => {
      if (cur.filterType === 'DATE_RANGE') {
        prev[cur.id] = cur.selected;
      }
      return prev;
    }, emptyDates);
  };

  const [filters, setFilters] = useState<{
    [key: string]: SelectMenuOption | SelectMenuOption[] | undefined;
  }>(createFilterState() ?? {});

  const [dateFilters, setDateFilters] = useState<{
    [key: string]: [Date | undefined, Date | undefined] | undefined;
  }>(createDateFilterState() ?? {});

  const handleConfirm = () => {
    Object.entries(filters).forEach(([filterId, filterValue]) => {
      if (!filterValue) {
        queryParams.delete(filterId);
        return;
      }
      if (Array.isArray(filterValue)) {
        if (filterValue.length > 0) {
          queryParams.set(
            filterId,
            filterValue
              .map((option) => {
                if ('id' in option) return option.id ?? '';
                return option.toString();
              })
              .join(',')
          );
        }
        return;
      }
      if (filterValue.id === 'any') {
        queryParams.delete(filterId);
        return;
      }
      queryParams.set(filterId, filterValue.id);
    });

    Object.entries(dateFilters).forEach(([filterId, newDates]) => {
      if (!newDates) {
        queryParams.delete(filterId);
        return;
      }
      queryParams.set(
        filterId,
        newDates.map((date) => getISODate(date)).join(',')
      );
    });

    history.push(`?${queryParams.toString()}`);
    fetchFilteredAll(filters);
    fetchFilteredDates(dateFilters);
    setOpen(false);
  };

  const clearAllFilters = () => {
    const emptyFilters = Object.entries(filters).reduce((prev, cur) => {
      prev[cur[0]] = Array.isArray(cur[1]) ? [] : { label: 'Any', id: 'any' };
      return prev;
    }, empty);
    setFilters(emptyFilters);
    const emptyDateFilters = Object.entries(dateFilters).reduce((prev, cur) => {
      prev[cur[0]] = undefined;
      return prev;
    }, emptyDates);
    setDateFilters(emptyDateFilters);
  };

  const handleFilterChange = (
    option: SelectMenuOption | SelectMenuOption[] | undefined,
    filterId: string
  ) => {
    setFilters((prev) => ({ ...prev, [filterId]: option }));
  };

  const handleDateFilterChange = (
    option: Date | [Date, Date] | null,
    filterId: string
  ) => {
    if (!Array.isArray(option)) {
      return;
    }
    setDateFilters((prev) => ({
      ...prev,
      [filterId]: option,
    }));
  };

  return (
    <Modal
      title="Filter Options"
      open={open}
      setOpen={setOpen}
      handleConfirm={handleConfirm}
      confirmButtonLabel="Apply"
      cancelLabel="Clear filters"
      cancelOnClick={clearAllFilters}
    >
      <>
        <div className={styles.filterWrapper}>
          {filterDetails.slice(0, 2).map((filter) => {
            if (
              filter.filterType === 'MULTIPLE' ||
              filter.filterType === 'TEXT'
            ) {
              return (
                <SelectMenu
                  key={filter.id}
                  options={filter.options}
                  selected={filters[filter.id]}
                  setSelected={(
                    option: SelectMenuOption | SelectMenuOption[] | undefined
                  ) => handleFilterChange(option, filter.id)}
                  placeholder={filter.placeholder ?? ''}
                  label={filter.label}
                  horizontal={true}
                  multiple={filter.filterType === 'MULTIPLE'}
                  className="w-[280px]"
                  color="gray"
                />
              );
            }
            return <></>;
          })}
        </div>
        <div className={styles.divider} />
        <div className={styles.filterWrapper}>
          {filterDetails.slice(2).map((filter) => {
            if (
              filter.filterType === 'MULTIPLE' ||
              filter.filterType === 'TEXT'
            ) {
              return (
                <SelectMenu
                  key={filter.id}
                  options={filter.options}
                  selected={filters[filter.id]}
                  setSelected={(
                    option: SelectMenuOption | SelectMenuOption[] | undefined
                  ) => handleFilterChange(option, filter.id)}
                  placeholder={filter.placeholder ?? ''}
                  label={filter.label}
                  horizontal={true}
                  multiple={filter.filterType === 'MULTIPLE'}
                  className="w-[280px]"
                  color="gray"
                />
              );
            }
            if (filter.filterType === 'DATE_RANGE') {
              return (
                <div className={styles.calendarPickerWrapper} key={filter.id}>
                  <span className={styles.calendarPickerLabel}>
                    {filter.label}
                  </span>
                  <CalendarDatePicker
                    className={styles.calendarPicker}
                    onChange={(date) => handleDateFilterChange(date, filter.id)}
                    range={true}
                    selectedDate={dateFilters[filter.id]?.[0]}
                    endDate={dateFilters[filter.id]?.[1]}
                  />
                </div>
              );
            }
            return <></>;
          })}
        </div>
      </>
    </Modal>
  );
};

export default MoreFiltersForm;
