import { gql } from '@apollo/client';

export const UPLOAD_GENERIC_POLICY_FILE = gql`
  mutation uploadGenericPolicyFile(
    $policyId: String!
    $type: String!
    $newFilename: String
    $file: Upload
  ) {
    uploadGenericPolicyFile(
      policyId: $policyId
      type: $type
      newFilename: $newFilename
      file: $file
    ) {
      id
      name
      resizedUrl
      type
      url
    }
  }
`;
