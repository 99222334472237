import { gql } from '@apollo/client';

export const GET_PUBLIC_POLICIES = gql`
  query GetPublicHealthPolicies(
    $offset: Float!
    $limit: Float!
    $searchString: String!
    $sortColumn: String!
    $sortOrder: String!
    $filterStatus: [String!]!
    $company: String!
  ) {
    hrPublicHealthPolicies(
      offset: $offset
      limit: $limit
      searchString: $searchString
      sortColumn: $sortColumn
      sortOrder: $sortOrder
      filterStatus: $filterStatus
      company: $company
    ) {
      id
      providerId
      createdAt
      user {
        firstName
        lastName
        email
        dateOfBirth
        svnr
      }
      publicStatus
      startDate
      confirmationOfCoverageUrl
      preliminaryConfirmationOfCoverage {
        url
      }
      confirmationOfCoverageFromProviderUrl
      mergedConfirmationOfCoverageUrl
    }
    hrTotalPublicHealthPolicies(
      searchString: $searchString
      sortColumn: $sortColumn
      sortOrder: $sortOrder
      filterStatus: $filterStatus
      company: $company
    )
  }
`;

export const GET_HR_PUBLIC_POLICY = gql`
  query HrPublicHealthPolicy($id: String!) {
    hrPublicHealthPolicy(id: $id) {
      confirmationOfCoverageUrl
      confirmationOfCoverageFromProviderUrl
      createdAt
      id
      mergedConfirmationOfCoverageUrl
      preliminaryConfirmationOfCoverage {
        url
      }
      providerId
      publicStatus
      startDate
      user {
        dateOfBirth
        email
        firstName
        svnr
        lastName
      }
    }
  }
`;
