import { useTranslation } from '@getpopsure/i18n-react';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { BadgeColor } from 'components/Badge/Badge';
import { Fragment } from 'react';

import OptionsList from './OptionsList';
import SelectedOption from './SelectedOption';
import * as styles from './styles';

export interface SelectButtonOption<T = void> {
  label: string;
  title?: string;
  id: T extends void ? string : T;
  color?: BadgeColor;
  disabled?: boolean;
}

interface SelectButtonProps<T = void> {
  options: SelectButtonOption<T>[];
  selected: SelectButtonOption<T> | SelectButtonOption<T>[] | undefined;
  multiple?: boolean;
  handleOnChange:
    | ((newOption: SelectButtonOption<T>) => void)
    | ((newOptions: SelectButtonOption<T>[]) => void);
  disabled: boolean;
  withDot: boolean;
  label: string;
  dataTestId?: string;
  showBorder?: boolean;
}

const SelectButton = <T extends string | void>({
  options,
  selected,
  handleOnChange,
  disabled,
  withDot,
  label,
  multiple = false,
  dataTestId,
  showBorder = false,
}: SelectButtonProps<T>) => {
  const { t } = useTranslation();
  const handleClearFilters = () => {
    handleOnChange([] as never);
  };

  const showLabel = (
    selectedOption: SelectButtonOption | SelectButtonOption[] | undefined
  ): boolean => {
    if (!selectedOption) return true;
    if (Array.isArray(selectedOption)) return selectedOption.length === 0;
    return selectedOption.id === 'any';
  };

  return (
    <Listbox
      value={multiple && !selected ? [] : selected}
      onChange={handleOnChange}
      disabled={disabled}
      multiple={multiple}
    >
      {({ open }: { open: boolean }) => (
        <div className={styles.singleSelect}>
          <div
            className={classNames(styles.singleSelectHeader, {
              [styles.border]: showBorder,
            })}
          >
            <Listbox.Button className={styles.button} data-testid={dataTestId}>
              <div className={styles.selectOption}>
                <p
                  className={classNames(styles.textStatus, {
                    [styles.hidden]: !showLabel(selected),
                  })}
                >
                  {label}
                </p>
                <SelectedOption withDot={withDot} selected={selected} />
              </div>
              <div className={styles.arrowDown}>
                <ChevronDownIcon className={styles.icon} aria-hidden="true" />
              </div>
            </Listbox.Button>
          </div>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className={classNames(styles.listOptions, {
                [styles.maxHeight]: options.length > 5 && !multiple,
                [styles.maxHeightBottomLabel]: options.length > 5 && multiple,
              })}
            >
              {options.map((option) => (
                <Listbox.Option
                  key={option.id}
                  className={({ active }: { active: boolean }) =>
                    classNames(styles.listOption, {
                      [styles.listOptionHover]: active,
                      [styles.listOptionDisabled]: Boolean(option.disabled),
                    })
                  }
                  value={option}
                  disabled={option.disabled}
                >
                  {({ active }: { active: boolean }) => {
                    const isSelectedArray = Array.isArray(selected);
                    return (
                      <OptionsList
                        withDot={withDot}
                        selected={
                          isSelectedArray
                            ? selected.some(({ id }) => id === option.id)
                            : option.id === selected?.id
                        }
                        active={active}
                        option={option}
                      />
                    );
                  }}
                </Listbox.Option>
              ))}
              {multiple && Array.isArray(selected) && (
                <div className={styles.clearFilterContainer}>
                  <button
                    type="button"
                    className={styles.clearFilterButton}
                    disabled={selected.length === 0}
                    onClick={handleClearFilters}
                  >
                    {t('admin.filters.clear.label', 'Clear filter')}
                  </button>
                </div>
              )}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};

export default SelectButton;
