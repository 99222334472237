import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import useQueryParams from 'hooks/useQueryParams';
import { useHistory } from 'react-router';

import { HeaderInformation, SortingKey } from '..';
import * as styles from './styles';

export interface SortableColumnHeaderProps {
  header: HeaderInformation;
  sortingKey: SortingKey;
  fetchSorted: (key: SortingKey) => void;
}

const SortableColumnHeader = ({
  header,
  sortingKey,
  fetchSorted,
}: SortableColumnHeaderProps) => {
  const queryParams = useQueryParams();
  const history = useHistory();

  const handleSortChange = (): SortingKey => {
    if (sortingKey.column !== header.id) {
      queryParams.set('sortColumn', header.id);
      queryParams.set('sortOrder', 'asc');
      return { column: header.id, order: 'asc' };
    }
    if (sortingKey.order === 'asc') {
      queryParams.set('sortColumn', header.id);
      queryParams.set('sortOrder', 'desc');
      return { ...sortingKey, order: 'desc' };
    }
    if (sortingKey.order === 'desc') {
      queryParams.delete('sortColumn');
      queryParams.delete('sortOrder');
      return { column: 'none', order: 'none' };
    }

    return { column: 'none', order: 'none' };
  };

  const handleSubmitSort = () => {
    const newSortKey = handleSortChange();
    fetchSorted(newSortKey);
    history.push(`?${queryParams.toString()}`);
  };

  const getIcon = () => {
    if (sortingKey.column === header.id) {
      if (sortingKey.order === 'asc') {
        return (
          <ArrowDownIcon
            className={classNames(styles.arrowIcon, styles.sortArrowIcon)}
          />
        );
      }
      if (sortingKey.order === 'desc') {
        return (
          <ArrowUpIcon
            className={classNames(styles.arrowIcon, styles.sortArrowIcon)}
          />
        );
      }
    } else {
      return (
        <ArrowDownIcon
          className={classNames(
            styles.arrowIcon,
            styles.hidden,
            styles.noSortArrowIcon,
            'icon-no-sorting'
          )}
        />
      );
    }
  };

  return (
    <th
      key={header.id}
      scope="col"
      tabIndex={0}
      onClick={handleSubmitSort}
      className={classNames(
        styles.columnHeader,
        header.minWidth,
        header.width,
        'sortable-column-header',
        {
          [`${header.threshold} ${styles.sticky}`]: header.sticky,
          [styles.borderRight]: header.border === 'right',
          [styles.borderLeft]: header.border === 'left',
        }
      )}
    >
      <div className={styles.contentWrapper}>
        <span>{header.label}</span>
        {getIcon()}
      </div>
    </th>
  );
};

export default SortableColumnHeader;
