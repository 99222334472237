import { PrivateHealthClaimStatus } from './models';

/**
 Below is a list of INTERNAL Private Health claims statuses
 See Notion documentation for mapping of all statuses: https://www.notion.so/featherinsurance/Claim-statuses-638be42fbe3144808e524da92c92747d
 *  */
export const statusNameMapper: Record<PrivateHealthClaimStatus, string> = {
  RECEIVED: 'Received',
  SUBMITTED_TO_PROVIDER: 'Sent',
  CLOSED: 'Closed',
  DUPLICATE: 'Duplicate',
  INCOMPLETE: 'Missing info',
  MISSING_INFO_RECEIVED: 'Info received',
  DENIED: 'Denied',
};
