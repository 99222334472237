import { OperationVariables } from '@apollo/client';
import { DocumentsSectionData } from 'components/DocumentsCard';
import DocumentsCardView from 'components/DocumentsCard/DocumentsCardBase';
import { SelectMenuOption } from 'components/SelectMenu';
import { DocumentNode } from 'graphql';
import { useState } from 'react';

interface SideSectionProps {
  id: string;
  documentsData?: {
    sections: DocumentsSectionData[];
    options: SelectMenuOption[];
    uploadMutation?: DocumentNode;
    deleteFileMutation?: DocumentNode;
    getPolicyQuery: DocumentNode;
    additionalQueries?: OperationVariables;
  };
}

const HRSideSection = ({ id, documentsData }: SideSectionProps) => {
  const [openModal, setOpenModal] = useState(false);
  return documentsData ? (
    <div className="w-full lg:ml-[20px] lg:w-auto">
      <DocumentsCardView
        sections={documentsData.sections}
        policyId={id}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </div>
  ) : (
    <></>
  );
};

export default HRSideSection;
