import { gql } from '@apollo/client';

export const UPLOAD_BIKE_DOCUMENT = gql`
  mutation UploadBikeDocument(
    $policyId: String!
    $type: String!
    $newFilename: String
    $file: Upload
  ) {
    uploadBikeDocument(
      id: $policyId
      type: $type
      newFilename: $newFilename
      file: $file
    ) {
      id
      url
      name
    }
  }
`;

export const DELETE_BIKE_DOCUMENT = gql`
  mutation DeleteBikeDocument(
    $id: String!
    $blobName: String!
    $documentType: String!
  ) {
    deleteBikePolicyFile(id: $id, blobName: $blobName, type: $documentType) {
      id
    }
  }
`;
