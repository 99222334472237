import { gql } from '@apollo/client';

export const CREATE_BIKE_CLAIM = gql`
  mutation CreateBikeClaim(
    $insuranceType: String!
    $userId: String!
    $policyId: String!
    $policyNumber: String
    $status: String!
    $claimType: [String!]!
    $eventDescription: String
    $iban: String!
    $amount: Float
    $uploadDocument: Upload
    $dateOfEvent: String!
  ) {
    createBikeClaim(
      insuranceType: $insuranceType
      userId: $userId
      policyId: $policyId
      policyNumber: $policyNumber
      status: $status
      claimType: $claimType
      eventDescription: $eventDescription
      iban: $iban
      amount: $amount
      uploadDocument: $uploadDocument
      dateOfEvent: $dateOfEvent
    ) {
      userPolicyId
      status
      user {
        id
        firstName
        lastName
      }
      claimType {
        id
        createdAt
        type
      }
      amount
      eventDescription
      iban
      dateOfEvent
    }
  }
`;
