import { DocumentsSectionData } from 'components/DocumentsCard';
import { SelectMenuOption } from 'components/SelectMenu';
import { retrieveFileFriendlyName } from 'shared/utils/retrieveFileFriendlyName';

import { DentalPolicyData } from '../models/DentalPolicy';

export const fileTypeOptions: SelectMenuOption<'POLICY_DOCUMENTS'>[] = [
  {
    id: 'POLICY_DOCUMENTS',
    label: 'Policy documents',
  },
];

// TODO: [KONG] Refactor logic
export const getDocumentSections = (
  data?: DentalPolicyData
): DocumentsSectionData<string>[] => {
  const policyDocuments: DocumentsSectionData<string> = {
    title: 'Policy documents',
    documents: [],
  };

  if (data?.dentalPolicy.confirmationOfCoverageDocuments) {
    const { url, name } = data.dentalPolicy.confirmationOfCoverageDocuments;
    const confirmationOfCoverage = {
      fileLink: url,
      fileName: name ? retrieveFileFriendlyName(name) : '',
    };

    policyDocuments.documents.push(confirmationOfCoverage);
  }

  data?.dentalPolicy.policyDocuments.forEach(
    ({ url, name, resizedUrl, blobName, id }) => {
      policyDocuments.documents.push({
        id,
        fileLink: url,
        fileName: name,
        resizedUrl,
        blobName,
        canDelete: true,
        documentType: 'POLICY_DOCUMENT',
      });
    }
  );

  data?.dentalPolicy.questionnaireDownloadDocuments.forEach(
    ({ url, blobName }) => {
      policyDocuments.documents.push({
        fileLink: url,
        fileName: retrieveFileFriendlyName(blobName),
        resizedUrl: url,
      });
    }
  );

  const scannedDocuments = {
    title: 'Scanned documents',
    documents:
      data?.dentalPolicy.scannedDocuments?.flatMap(
        ({ url, blobName, id, visibleInUserAccount }) =>
          visibleInUserAccount
            ? [
                {
                  id,
                  fileLink: url,
                  fileName: retrieveFileFriendlyName(blobName),
                  resizedUrl: url,
                  blobName,
                  canDelete: true,
                  documentType: 'SCANNED_DOCUMENT',
                },
              ]
            : []
      ) ?? [],
  };

  return [policyDocuments, scannedDocuments];
};
