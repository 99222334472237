import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import Badge from 'components/Badge';
import ComboBox from 'components/ComboBox';
import { ComboBoxOption } from 'components/ComboBox/ComboBox';
import FileErrorCard from 'components/FileErrorCard';
import Input from 'components/Input';
import Link from 'components/Link';
import Loader from 'components/Loader';
import Modal from 'components/Modal';
import SelectMenu, { SelectMenuOption } from 'components/SelectMenu';
import TextArea from 'components/TextArea';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'models/date';
import { InsurancePathName } from 'pages/claims/template/models';
import { retrieveClaimsDetailsPath } from 'pages/claims/template/utils/utils';
import { GET_EXPAT_POLICIES } from 'pages/policies/expatHealth/graphql/policies';
import { useEffect, useState } from 'react';
import { policyRouteMapper } from 'shared/insurances/policyRouteMapper';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { validateIBAN } from 'shared/validateIBAN';
import { v4 as uuidv4 } from 'uuid';

import { GET_QONTO_TRANSACTIONS } from '../../graphql/transactions';
import { UPDATE_CLAIM_PAYMENT_TRANSACTION } from '../../graphql/updateTransaction';
import { useClaimAmountsValidation } from '../../hooks/useClaimAmountsValidation';
import { ClaimPaymentTransaction } from '../../models/ClaimPaymentTransaction';
import { TransactionInsuranceType } from '../../models/TransactionInsuranceType';
import { getInsuranceTypePathName } from '../../utils/getInsuranceTypePathName';
import { getPoliciesData } from '../../utils/getPoliciesData';
import { getPoliciesQuery } from '../../utils/getPoliciesQuery';

interface PaymentSummaryModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  transaction?: ClaimPaymentTransaction;
  insuranceType: TransactionInsuranceType;
}

const statusOptions: SelectMenuOption[] = [
  {
    id: 'PENDING',
    label: 'Pending',
    color: 'red',
  },
  {
    id: 'READY',
    label: 'Ready',
    color: 'blue',
  },
  {
    id: 'EXPORTED',
    label: 'Exported',
    color: 'blue',
  },
  {
    id: 'SKIPPED',
    label: 'Skipped',
    color: 'gray',
  },
  {
    id: 'IGNORED',
    label: 'Ignored',
    color: 'yellow',
  },
  {
    id: 'PAID',
    label: 'Paid',
    color: 'green',
  },
];

const PaymentSummaryModal = ({
  open,
  setOpen,
  transaction,
  insuranceType,
}: PaymentSummaryModalProps) => {
  if (!transaction && open) {
    throw new Error('Transaction is not defined');
  }

  const claimInsuranceType: InsurancePathName | undefined =
    transaction?.insuranceType
      ? getInsuranceTypePathName(transaction.insuranceType)
      : undefined;

  const alertBannersState = useReactiveVar(alertBanners);

  const [updateTransaction, { loading }] = useMutation(
    UPDATE_CLAIM_PAYMENT_TRANSACTION,
    {
      refetchQueries: [GET_QONTO_TRANSACTIONS],
      onCompleted: () => {
        setOpen(false);

        const newAlertBanner: AlertBannerState = {
          id: uuidv4(),
          type: 'SUCCESS',
          message: 'You have successfully saved the changes.',
        };

        setNewAlertBanner({ state: alertBannersState, newAlertBanner });
      },
      onError: () => {
        setOpen(false);
        const newAlertBanner: AlertBannerState = {
          id: uuidv4(),
          type: 'WARNING',
          message: 'Something went wrong. Please try again.',
        };

        setNewAlertBanner({ state: alertBannersState, newAlertBanner });
      },
    }
  );

  const { validateClaimAmounts, description } =
    useClaimAmountsValidation(insuranceType);

  const [selectedPolicy, setSelectedPolicy] = useState<
    | {
        id: string;
        policyNumber?: string;
        user?: { firstName?: string; lastName?: string };
      }
    | undefined
  >(transaction?.userPolicy);
  const [selectedStatus, setSelectedStatus] = useState(
    statusOptions.find((option) => option.id === transaction?.status)
  );
  const [note, setNote] = useState(transaction?.note ?? '');
  const [selectedClaim, setSelectedClaim] = useState<
    | {
        id: string;
        claimNumber: string;
        refundAmount?: number;
        iban?: string;
      }
    | undefined
  >(transaction?.userClaim);
  const [iban, setIban] = useState(transaction?.userClaim?.iban);

  const [policiesQuery, setPoliciesQuery] = useState(
    selectedPolicy?.policyNumber ?? ''
  );
  const [ibanError, setIbanError] = useState(!validateIBAN(iban));
  const [amountError, setAmountError] = useState(
    !validateClaimAmounts(transaction?.userClaim, transaction)
  );
  const [policyNumberError, setPolicyNumberError] = useState(
    !transaction?.userPolicy
  );
  const [claimNumberError, setClaimNumberError] = useState(
    transaction?.userClaim ? !transaction.userClaim.claimNumber : false
  );

  const { data: policiesData, loading: policiesLoading } = useQuery(
    transaction ? getPoliciesQuery(insuranceType) : GET_EXPAT_POLICIES,
    {
      variables: {
        limit: 20,
        offset: 0,
        searchString: policiesQuery,
        sortColumn: 'none',
        sortOrder: 'none',
        filterStatus: [],
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const { policies } = transaction
    ? getPoliciesData(insuranceType, policiesData)
    : { policies: [] };

  useEffect(() => {
    setSelectedPolicy(transaction?.userPolicy);
    setPoliciesQuery(transaction?.userPolicy?.policyNumber ?? '');
    setSelectedStatus(
      statusOptions.find((option) => option.id === transaction?.status)
    );
    setNote(transaction?.note ?? '');
    setSelectedClaim(transaction?.userClaim);
    setIban(transaction?.userClaim?.iban);
    setIbanError(!validateIBAN(transaction?.userClaim?.iban));
    setAmountError(!validateClaimAmounts(transaction?.userClaim, transaction));
    setPolicyNumberError(!transaction?.userPolicy);
    setClaimNumberError(
      transaction?.userClaim ? !transaction.userClaim.claimNumber : false
    );
  }, [transaction]);

  const handleSave = () => {
    updateTransaction({
      variables: {
        transactionId: transaction?.id,
        userPolicyId: selectedPolicy?.id,
        transactionStatus: selectedStatus?.id,
        note,
        userClaimId: selectedClaim?.id,
        iban,
      },
    });
  };

  const policyOptions =
    policies?.map(({ id, policyNumber }) => ({
      id,
      label: policyNumber ?? 'No policy number',
    })) ?? [];
  const selectedPolicyOption = policyOptions.find(
    ({ id }) => id === selectedPolicy?.id
  );

  const claimOptions = [
    ...(transaction?.claimSuggestions.map(({ id, claimNumber, amount }) => ({
      id,
      label: `${claimNumber ?? 'No claim number'} - ${
        amount ? englishFormattedEuroCurrency(amount) : ''
      }`,
    })) ?? []),
    ...(transaction?.otherClaimSuggestions
      .slice(0, 1)
      .map(({ id, claimNumber, amount }) => ({
        id,
        label: `${claimNumber ?? 'No claim number'} - ${
          amount ? englishFormattedEuroCurrency(amount) : ''
        }`,
        sectionName: 'Other claims',
      })) ?? []),
    ...(transaction?.otherClaimSuggestions
      .slice(1)
      .map(({ id, claimNumber, amount }) => ({
        id,
        label: `${claimNumber ?? 'No claim number'} - ${
          amount ? englishFormattedEuroCurrency(amount) : ''
        }`,
      })) ?? []),
  ];

  const claimSuggestions = [
    ...(transaction?.claimSuggestions ?? []),
    ...(transaction?.otherClaimSuggestions ?? []),
  ];

  const statusNotPaidOrIgnored =
    transaction?.status !== 'PAID' && transaction?.status !== 'IGNORED';

  return (
    <Modal
      title="Payment summary"
      open={open}
      setOpen={setOpen}
      confirmButtonLabel="Save"
      textLoading="Saving..."
      loading={loading}
      handleConfirm={handleSave}
      scrollable={true}
    >
      <div className="mt-2 flex-col space-y-4 text-sm text-gray-900">
        {ibanError && statusNotPaidOrIgnored && (
          <FileErrorCard
            open={true}
            title="Customer's IBAN is invalid"
            handleClose={() => {}}
            errorType="ERROR"
            hideCloseButton={true}
          />
        )}
        {claimNumberError && statusNotPaidOrIgnored && (
          <FileErrorCard
            open={true}
            title="Claim doesn't have a claim number"
            handleClose={() => {}}
            errorType="ERROR"
            hideCloseButton={true}
          />
        )}
        {policyNumberError && statusNotPaidOrIgnored && (
          <FileErrorCard
            open={true}
            title="Policy number not found"
            handleClose={() => {}}
            errorType="ERROR"
            hideCloseButton={true}
          />
        )}
        {amountError && statusNotPaidOrIgnored && (
          <FileErrorCard
            open={true}
            title={description}
            handleClose={() => {
              setAmountError(false);
            }}
            errorType="WARNING"
            hideCloseButton={false}
          />
        )}
        <div className="flex items-center justify-between">
          <span className="font-bold">Reference</span>
          <span className="w-[244px]">
            {transaction?.qontoTransaction?.reference}
          </span>
        </div>
        <div className="flex items-center justify-between">
          {transaction?.status !== 'PAID' ? (
            <>
              <div className="flex items-center space-x-2">
                <label className="font-bold" htmlFor="policy">
                  Policy number
                </label>
                {policiesLoading && (
                  <Loader className="ml-[4px] h-[16px] w-[16px] animate-spin" />
                )}
              </div>
              <ComboBox
                options={policyOptions}
                selectedOption={selectedPolicyOption}
                setSelectedOption={(newOption: ComboBoxOption) => {
                  const policy = policies?.find(
                    (policyOption: any) => policyOption.id === newOption.id
                  );
                  setSelectedPolicy(policy);
                  setPolicyNumberError(false);
                }}
                placeholder="Start typing number..."
                color="gray"
                className="w-[244px]"
                setExternalQuery={setPoliciesQuery}
                useExternalQuery
                useUnfilteredOptions
                id="policy"
              />
            </>
          ) : (
            <>
              <span className="font-bold">Policy number</span>
              <span className="w-[244px]">{selectedPolicy?.policyNumber}</span>
            </>
          )}
        </div>
        {transaction?.status !== 'PAID' ? (
          <SelectMenu
            options={statusOptions}
            disabled={false}
            selected={selectedStatus}
            setSelected={setSelectedStatus}
            label="Status"
            color="gray"
            horizontal={true}
            placeholder="Select status"
            className="w-[244px]"
            withDot={true}
          />
        ) : (
          <div className="flex items-center justify-between">
            <span className="font-bold">Status</span>
            <span className="w-[244px]">
              <Badge color={selectedStatus?.color ?? 'gray'} badgeType="full">
                {selectedStatus?.label}
              </Badge>
            </span>
          </div>
        )}
        <div className="flex items-center justify-between">
          <span className="font-bold">Customer name</span>
          <span className="w-[244px]">
            {selectedPolicy &&
            selectedPolicy.user &&
            selectedPolicy.user.firstName &&
            selectedPolicy.user.lastName
              ? `${selectedPolicy?.user?.firstName} ${selectedPolicy?.user?.lastName}`
              : ''}
          </span>
        </div>
        <div className="flex items-center justify-between">
          <span className="font-bold">Payment date</span>
          <span className="w-[244px]">
            {dayjs(transaction?.emittedAt).format(DATE_FORMAT)}
          </span>
        </div>
        <div className="flex items-center justify-between">
          <span className="font-bold">Received amount</span>
          <span className="w-[244px]">
            {englishFormattedEuroCurrency(
              transaction?.qontoTransaction?.amount ?? 0
            )}
          </span>
        </div>
        <div className="my-[24px] w-full border-t border-gray-300" />
        {transaction?.status !== 'PAID' ? (
          <SelectMenu
            options={claimOptions}
            selected={claimOptions.find(
              (claim) => selectedClaim?.id === claim.id
            )}
            setSelected={(newOption: SelectMenuOption) => {
              const claim = claimSuggestions.find(
                (suggestion) => suggestion.id === newOption?.id
              );
              setSelectedClaim(claim);
              setIban(claim?.iban);
              setIbanError(!validateIBAN(claim?.iban));
              setAmountError(!validateClaimAmounts(claim, transaction));
            }}
            label="Claim"
            color="gray"
            horizontal={true}
            placeholder="Select a claim"
            className="w-[244px]"
            disabled={!transaction?.userPolicy}
            clearButton={true}
          />
        ) : (
          <div className="flex items-center justify-between">
            <span className="font-bold">Claim</span>
            <span className="w-[244px]">
              {transaction.userClaim
                ? `${
                    transaction?.userClaim?.claimNumber
                  } - ${englishFormattedEuroCurrency(
                    transaction?.userClaim?.amount ?? 0
                  )}`
                : ''}
            </span>
          </div>
        )}
        <div className="flex items-center justify-between">
          <label htmlFor="iban" className="font-bold">
            Customer IBAN
          </label>
          {transaction?.status !== 'PAID' ? (
            <Input
              id="iban"
              color="gray"
              className="w-[244px]"
              placeholder="Start typing IBAN..."
              value={iban}
              onChange={(e) => {
                setIban(e.target.value);
                setIbanError(!validateIBAN(e.target.value));
              }}
              disabled={!transaction?.userPolicy}
              error={ibanError}
              errorMessage="Customer's IBAN is invalid"
            />
          ) : (
            <span className="w-[244px]">{transaction?.userClaim?.iban}</span>
          )}
        </div>
        <div className="flex items-center justify-between">
          <span className="font-bold">Refunded amount</span>
          <span className="w-[244px]">
            {selectedClaim?.refundAmount
              ? englishFormattedEuroCurrency(selectedClaim?.refundAmount)
              : ''}
          </span>
        </div>
        <div className="flex items-center justify-between">
          <span className="font-bold">Customer paid out on</span>
          <span className="w-[244px]">
            {transaction?.userClaim?.customerPaidOn
              ? dayjs(transaction?.userClaim?.customerPaidOn ?? '').format(
                  DATE_FORMAT
                )
              : ''}
          </span>
        </div>
        <div className="my-[24px] w-full border-t border-gray-300" />
        <div className="flex items-start justify-between">
          <span className="font-bold">Links</span>
          <div className="flex w-[244px] flex-col space-y-2">
            {transaction?.userPolicy && (
              <Link
                color="primary"
                href={policyRouteMapper[transaction.insuranceType](
                  transaction.userPolicy.id
                )}
                text="Policy details"
                target="_blank"
              />
            )}
            {transaction?.userClaim && (
              <Link
                color="primary"
                href={
                  claimInsuranceType
                    ? retrieveClaimsDetailsPath(
                        claimInsuranceType,
                        transaction.userClaim.id
                      )
                    : ''
                }
                text="Claim details"
                target="_blank"
              />
            )}
          </div>
        </div>
        <div className="flex items-center justify-between">
          <span className="font-bold">Notes</span>
          <TextArea
            color="gray"
            className="w-[244px]"
            placeholder="Start typing..."
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default PaymentSummaryModal;
