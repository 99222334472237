import classNames from 'classnames';

export const tableRow = classNames('bg-white');

export const clickable = classNames(
  'cursor-pointer',
  'hover:bg-gray-50',
  'focus:bg-gray-200',
  'clickable-row'
);

export const checked = classNames('bg-indigo-100');

export const checkboxWrapper = classNames(
  'border-b border-t border-b-gray-200 border-t-gray-200 p-3'
);
