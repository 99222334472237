import { GET_GENERIC_POLICIES } from 'pages/policies/generic/graphql/policies';
import { TemplateInsuranceType } from 'pages/policies/template/models/InsuranceTypes';
import {
  GenericClaimStatus,
  GenericClaimType,
} from 'shared/claims/generic/models';
import { Country } from 'shared/countries/models';

import { ClaimsTemplateData } from '../template/models';
import { retrieveClaimInfoRows } from './modules/claimsDetails/claimInfo/data';
import { UPDATE_GENERIC_CLAIM } from './modules/claimsDetails/graphql/mutations';
import { GET_GENERIC_CLAIM } from './modules/claimsDetails/graphql/queries';
import { retrievePolicyInfoRows } from './modules/claimsDetails/policyInfo/data';
import { retrieveCreateClaimFormData } from './modules/createClaim/data';
import { CREATE_GENERIC_CLAIM } from './modules/createClaim/graphql/mutations';
import { zodSubmittableClaimSchema } from './modules/createClaim/models';
import { getFilterConfigByRegion } from './modules/overview/data/filters';
import { rowConfig } from './modules/overview/data/tableData';
import { getGenericClaimsTableHeaders } from './modules/overview/data/tableHeaders';
import { GET_GENERIC_CLAIMS } from './modules/overview/graphql/queries';
import {
  genericClaimStatusOptions,
  retrieveFormAndFooterTextByStatus,
} from './modules/statusUpdate/data';
import { UPDATE_GENERIC_CLAIM_STATUS } from './modules/statusUpdate/graphql/mutations';
import { claimSubmissionMutationByInsuranceType } from './modules/submitClaim/data';
import { getGenericClaimTypes } from './utils';

export const genericClaimsData = (
  insuranceType: TemplateInsuranceType,
  region?: Country
): ClaimsTemplateData<GenericClaimStatus, GenericClaimType> => {
  const genericClaimTypes = getGenericClaimTypes(insuranceType);

  return {
    insuranceType,
    region,
    // GraphQL -----------------------------------------
    queries: {
      fetchAllClaims: GET_GENERIC_CLAIMS,
      fetchSingleClaim: GET_GENERIC_CLAIM,
      fetchAllPolicies: GET_GENERIC_POLICIES,
    },

    mutations: {
      createClaim: CREATE_GENERIC_CLAIM,
      updateClaim: UPDATE_GENERIC_CLAIM,
      updateClaimStatus: UPDATE_GENERIC_CLAIM_STATUS,
      submitClaim: claimSubmissionMutationByInsuranceType[insuranceType],
    },

    // Features ----------------------------------------
    overview: {
      headers: getGenericClaimsTableHeaders(region),
      filterConfig: getFilterConfigByRegion({
        claimTypes: genericClaimTypes,
        insuranceType,
        currentRegion: region,
      }),
      rowConfig: rowConfig(insuranceType, region),
    },
    claimCreation: {
      createClaimSubmissionSchema: zodSubmittableClaimSchema,
      retrieveFormData: retrieveCreateClaimFormData(genericClaimTypes),
    },
    policyInfo: { retrievePolicyInfoRows },
    claimInfo: {
      retrieveClaimInfoRows: retrieveClaimInfoRows(genericClaimTypes),
    },
    statusUpdate: {
      dataByStatus: retrieveFormAndFooterTextByStatus,
      statusOptions: genericClaimStatusOptions,
    },
  };
};
