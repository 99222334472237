import { gql } from '@apollo/client';

import { EXPAT_SPAIN_POLICY_ACTIVITY } from './activity';

export const GET_EXPAT_SPAIN_POLICY = gql`
  ${EXPAT_SPAIN_POLICY_ACTIVITY}
  query GetExpatSpainPolicy($id: String!) {
    expatSpainPolicy(id: $id) {
      id
      regionOfPurchase
      policyNumber
      providerPolicyNumber
      createdAt
      startDate
      arrivalDate
      archivedAt
      price
      paymentReceivedAt
      source
      campaign
      medium
      content
      term
      numberOfClaims
      publicStatus
      activeUntil

      # Quote info
      quote {
        plan
      }

      # insured person
      insuredPerson {
        firstName
        lastName
        dateOfBirth
        email
        gender
      }

      # user
      user {
        id
        firstName
        lastName
        stripeToken
        intercomEUId
        email
        isDelinquent
        isFraudulent
        fraudulentAdditionalInfo
      }

      # documents
      policyDocuments {
        url
        id
        name
        blobName
        isRegeneratable
        resizedUrl
      }
      questionnaireDownloadDocuments {
        id
        url
        blobName
      }

      # policy cancelation request
      policyCancelationRequest {
        id
        cancelationConfirmedByProviderAt
        createdAt
        canceledOnStripeAt
        requestSentToProviderAt
        reason
      }

      # questionnaire
      questionnaire {
        id
        answers
      }

      pinnedNotes {
        id
        body
        createdAt
        adminUser {
          firstName
          lastName
        }
        updatedAt
      }

      # policy activity
      ...ExpatSpainPolicyActivity

      referral {
        referralCode
        status
      }
    }
  }
`;
