import useQueryParams from 'hooks/useQueryParams';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export const useOffset = (): {
  offset: number;
  setOffset: Dispatch<SetStateAction<number>>;
} => {
  const queryParams = useQueryParams();

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const offsetParam = queryParams.get('offset');

    // If there is no offset param, set it to default 0
    if (!offsetParam) {
      setOffset(0);
    }

    // If offset exists, set it on mount
    if (Number(offsetParam) > 0) {
      setOffset(Number(offsetParam));
    }
  }, []);

  useEffect(() => {
    const offsetParam = queryParams.get('offset');
    if (!offsetParam) {
      setOffset(0);
    }

    if (Number(offsetParam) >= 0) {
      setOffset(Number(offsetParam));
    }
  }, [queryParams]);

  return { offset, setOffset };
};
