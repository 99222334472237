import { useTranslation } from '@getpopsure/i18n-react';
import { z } from 'zod';

import type { DataPreviewProps } from '../TaskEngine.types';
import * as styles from './TaskPreviewModal.styles';

export const TextPreview = ({
  data,
  actionId,
  label,
}: DataPreviewProps & { label: string }) => {
  const { t } = useTranslation();
  const TextDataObjectSchema = z
    .object({ [actionId]: z.string() })
    .transform((v) => v[actionId]);

  const text = TextDataObjectSchema.safeParse(data);

  if (!text.success) {
    return (
      <div className={styles.row}>
        <p className={styles.rowText}>{label}</p>
        <p className={styles.invalidAnswer}>
          {t(
            'taskEngine.taskPreviewModal.textPreview.error',
            'The customer provided an invalid answer'
          )}
        </p>
      </div>
    );
  }

  return (
    <div className={styles.row}>
      <p className={styles.rowText}>{label}</p>
      <p className={styles.rowText}>{text.data}</p>
    </div>
  );
};
