import classNames from 'classnames';
import { TextColor } from 'components/Table';

import { HeaderConfig } from '../types';
import * as styles from './styles';

export interface TableCellProps {
  children?: JSX.Element;
  header: HeaderConfig;
  textColor: TextColor;
  className?: string;
  resourceOnClick?: () => void;
  getResourceLink?: () => string;
}

export const TableCell = ({
  children,
  header,
  textColor,
  getResourceLink,
  resourceOnClick,
  className,
}: TableCellProps) => {
  const isButton = Boolean(resourceOnClick);
  const isLink = Boolean(getResourceLink);
  const isClickable = isButton || isLink;
  const isLgScreen = window.innerWidth > 1024;

  const clickableCx = (extra = '') =>
    classNames(
      styles.nestedAnchor,
      className,
      header.minWidth,
      header.width,
      extra,
      {
        [styles.lightTextColor]: textColor === 'light',
        [styles.darkTextColor]: textColor === 'dark',
      }
    );

  return (
    <td
      className={classNames(className, styles.divider, {
        'row-cell': isClickable,
        [styles.lightTextColor]: !isClickable && textColor === 'light',
        [styles.darkTextColor]: !isClickable && textColor === 'dark',
        [`${header.threshold} ${styles.sticky}`]: header.sticky && isLgScreen,
        [styles.borderRight]: header.border === 'right' && isLgScreen,
        [styles.borderLeft]: header.border === 'left' && isLgScreen,
      })}
    >
      {!isClickable && <div className={clickableCx()}>{children}</div>}

      {isButton && (
        <button
          type="button"
          className={clickableCx('flex')}
          onClick={resourceOnClick}
        >
          {children}
        </button>
      )}

      {isLink && getResourceLink && (
        <a className={clickableCx()} href={getResourceLink()}>
          {children}
        </a>
      )}
    </td>
  );
};
