import classNames from 'classnames';

export const updateNoteTextArea = classNames(
  'mt-[8px]',
  'max-h-[170px]',
  'min-h-[33px]'
);
export const updateNoteCtaWrapper = classNames(
  'flex',
  'justify-end',
  'gap-[8px]',
  'mt-[8px]'
);
export const updateNoteCtaButton = classNames('text-xs');
