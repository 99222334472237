import { gql } from '@apollo/client';

export const ARCHIVE_POLICY = gql`
  mutation ArchivePolicy($insuranceType: String!, $id: String!) {
    archivePolicy(insuranceType: $insuranceType, id: $id) {
      insuranceType
      id
    }
  }
`;

export const UNARCHIVE_POLICY = gql`
  mutation ArchivePolicy($insuranceType: String!, $id: String!) {
    unarchivePolicy(insuranceType: $insuranceType, id: $id) {
      id
    }
  }
`;

export const UPDATE_POLICY_CANCELATION_REQUEST = gql`
  mutation UpdatePolicyCancelationRequest(
    $insuranceType: String!
    $policyId: String!
    $id: String
    $activeUntil: String
    $createdAt: String
    $canceledOnStripeAt: String
    $requestSentToProviderAt: String
    $cancelationConfirmedByProviderAt: String
    $reason: String
  ) {
    updatePolicyCancelationRequest(
      insuranceType: $insuranceType
      policyId: $policyId
      id: $id
      activeUntil: $activeUntil
      createdAt: $createdAt
      canceledOnStripeAt: $canceledOnStripeAt
      requestSentToProviderAt: $requestSentToProviderAt
      cancelationConfirmedByProviderAt: $cancelationConfirmedByProviderAt
      reason: $reason
    ) {
      id
      activeUntil
      policyCancelationRequest {
        id
        createdAt
        canceledOnStripeAt
        requestSentToProviderAt
        cancelationConfirmedByProviderAt
        reason
      }
    }
  }
`;

export const UPDATE_USER_IN_POLICY = gql`
  mutation UpdateUserInPolicy($userId: String!, $policyId: String!) {
    updateUserInPolicy(userId: $userId, policyId: $policyId) {
      id
      user {
        id
        email
        dateOfBirth
        lastName
        firstName
      }
    }
  }
`;
