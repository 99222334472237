import { ApolloError, OperationVariables, useQuery } from '@apollo/client';
import { CheckBox } from 'components/CheckBox';
import Dropdown, { DropdownOption } from 'components/Dropdown/Dropdown';
import SearchBar from 'components/SearchBar';
import { SelectButtonOption } from 'components/SelectButton';
import TableEmptyState, {
  EmptyStateMessage,
} from 'components/Table/TableEmptyState';
import TableLoadingState from 'components/Table/TableLoadingState';
import Pagination from 'components/TableRefactor/Pagination';
import { DocumentNode } from 'graphql';
import { useOffset } from 'hooks/useOffset';
import useTableQueryParams from 'hooks/useTableQueryParams';
import { useEffect, useState } from 'react';

import Filters from './Filters';
import { FilterConfig } from './Filters/types';
import FilterViewDropdown from './FilterViewDropdown';
import { useBulkSelect } from './hooks/useBulkSelect/useBulkSelect';
import * as styles from './styles';
import { TableRow } from './TableRow/TableRow';
import {
  HeaderConfig,
  NormalizedData,
  ObjectWithNullishId,
  RowConfig,
  ViewConfig,
} from './types';

const DEFAULT_NUMBER_OF_ROWS_PER_PAGE = 12;
const CUSTOM_ROWS_PER_PAGE_OPTIONS = ['12', '25', '100', '250', '500'] as const;

const rowsPerPageOptions = CUSTOM_ROWS_PER_PAGE_OPTIONS.map((n) => ({
  id: String(n),
  label: String(n),
  show: true,
}));

const getNormalizedData = <T extends ObjectWithNullishId>(
  data?: T,
  countPath?: [string, string]
): NormalizedData<T> => {
  const values = Object.values(data ?? {});
  const items = values.find((v) => Array.isArray(v)) ?? [];
  let total = values.find((v) => typeof v === 'number') ?? 0;

  if (total === 0 && items.length > 0 && countPath) {
    total = data?.[countPath[0]][countPath[1]] ?? 0;
  }

  return { items, total };
};

const Table = ({
  title,
  subtitle,
  query,
  itemsPerPage,
  searchPlaceholder = 'Search by name or email',
  headers,
  rowConfig,
  emptyStateLabel,
  filterConfig = [],
  onError,
  viewConfig,
  actionButton,
  queryVariables,
  countPath,
  nVisibleFilters,
  onCompleted,
  onBulkSelection,
  enableItemsPerPageDropdown = false,
  skipQuery,
}: {
  title: string;
  subtitle?: string;
  query: DocumentNode;
  itemsPerPage?: number;
  searchPlaceholder: string;
  headers: HeaderConfig[];
  rowConfig: RowConfig;
  emptyStateLabel: EmptyStateMessage;
  filterConfig?: FilterConfig[];
  viewConfig?: ViewConfig;
  onError: (error: ApolloError | undefined) => void;
  actionButton?: React.ReactNode;
  queryVariables?: OperationVariables;
  countPath?: [string, string];
  nVisibleFilters?: number;
  onCompleted?: (data?: any) => void;

  // If defined, will add checkboxes to the table for (bulk) selecting items
  onBulkSelection?: (items: any[]) => void;
  enableItemsPerPageDropdown?: boolean;
  skipQuery?: boolean;
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(
    itemsPerPage || DEFAULT_NUMBER_OF_ROWS_PER_PAGE
  );

  const { offset, setOffset } = useOffset();
  const {
    search: searchString,
    sort,
    filter,
    setFilter,
    dateFilter,
    setDateFilter,
  } = useTableQueryParams([
    ...filterConfig.map((config) => ({
      ...config,
      multiple: config.filterType === 'MULTIPLE',
      dateRange: config.filterType === 'DATE_RANGE',
    })),
    ...(viewConfig
      ? [
          {
            id: viewConfig.id,
            options: viewConfig.options,
            multiple: false,
          },
        ]
      : []),
  ]);
  const { data, error, loading, fetchMore, networkStatus } = useQuery(query, {
    skip: skipQuery,
    variables: {
      offset,
      limit: rowsPerPage,
      sortColumn: sort.column,
      sortOrder: sort.order,
      searchString,
      ...Object.entries(filter ?? {}).reduce(
        (all, [k, v]) => ({
          ...all,
          [k]: Array.isArray(v) ? v.map(({ id }) => id) : v?.id,
        }),
        {}
      ),
      ...Object.entries(dateFilter ?? {}).reduce(
        (all, [k, v]) => ({
          ...all,
          [`${k}RangeStart`]: v?.[0],
          [`${k}RangeEnd`]: v?.[1],
          [k]: v,
        }),
        {}
      ),
      ...queryVariables,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (_data: any) => onCompleted?.(_data),
  });

  useEffect(() => {
    onError(error);
  }, [error, onError]);

  const normalizedData = getNormalizedData(data, countPath);

  const fetchFiltered = (
    newFilterOption: SelectButtonOption | SelectButtonOption[] | undefined,
    filterId: string
  ) => {
    setFilter?.({ ...filter, [filterId]: newFilterOption });
    setOffset(0);
    fetchMore({
      variables: {
        offset: 0,
        [filterId]: Array.isArray(newFilterOption)
          ? newFilterOption.map((option) => option.id)
          : newFilterOption?.id,
      },
    }).catch(() => {});
  };

  const fetchFilteredAll = (options: {
    [key: string]: SelectButtonOption | SelectButtonOption[] | undefined;
  }) => {
    setFilter?.(options);
    setOffset(0);
  };

  const fetchFilteredDates = (options: {
    [key: string]: [Date | undefined, Date | undefined] | undefined;
  }) => {
    setDateFilter?.(options);
    setOffset(0);
  };

  const getSelectedFilterViewOption = (): DropdownOption => {
    if (!viewConfig) return { id: '', label: '', show: false };

    if (!filter[viewConfig.id] || Array.isArray(filter[viewConfig.id])) {
      return { ...viewConfig.options[0], show: true };
    }
    return { id: '', label: '', show: true, ...filter[viewConfig.id] };
  };

  const hasSelectedFilter = (): boolean => {
    return !!filterConfig?.find((config) => {
      if (config.filterType === 'MULTIPLE' || config.filterType === 'TEXT') {
        const selected = filter[config.id];
        return Array.isArray(selected)
          ? selected?.length !== 0
          : selected?.id !== 'any';
      }

      const selected = dateFilter[config.id];
      return Array.isArray(selected)
        ? selected.filter((x) => x).length !== 0
        : false;
    });
  };

  const {
    allRowsSelected,
    anyRowsSelected,
    onCheckBoxClick,
    onShiftCheckBoxClick,
    onToggleSelectAll,
    selectedRows,
  } = useBulkSelect({
    normalizedData,
    onBulkSelection,
    networkStatus,
  });

  return (
    <div className={styles.tableWrapper}>
      <div className="mx-[40px] flex items-baseline space-x-2">
        <h1 className={styles.title}>{title}</h1>
        {subtitle && <h3 className={styles.subtitle}>{subtitle}</h3>}
      </div>
      <div className={styles.actionsBar}>
        <div className={styles.searchBar}>
          <SearchBar
            searchString={searchString}
            loading={loading}
            placeholder={searchPlaceholder}
            includePlaceholderDropdown
          />
          {filter && (
            <Filters
              fetchFiltered={fetchFiltered}
              fetchFilteredAll={fetchFilteredAll}
              loading={loading}
              filterConfig={filterConfig ?? []}
              filters={filter}
              dateFilters={dateFilter}
              fetchFilteredDates={fetchFilteredDates}
              viewConfigId={viewConfig?.id}
              nVisibleFilters={nVisibleFilters}
            />
          )}
        </div>
        <div className="flex space-x-2 self-end">
          {viewConfig && (
            <FilterViewDropdown
              options={viewConfig.options.map(({ displayDot, ...options }) => ({
                ...options,
                dot: displayDot?.(data) ?? false,
                show: true,
              }))}
              selected={{ ...getSelectedFilterViewOption() }}
              generalActivityDot={viewConfig.getGeneralActivityDot?.(data)}
            />
          )}
          {actionButton && !loading ? actionButton : <></>}
        </div>
      </div>
      <div className={styles.tableContent}>
        <table className={styles.table}>
          <thead>
            <tr className={styles.tableHeader}>
              {onBulkSelection && (
                <td className="p-3">
                  <CheckBox
                    checked={allRowsSelected}
                    setChecked={onToggleSelectAll}
                    muted={!anyRowsSelected}
                    dataTestId="TABLE_ROW_SELECT_ALL"
                  />
                </td>
              )}
              {headers.map((header) => (
                <header.component
                  header={header}
                  key={header.id}
                  sortingKey={sort}
                />
              ))}
            </tr>
          </thead>
          {!loading && (
            <tbody>
              {normalizedData.items.map((item, rowIndex) => (
                <TableRow
                  key={String(`TR-${rowIndex}`)}
                  rowIndex={rowIndex}
                  rowConfig={rowConfig}
                  data={item}
                  headers={headers}
                  checkboxProps={
                    onBulkSelection
                      ? {
                          onCheckBoxClick: onCheckBoxClick(rowIndex),
                          onShiftCheckBoxClick: onShiftCheckBoxClick(rowIndex),
                          checked: selectedRows.includes(rowIndex),
                          muted: !anyRowsSelected,
                        }
                      : undefined
                  }
                />
              ))}
            </tbody>
          )}
        </table>
      </div>
      {normalizedData.total === 0 && !loading && (
        <TableEmptyState
          defaultMessage={emptyStateLabel}
          searchString={searchString}
          filterOption={hasSelectedFilter()}
        />
      )}
      {!loading ? (
        <div className={styles.pagination}>
          <div className="flex gap-4">
            {enableItemsPerPageDropdown && (
              <div className="mt-2 flex items-center gap-2">
                <p className="text-sm text-gray-500 "> Items per page</p>
                <Dropdown
                  type="white"
                  options={rowsPerPageOptions}
                  disabled={false}
                  selected={
                    rowsPerPageOptions.find(
                      ({ id }) => Number(id) === rowsPerPage
                    ) || rowsPerPageOptions[0]
                  }
                  setSelected={(option) => {
                    setOffset(0);
                    setRowsPerPage(Number(option.id));
                  }}
                />
              </div>
            )}
            <Pagination
              totalItems={normalizedData.total}
              limitItems={rowsPerPage}
            />
          </div>
        </div>
      ) : (
        <TableLoadingState />
      )}
    </div>
  );
};

export default Table;
