import { gql } from '@apollo/client';

export const UPDATE_GENERIC_POLICY = gql`
  mutation UpdateGenericPolicy(
    $source: String!
    $campaign: String
    $medium: String
    $content: String
    $term: String
    $companyId: String
    $policyPurchaseEmail: String!
    $formId: String!
    $startDate: String!
    $price: Float!
    $policyNumber: String!
    $providerPolicyNumber: String
    $id: String!
    $genericPolicyData: String
  ) {
    updateGenericPolicy(
      source: $source
      campaign: $campaign
      medium: $medium
      content: $content
      term: $term
      policyPurchaseEmail: $policyPurchaseEmail
      formId: $formId
      startDate: $startDate
      price: $price
      policyNumber: $policyNumber
      providerPolicyNumber: $providerPolicyNumber
      id: $id
      genericPolicyData: $genericPolicyData
      companyId: $companyId
    ) {
      id
      startDate
      source
      campaign
      medium
      content
      term
      price
      policyPurchaseEmail
      policyNumber
      providerPolicyNumber
      formId
      genericPolicyData
      company {
        name
        id
      }
    }
  }
`;
