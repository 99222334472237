import classNames from 'classnames';

export const badgeCaption = classNames(
  'hidden',
  'peer-hover:block',
  'absolute',
  'bottom-10',
  'z-10',
  'bg-white',
  'shadow-3xl',
  'px-4',
  'py-2',
  'text-xs',
  'text-gray-600',
  'rounded-md',
  'w-fit',
  'whitespace-nowrap'
);
