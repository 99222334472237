import { gql } from '@apollo/client';

export const CREATE_GENERIC_POLICY = gql`
  mutation Mutation(
    $insuranceType: String!
    $status: String!
    $provider: String!
    $regionOfPurchase: String!
    $startDate: String
    $file: Upload
    $fileName: String
    $price: Float
    $customerId: String
    $policyNumber: String
    $email: String
    $dateOfBirth: String
    $lastName: String
    $firstName: String
  ) {
    createGenericPolicy(
      startDate: $startDate
      insuranceType: $insuranceType
      status: $status
      provider: $provider
      file: $file
      fileName: $fileName
      price: $price
      customerId: $customerId
      policyNumber: $policyNumber
      email: $email
      dateOfBirth: $dateOfBirth
      lastName: $lastName
      firstName: $firstName
      regionOfPurchase: $regionOfPurchase
    ) {
      id
    }
  }
`;
