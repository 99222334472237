import classNames from 'classnames';

export const cardInnerWrapper = classNames('border-t', 'border-gray-200');

export const noNotesWrapper = classNames(
  'my-[24px]',
  'flex',
  'flex-col',
  'items-center'
);

export const noNotesTitle = classNames('text-md', 'text-gray-900');

export const noNotesDescription = classNames('text-sm', 'text-gray-600');

export const content = classNames(
  'hover:bg-gray-50',
  'p-[24px]',
  'w-full',
  'text-left'
);

export const header = classNames('flex', 'justify-between');

export const authorName = classNames('text-sm', 'text-gray-900');

export const note = classNames('text-sm', 'text-gray-600', 'mt-[8px]');

export const date = classNames('text-xs', 'text-gray-500', 'mt-[8px]');

export const footerAction = classNames('flex', 'justify-center', 'py-[16px]');

export const viewAllNotesButton = classNames('text-gray-600');

export const pinButton = classNames('text-indigo-500');
