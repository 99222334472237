/* eslint-disable no-underscore-dangle */

import { useQuery } from '@apollo/client';
import EmptyActivityState from 'components/ActivityCards/EmptyActivityState';
import ActivityCircle from 'components/ActivityCircle';
import { ActivityCircleType } from 'components/ActivityCircle/ActivityCircle';
import Badge from 'components/Badge';
import { BadgeColor } from 'components/Badge/Badge';
import Loader from 'components/Loader';
import { TaskStatus } from 'components/TaskEngine';
import dayjs from 'dayjs';
import { isObject } from 'lodash';
import { useState } from 'react';
import { GET_ACTIVITY_LOG } from 'shared/activityLog/queries';
import {
  ActivityLogAuthor,
  ActivityLogChange,
  ActivityLogResource,
  ActivityLogResourceType,
  ActivityLogWhere,
  IActivityLogEntry,
} from 'shared/activityLog/types';
import { combinedIssueCategoryMapper } from 'shared/issues/shared/issueCategoryMapper';
import { IssueCategoryCombined } from 'shared/issues/shared/models';
import { IssueStatusType } from 'shared/mapIssueStatus';

const resourceLabels: Record<ActivityLogResourceType, string> = {
  admin_notes: 'Note',
  questionnaires: 'Questionnaire',
  tariff_infos: 'Tariff',
  tasks: 'Task',
  user: 'Customer',
  user_claim_attachments: 'Claim attachment',
  user_claim_provider_attachments: 'Provider attachment',
  user_claim_submissions: 'Claim',
  user_claim_types: 'Claim type',
  user_claims: 'Claim',
  user_claims_user_uploads: 'User upload',
  user_policies: 'Policy',
  user_policy_issues: 'Issue',
  user_uploads: 'User upload',
};

const statusBadges: Record<string, { label: string; color: BadgeColor }> = {
  ACTIVE: {
    label: 'Covered',
    color: 'green',
  },
  CANCELED: {
    label: 'Canceled',
    color: 'gray',
  },
  PENDING: {
    label: 'Pending',
    color: 'purple',
  },
  SENT: {
    label: 'Sent',
    color: 'blue',
  },
  RECEIVED: {
    label: 'Received',
    color: 'purple',
  },
  MISSING_INFO_RECEIVED: {
    label: 'Info received',
    color: 'purple',
  },
  INCOMPLETE: {
    label: 'Missing info',
    color: 'red',
  },
  DUPLICATE: {
    label: 'Duplicate',
    color: 'gray',
  },
  SUBMITTED_TO_PROVIDER: {
    label: 'Sent',
    color: 'blue',
  },
  FEATHER_PAID_OUT: {
    label: 'Feather paid out',
    color: 'gray',
  },
  OTHER_PAID_OUT: {
    label: 'Paid to 3rd party',
    color: 'green',
  },
  CLOSED: {
    label: 'Closed',
    color: 'gray',
  },
  DENIED: {
    label: 'Denied',
    color: 'red',
  },
  IN_REVIEW: {
    label: 'In review',
    color: 'yellow',
  },
  DENIED_WITH_OTHER_CLAIMS: {
    label: 'Denied with other claims',
    color: 'red',
  },
  PROCESSED_WITH_OTHER_CLAIM: {
    label: 'Processed with other claim',
    color: 'green',
  },
  MISSING_PAYMENT_INFO: {
    label: 'Missing payment info',
    color: 'red',
  },
  CUSTOMER_PAID_OUT: {
    label: 'Customer paid out',
    color: 'green',
  },
  APPROVED: {
    label: 'Approved',
    color: 'green',
  },
  DROPPED_OUT: {
    label: 'Dropped out',
    color: 'gray',
  },
  APPROVED_WITH_OTHER_CLAIMS: {
    label: 'Approved with other claims',
    color: 'green',
  },
};

const issueStatusMapper: Record<IssueStatusType, string> = {
  RESOLVED: 'resolved',
  OPEN: 're-opened',
  CUSTOMER_CONTACTED: 'customer contacted',
};

const taskStatusMapper: Record<TaskStatus, string> = {
  COMPLETED: 'completed',
  OPEN: 'opened',
  CANCELED: 'canceled',
};

const activityCircleType = (
  resource: ActivityLogResource
): ActivityCircleType => {
  let activityType: ActivityCircleType = 'STATUS_CHANGE';
  if (resource.type === 'user_policy_issues' || resource.type === 'tasks') {
    return 'ISSUES_OR_TASKS';
  }
  if (resource.operation === 'I') {
    activityType = 'CREATE';
    if (resource.type === 'admin_notes') activityType = 'NOTE';
    if (resource.type === 'user_claim_submissions')
      activityType =
        resource.rowData?.submission_details !== null
          ? 'CLAIM_SUBMISSION'
          : 'WARNING';
  }
  if (resource.operation === 'U') activityType = 'EDIT';
  if (resource.operation === 'D') activityType = 'REMOVE';
  if (resource.changes.find((change) => change.property.match(/status/)))
    activityType = 'STATUS_CHANGE';

  return activityType;
};

const Highlight = ({ title, children }: { title?: string; children: any }) => (
  <span className="font-bold text-gray-900" title={title}>
    {children}
  </span>
);

const ResourceLabel = ({ resource }: { resource: ActivityLogResource }) => (
  <Highlight>{resourceLabels[resource.type]}</Highlight>
);

const Property = ({ change }: { change: ActivityLogChange }) => {
  let formattedLabel = change.property;
  if (['policy_number', 'claim_number'].includes(change.property))
    formattedLabel = 'number';

  formattedLabel = formattedLabel.replaceAll('_', ' ');

  return <Highlight title={change.path}>{formattedLabel}</Highlight>;
};

const Value = ({ value, asBadge }: { value: any; asBadge?: BadgeColor }) => {
  let formattedValue = value;

  if (value === null) formattedValue = 'null';
  if (value && isObject(value)) formattedValue = JSON.stringify(value);
  if (value && value.toString().match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/))
    formattedValue = dayjs(value).format('DD MMM YYYY, HH:mm');

  if (asBadge) {
    return (
      <>
        {' '}
        <Badge badgeType="full" color={asBadge}>
          {formattedValue}
        </Badge>
      </>
    );
  }

  return <span className="font-bold">{formattedValue}</span>;
};

const Author = ({ author }: { author?: ActivityLogAuthor }) => {
  if (!author) return null;

  return (
    <div className="inline-block">
      <p className="ml-[8px] inline-block">·</p>
      <p className="ml-[8px] inline-block">{author.name ?? author.email}</p>
    </div>
  );
};

const Change = ({
  change,
  type: activityLogResourceType,
  rowData,
}: {
  change: ActivityLogChange;
  type: ActivityLogResourceType;
  rowData: Record<string, any> | null;
}) => {
  const { type: activityLogChangeType, property, value } = change;

  switch (activityLogChangeType) {
    case 'create':
      return (
        <>
          <Property change={change} /> added with value <Value value={value} />
        </>
      );
    case 'update':
      // Issue status change
      if (
        activityLogResourceType === 'user_policy_issues' &&
        property === 'status'
      ) {
        return (
          <>
            {issueStatusMapper[value.__new as IssueStatusType]}
            {rowData && (
              <>
                :{' '}
                <span className="font-bold text-indigo-500">
                  {rowData.title ??
                    combinedIssueCategoryMapper[
                      rowData.category as IssueCategoryCombined
                    ]}
                </span>
              </>
            )}
          </>
        );
      }

      // Task status change
      if (activityLogResourceType === 'tasks' && property === 'status') {
        return (
          <>
            {taskStatusMapper[value.__new as TaskStatus]}
            {rowData && (
              <>
                :{' '}
                <span className="font-bold text-indigo-500">
                  {rowData.description.metadata.title}
                </span>
              </>
            )}
          </>
        );
      }

      if (property.match('status') && value.__new in statusBadges) {
        return (
          <>
            {!['RECEIVED', 'SUBMITTED_TO_PROVIDER'].includes(value.__new) && (
              <>
                <Highlight title={change.path}>status</Highlight> changed to
              </>
            )}
            <Value
              value={statusBadges[value.__new].label}
              asBadge={statusBadges[value.__new].color}
            />
          </>
        );
      }

      if (property === 'application_sent_at' && value.__new !== null) {
        return (
          <>
            application{' '}
            <Value
              value={statusBadges.SENT.label}
              asBadge={statusBadges.SENT.color}
            />
          </>
        );
      }

      if (property === 'is_delinquent') {
        return (
          <>
            marked as{' '}
            <Highlight>
              {value.__new ? 'delinquent' : 'not delinquent'}
            </Highlight>
          </>
        );
      }

      if (property === 'is_fraudulent') {
        return (
          <>
            marked as{' '}
            <Highlight>
              {value.__new ? 'fraudulent' : 'not fraudulent'}
            </Highlight>
          </>
        );
      }

      return value.__old !== null && value.__old !== '' ? (
        <>
          <Property change={change} /> changed from{' '}
          <Value value={value.__old} /> to <Value value={value.__new} />
        </>
      ) : (
        <>
          <Property change={change} /> changed to <Value value={value.__new} />
        </>
      );
    case 'delete':
      return (
        <>
          <Property change={change} /> deleted (was <Value value={value} />)
        </>
      );
  }
};

const InsertChange = ({
  author,
  resource,
}: {
  author?: ActivityLogAuthor;
  resource: ActivityLogResource;
}) => {
  switch (resource.type) {
    case 'user':
      return <>account created</>;

    case 'admin_notes':
      return (
        <>
          added <Author author={author} />
          <div className="mt-4 w-[calc(100%+120px+6rem)] rounded-[8px] bg-white p-4">
            {resource.rowData?.body}
          </div>
        </>
      );

    case 'user_claim_types':
      return (
        <>
          <Value asBadge="gray" value={resource.rowData?.claim_type} /> added
        </>
      );

    case 'user_claim_submissions': {
      const success = resource.rowData?.submission_details !== null;
      return success ? (
        <>successfully submitted to provider</>
      ) : (
        <>submission to provider failed</>
      );
    }

    case 'user_policy_issues':
      return (
        <>
          created
          {resource.rowData && (
            <>
              :{' '}
              <span className="font-bold text-indigo-500">
                {resource.rowData.title ??
                  combinedIssueCategoryMapper[
                    resource.rowData.category as IssueCategoryCombined
                  ]}
              </span>
            </>
          )}
        </>
      );

    case 'tasks':
      return (
        <>
          created
          {resource.rowData && (
            <>
              :{' '}
              <span className="font-bold text-indigo-500">
                {resource.rowData.description.metadata.title}
              </span>
            </>
          )}
        </>
      );

    default:
      return <> added </>;
  }
};

const DeleteChange = ({ resource }: { resource: ActivityLogResource }) => {
  switch (resource.type) {
    case 'user_claim_types':
      return (
        <>
          <Value asBadge="gray" value={resource.rowData?.claim_type} /> removed
        </>
      );

    default:
      return <> removed </>;
  }
};

const EntryDescription = ({
  author,
  resource,
}: {
  author: ActivityLogAuthor;
  resource: ActivityLogResource;
}) => {
  switch (resource?.operation) {
    case 'I':
      return (
        <>
          <ResourceLabel resource={resource} />{' '}
          <InsertChange author={author} resource={resource} />{' '}
          {resource.type !== 'admin_notes' && <Author author={author} />}
        </>
      );

    case 'U':
      if (resource.type === 'user_policy_issues') {
        const statusChangeObject = resource.changes.find(
          ({ property }) => property === 'status'
        );
        const changesWithoutStatus = resource.changes.filter(
          ({ property }) => property !== 'status'
        );

        return (
          <>
            {statusChangeObject && (
              <>
                <ResourceLabel resource={resource} />{' '}
                <Change
                  change={statusChangeObject}
                  type={resource.type}
                  rowData={resource.rowData}
                />{' '}
                <Author author={author} />
              </>
            )}
            {changesWithoutStatus && (
              <ul className="mt-4">
                {changesWithoutStatus.map((change) => (
                  <li key={change.property}>
                    <Change
                      change={change}
                      type={resource.type}
                      rowData={resource.rowData}
                    />
                  </li>
                ))}
              </ul>
            )}
          </>
        );
      }

      if (resource.changes.length === 1) {
        return (
          <>
            <ResourceLabel resource={resource} />{' '}
            <Change
              change={resource.changes[0]}
              type={resource.type}
              rowData={resource.rowData}
            />{' '}
            <Author author={author} />
          </>
        );
      }

      return (
        <>
          <ResourceLabel resource={resource} /> edited{' '}
          <Author author={author} />
          <ul className="mt-4">
            {resource.changes.map((change) => (
              <li key={change.property}>
                <Change
                  change={change}
                  type={resource.type}
                  rowData={resource.rowData}
                />
              </li>
            ))}
          </ul>
        </>
      );

    case 'D':
      return (
        <>
          <ResourceLabel resource={resource} />{' '}
          <DeleteChange resource={resource} /> <Author author={author} />
        </>
      );

    default:
      return null;
  }
};

export const ActivityLogEntry = ({
  eventId,
  author,
  timestamp,
  resource,
  hasActivityLine,
}: IActivityLogEntry & {
  hasActivityLine: boolean;
}) => {
  if (resource.operation === 'U' && resource.changes.length === 0) return null;

  return (
    <li
      className="relative flex min-h-[60px] w-full items-stretch justify-between"
      key={eventId}
    >
      <div className="flex w-[calc(100%-120px-6rem)] items-stretch">
        <ActivityCircle
          activityType={activityCircleType(resource)}
          hasActivityLine={hasActivityLine}
        />
        <div className="mt-2 w-full pb-4 leading-5 text-gray-600">
          <EntryDescription author={author} resource={resource} />
        </div>
      </div>
      <span className="ml-24 mt-2 w-[140px] text-right text-gray-500">
        {dayjs(timestamp).format('DD MMM YYYY, HH:mm')}
      </span>
    </li>
  );
};

export const RecentActivityEntry = ({
  eventId,
  author,
  timestamp,
  resource,
  hasActivityLine,
}: IActivityLogEntry & {
  hasActivityLine: boolean;
}) => {
  return (
    <div className="flex text-sm" key={eventId}>
      <ActivityCircle
        activityType={activityCircleType(resource)}
        hasActivityLine={hasActivityLine}
        smallVersion
      />
      <div className="mt-2 pb-4 text-gray-600">
        <EntryDescription author={author} resource={resource} />
        <span className="ml-1 mt-2 text-gray-500">
          {dayjs(timestamp).format('DD MMM YYYY, HH:mm')}
        </span>
      </div>
    </div>
  );
};

const PAGE_SIZE = 10;

export const ActivityLog = ({ where }: { where: ActivityLogWhere[] }) => {
  const [entries, setEntries] = useState<IActivityLogEntry[]>([]);
  const [loadMore, setLoadMore] = useState<boolean>(false);

  const { loading, refetch } = useQuery(GET_ACTIVITY_LOG, {
    variables: {
      where,
      pageSize: PAGE_SIZE,
      beforeEvent: '',
    },
    skip: !where.every(({ value }) => !!value),
    onCompleted: (res: { activityLog: IActivityLogEntry[] }) => {
      const newEntries = res.activityLog;
      if (newEntries.length < PAGE_SIZE) setLoadMore(false);
      else if (newEntries.length === PAGE_SIZE) setLoadMore(true);

      setEntries((state) => [...state, ...newEntries]);
    },
  });

  if (loading && entries.length === 0)
    return (
      <div className="flex h-[200px] w-full animate-fade-in flex-col items-center justify-center">
        <Loader className="animate-spin" />
        <p className="mt-[8px] text-sm text-gray-600">Loading activity logs</p>
      </div>
    );

  if (entries.length === 0)
    return (
      <div className="relative w-full">
        <EmptyActivityState />
      </div>
    );

  return (
    <div className="w-fit relative min-w-full">
      <ol className="justify-left flex flex-col items-start text-sm">
        {entries.map((entry, i) => (
          <ActivityLogEntry
            key={entry.eventId}
            {...entry}
            hasActivityLine={i !== entries.length - 1}
          />
        ))}
      </ol>

      {loadMore && loading && <Loader className="animate-spin" />}
      {loadMore && !loading && (
        <button
          type="button"
          onClick={() =>
            refetch({ beforeEvent: entries[entries.length - 1].eventId })
          }
        >
          Load More
        </button>
      )}
    </div>
  );
};
