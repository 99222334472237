/**
 * NOTE: This type is being shared with BE.
 * When updating, also update BE type
 *
 * Notion page for all vertical claim statuses:
 * https://www.notion.so/featherinsurance/Claim-statuses-2-0-22713a3fe92c49969b2e403f655678b2
 */
export const genericClaimStatuses = [
  'RECEIVED',
  'INCOMPLETE',
  'MISSING_INFO_RECEIVED',
  'SUBMITTED_TO_PROVIDER',
  'OTHER_PAID_OUT',
  'FEATHER_PAID_OUT',
  'CUSTOMER_PAID_OUT',
  'DENIED',
  'DUPLICATE',
  'DROPPED_OUT',
  'CLOSED',
] as const;
export type GenericClaimStatus = typeof genericClaimStatuses[number];

export const travelClaimTypes = [
  'AMBULANCE',
  'HOSPITAL_OVERNIGHT',
  'MEDICATION',
  'DOCTORS_VISIT',
  'OTHER',
] as const;

export const petHealthClaimTypes = [
  'SURGERY',
  'PREVENTATIVE_TREATMENT',
  'VETERINARY_VISITS',
  'MEDICATION',
  'OTHER',
] as const;

export const expatLTClaimTypes = [
  'COST_PLAN',
  'DENTAL',
  'DOCTORS_VISIT',
  'HOSPITAL_TREATMENT',
  'MEDICATION',
  'OTHER',
  'PHYSICAL_THERAPY',
] as const;

export const standardGenericClaimTypes = ['OTHER'] as const;

export const combinedGenericClaimTypes = [
  ...travelClaimTypes,
  ...petHealthClaimTypes,
  ...expatLTClaimTypes,
  ...standardGenericClaimTypes,
] as const;

export type GenericClaimType = typeof combinedGenericClaimTypes[number];
