import { InsuranceType } from 'shared/insurances/types';

export type TransactionInsuranceType = Extract<
  InsuranceType,
  'INCOMING' | 'DENTAL' | 'INCOMING_ES'
>;

export const expatTransactionInsuranceTypes: TransactionInsuranceType[] = [
  'INCOMING',
  'INCOMING_ES',
];

export const insuranceTypeFromPath = [
  'expat-health',
  'dental',
  'expat-health-es',
] as const;
export type InsuranceTypeFromPath = typeof insuranceTypeFromPath[number];

export const InsuranceTypeFromPathMapping: Record<
  InsuranceTypeFromPath,
  TransactionInsuranceType
> = {
  'expat-health': 'INCOMING',
  dental: 'DENTAL',
  'expat-health-es': 'INCOMING_ES',
};
