import classNames from 'classnames';
import { CheckBox } from 'components/CheckBox';

import { HeaderInformation, Row, TextColor } from '..';
import * as styles from './styles';

interface TableRowProps {
  rowIndex: number;
  row: Row;
  headers: HeaderInformation[];
  checkboxProps?: CheckBoxProps;
}

type CheckBoxProps = {
  onCheckBoxClick: () => void;
  checked: boolean;
  onShiftCheckBoxClick: () => void;
  muted: boolean;
};

const TableRow = ({ rowIndex, row, headers, checkboxProps }: TableRowProps) => {
  return (
    <>
      <tr
        key={rowIndex}
        className={classNames(styles.tableRow, {
          [styles.clickable]: row.resourceLink || row.resourceOnClick,
          [styles.checked]: checkboxProps?.checked,
        })}
      >
        {checkboxProps && (
          <td className={styles.checkboxWrapper}>
            <CheckBox
              checked={checkboxProps.checked}
              muted={checkboxProps.muted}
              setChecked={checkboxProps.onCheckBoxClick}
              onShiftClick={() => checkboxProps.onShiftCheckBoxClick?.()}
            />
          </td>
        )}
        {row.cells.map((cell, cellIndex) => {
          const color = cell.textColor as TextColor;
          return (
            <cell.component
              textColor={color}
              header={headers[cellIndex]}
              cell={cell.data}
              key={cellIndex}
              divider={rowIndex !== 0}
              statusMapping={cell.statusMapping}
              check={cell.check}
              completedStudentChecklist={cell.completedStudentChecklist}
              badgeColor={cell.badgeColor}
              badgeCaption={cell.badgeCaption}
              link={row.resourceLink}
              title={cell.title}
              onClick={cell.onClick}
              resourceOnClick={row.resourceOnClick}
              href={cell.href}
            />
          );
        })}
      </tr>
    </>
  );
};

export default TableRow;
