import Input from 'components/Input';
import useQueryParams from 'hooks/useQueryParams';
import { FormEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import * as styles from './styles';

interface SearchBarProps {
  searchString: string;
  fetchSearch?: (query: string) => void;
  loading: boolean;
  placeholder: string;
  includePlaceholderDropdown?: boolean;
}

const SearchBar = ({
  searchString,
  fetchSearch,
  loading,
  placeholder,
  includePlaceholderDropdown,
}: SearchBarProps) => {
  const [query, setQuery] = useState(searchString);
  const [showPlaceholderDropdown, setShowPlaceholderDropdown] = useState(false);

  const history = useHistory();
  const queryParams = useQueryParams();

  useEffect(() => {
    setQuery(searchString);
  }, [searchString]);

  const handleSubmitSearch = (e: FormEvent) => {
    e.preventDefault();
    fetchSearch?.(query);
    queryParams.set('search', query);
    history.push(`?${queryParams.toString()}`);
  };

  const handleClearSearch = () => {
    setQuery('');
  };

  // TODO: [KONG] Max character length that fits the input width
  const isPlaceholderLengthExceeding = placeholder.length > 42;

  return (
    <form
      className={styles.searchBar}
      onSubmit={handleSubmitSearch}
      onMouseEnter={() => {
        setShowPlaceholderDropdown(true);
      }}
      onMouseLeave={() => {
        setShowPlaceholderDropdown(false);
      }}
    >
      <Input
        id="search"
        placeholder={placeholder}
        disabled={loading}
        color="white"
        search
        clearSearch={handleClearSearch}
        value={query}
        onChange={(event) => setQuery(event.target.value)}
      />
      {includePlaceholderDropdown &&
        showPlaceholderDropdown &&
        isPlaceholderLengthExceeding && (
          <div className={styles.placeholderDropdown}>{placeholder}</div>
        )}
    </form>
  );
};

export default SearchBar;
