import { gql } from '@apollo/client';

export const UPLOAD_EXPAT_SPAIN_DOCUMENT = gql`
  mutation UpdateExpatSpainDocument(
    $policyId: String!
    $newFilename: String
    $file: Upload
  ) {
    uploadExpatSpainDocument(
      id: $policyId
      newFilename: $newFilename
      file: $file
    ) {
      id
      url
      name
    }
  }
`;

export const DELETE_EXPAT_SPAIN_DOCUMENT = gql`
  mutation DeleteExpatSpainDocument(
    $id: String!
    $blobName: String!
    $documentType: String!
  ) {
    deleteExpatSpainPolicyFile(
      id: $id
      blobName: $blobName
      type: $documentType
    ) {
      id
    }
  }
`;
