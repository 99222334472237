import { gql } from '@apollo/client';
import { POLICY_ACTIVITY } from 'graphql/publicHealth/fragments/policyActivity';

import { HEALTH_CARD } from '../fragments/healthCard';

export const GET_PUBLIC_POLICIES = gql`
  query GetPublicHealthPolicies(
    $offset: Float!
    $limit: Float!
    # TODO: [KONG] Convert all fields below this line to optional
    # For some reason, converting these to optional fail requests
    $searchString: String!
    $sortColumn: String!
    $sortOrder: String!
    $filterStatus: [String]!
    $filterProvider: [String]!
    $filterOccupation: [String]!
    $filterView: String!
    $createdAtRangeStart: DateTime
    $createdAtRangeEnd: DateTime
    $birthDateRangeStart: DateTime
    $birthDateRangeEnd: DateTime
  ) {
    publicHealthPolicies(
      offset: $offset
      limit: $limit
      searchString: $searchString
      sortColumn: $sortColumn
      sortOrder: $sortOrder
      filterOccupation: $filterOccupation
      filterStatus: $filterStatus
      filterProvider: $filterProvider
      filterView: $filterView
      createdAtRangeStart: $createdAtRangeStart
      createdAtRangeEnd: $createdAtRangeEnd
      birthDateRangeStart: $birthDateRangeStart
      birthDateRangeEnd: $birthDateRangeEnd
    ) {
      id
      providerId
      createdAt
      user {
        id
        firstName
        lastName
        isDelinquent
        isFraudulent
        fraudulentAdditionalInfo
      }

      insuredPerson {
        firstName
        lastName
      }

      publicStatus
      startDate
      studentChecklist {
        completed
      }
      occupation
      salary
      otherSituation
      source
      hasInternalActivityDot
      hasCustomerContactedDot
      updatedAt
      sentToProvider
      archivedAt
    }
    totalPublicHealthPolicies(
      searchString: $searchString
      sortColumn: $sortColumn
      sortOrder: $sortOrder
      filterOccupation: $filterOccupation
      filterStatus: $filterStatus
      filterProvider: $filterProvider
      filterView: $filterView
      createdAtRangeStart: $createdAtRangeStart
      createdAtRangeEnd: $createdAtRangeEnd
      birthDateRangeStart: $birthDateRangeStart
      birthDateRangeEnd: $birthDateRangeEnd
    ) {
      totalPolicies
      numberOfActivityDot
    }
  }
`;

export const GET_PUBLIC_POLICY = gql`
  ${HEALTH_CARD}
  ${POLICY_ACTIVITY}
  query GetPublicHealthPolicy($id: String!) {
    publicHealthPolicy(id: $id) {
      id
      createdAt
      statusSetToCoveredAt
      applicationSentAt
      providerId
      providerBranch
      startDate
      archivedAt
      user {
        id
        email
        firstName
        lastName
        dateOfBirth
        svnr
        intercomEUId
        isDelinquent
        isFraudulent
        fraudulentAdditionalInfo
        duplicateHealthPolicies {
          id
          insuranceType
          createdAt
          publicStatus
          providerId
        }
      }

      insuredPerson {
        firstName
        lastName
        gender
        dateOfBirth
      }

      kvnr
      publicStatus
      tkApiId
      studentChecklist {
        id
        completed
        iban
        uniEnrollmentCertificateUrl
        acceptanceLetterUrl
        proofOfConfirmationOfCoverage
        address {
          city
          street
          country
          postcode
          houseNumber
          additionalInformation
        }
      }
      occupation
      salary
      otherSituation
      source
      campaign
      medium
      content
      term
      hasInternalActivityDot
      providerAgent {
        id
        firstName
        lastName
        email
      }
      company {
        id
        name
      }
      mergedConfirmationOfCoverageUrl
      confirmationOfCoverageFromProviderUrl
      confirmationOfCoverageUrl
      powerOfAttorneyUrl
      sepaMandateUrl
      applicationDocumentUrl
      signedTKApplicationUrl
      preliminaryConfirmationOfCoverage {
        id
        url
        blobName
      }
      otherDocumentsFromFeather {
        name
        url
        id
        blobName
      }
      otherDocumentsFromProvider {
        name
        url
        id
        blobName
      }
      confirmationOfPermanentResidenceDocumentUrl
      ...PolicyActivity
      openIssues {
        id
        createdAt
        author {
          email
          company
          firstName
          lastName
        }
        description
        category
        status
        document {
          id
          name
        }
      }
      questionnaire {
        id
        answers
      }
      tkApiRequests {
        id
        response
        status
        payload
      }
      ...HealthCard
      commissionEntitlement
      commissionId
      hrInformation {
        email
        employerName
        letterToEmployerSentAt
        hrPersonName
      }
      premoderationIssues {
        id
        active
        category
      }

      pinnedNotes {
        id
        body
        createdAt
        adminUser {
          firstName
          lastName
        }
        updatedAt
      }

      referral {
        status
        referralCode
      }
    }
  }
`;

export const GET_PUBLIC_ISSUES = gql`
  query getPublicHealthIssues(
    $offset: Float!
    $limit: Float!
    $searchString: String!
    $sortColumn: String!
    $sortOrder: String!
    $filterStatus: [String]!
    $filterProvider: [String]!
    $filterType: [String]!
    $filterOccupation: [String]!
    $isCustomerContacted: String!
    $filterView: String!
    $updatedOnRangeStart: DateTime
    $updatedOnRangeEnd: DateTime
  ) {
    publicHealthIssues(
      offset: $offset
      limit: $limit
      searchString: $searchString
      sortColumn: $sortColumn
      sortOrder: $sortOrder
      filterStatus: $filterStatus
      filterProvider: $filterProvider
      filterType: $filterType
      filterOccupation: $filterOccupation
      isCustomerContacted: $isCustomerContacted
      filterView: $filterView
      updatedOnRangeStart: $updatedOnRangeStart
      updatedOnRangeEnd: $updatedOnRangeEnd
    ) {
      id
      category
      isCustomerInformed
      description
      updatedAt
      status
      numberOfComments
      userPolicy {
        id
        providerId
        user {
          firstName
          lastName
          email
          isDelinquent
          isFraudulent
          fraudulentAdditionalInfo
        }
      }
    }
    totalPublicHealthIssues(
      searchString: $searchString
      sortColumn: $sortColumn
      sortOrder: $sortOrder
      filterStatus: $filterStatus
      filterProvider: $filterProvider
      filterType: $filterType
      filterOccupation: $filterOccupation
      isCustomerContacted: $isCustomerContacted
      filterView: $filterView
      updatedOnRangeStart: $updatedOnRangeStart
      updatedOnRangeEnd: $updatedOnRangeEnd
    )
  }
`;
