import { gql } from '@apollo/client';

import { LEGAL_POLICY_ACTIVITY } from './activity';

export const GET_LEGAL_POLICY = gql`
  ${LEGAL_POLICY_ACTIVITY}
  query GetLegalPolicy($id: String!) {
    legalPolicy(id: $id) {
      id
      policyNumber
      createdAt
      startDate
      archivedAt
      price
      paymentReceivedAt
      source
      campaign
      medium
      content
      term
      numberOfClaims
      publicStatus
      activeUntil
      legalPlanId
      providerId

      # Partner name
      partnerName {
        firstName
        lastName
      }

      # Address
      address {
        street
        houseNumber
        city
        postcode
        country
        additionalInformation
      }

      # Add ons
      addOns

      # insured person
      insuredPerson {
        firstName
        lastName
        dateOfBirth
        email
        gender
      }

      # user
      user {
        id
        firstName
        lastName
        stripeToken
        intercomEUId
        email
        isDelinquent
        isFraudulent
        fraudulentAdditionalInfo
      }

      # documents
      policyDocuments {
        url
        id
        name
        blobName
        isRegeneratable
        resizedUrl
      }
      questionnaireDownloadDocuments {
        id
        url
        blobName
      }

      # policy cancelation request
      policyCancelationRequest {
        id
        cancelationConfirmedByProviderAt
        createdAt
        canceledOnStripeAt
        requestSentToProviderAt
      }

      # questionnaire
      questionnaire {
        id
        answers
      }

      pinnedNotes {
        id
        body
        createdAt
        adminUser {
          firstName
          lastName
        }
        updatedAt
      }

      # policy activity
      ...LegalPolicyActivity

      referral {
        referralCode
        status
      }
    }
  }
`;
