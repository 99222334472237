import { ApolloError } from '@apollo/client';
import Header from 'components/Header';
import OverviewPage from 'components/OverviewPage/OverviewPage';
import { EmptyStateMessage } from 'components/Table/TableEmptyState';
import Table from 'components/TableRefactor/Table';
import { useState } from 'react';

import { CreatePolicy } from '../CreatePolicy/CreatePolicy';
import {
  HRTemplateInsuranceType,
  TemplateInsuranceType,
} from '../models/InsuranceTypes';
import { PolicyTemplateData } from '../models/PolicyTemplateData';

interface PoliciesOverviewTemplateProps {
  templateData: PolicyTemplateData<any, any>;
  insuranceType: TemplateInsuranceType | HRTemplateInsuranceType;
}

const PoliciesOverviewTemplate = ({
  templateData,
  insuranceType,
}: PoliciesOverviewTemplateProps) => {
  const [error, setError] = useState<ApolloError | undefined>();
  const defaultEmptyStateMessage: EmptyStateMessage = {
    title: 'There are no policies yet.',
    description: 'It looks like there are no policies yet.',
  };

  return (
    <OverviewPage
      current={templateData.navigation.current}
      title="All policies - Feather Admin Panel"
      error={error}
    >
      {templateData.features.languageSelector ? <Header /> : <></>}
      <Table
        title={templateData.copy.table.title}
        subtitle={templateData.copy.table.subtitle}
        itemsPerPage={templateData.features.overviewTable.numberOfRowsPerPage}
        emptyStateLabel={
          templateData.features.overviewTable.defaultEmptyStateMessage ??
          defaultEmptyStateMessage
        }
        searchPlaceholder={templateData.copy.table.searchPlaceholder}
        filterConfig={templateData.features.overviewTable.filtersConfig}
        rowConfig={templateData.features.overviewTable.rowConfig}
        viewConfig={templateData.features.overviewTable.viewConfig}
        onError={setError}
        actionButton={
          <>
            {templateData.features.overviewTable.actionButton}
            {templateData.features.policyCreation &&
              templateData.mutations.createPolicy && (
                <CreatePolicy
                  insuranceType={insuranceType}
                  createPolicyMutation={templateData.mutations.createPolicy}
                  refetchQueries={[templateData.queries.allPolicies]}
                  getFormData={
                    templateData.features.policyCreation.retrieveFormData
                  }
                  createPolicySubmissionSchema={
                    templateData.features.policyCreation
                      .createPolicySubmissionSchema
                  }
                />
              )}
          </>
        }
        query={templateData.queries.allPolicies}
        queryVariables={{
          insuranceType,
          ...templateData.features.overviewTable.queryVariables,
        }}
        headers={templateData.features.overviewTable.headersConfig}
      />
    </OverviewPage>
  );
};

export default PoliciesOverviewTemplate;
