import { ApolloError } from '@apollo/client';
import { useFlag } from '@unleash/proxy-client-react';
import OverviewPage from 'components/OverviewPage';
import { SelectButtonOption } from 'components/SelectButton';
import { EmptyStateMessage } from 'components/Table/TableEmptyState';
import ColumnHeader from 'components/TableRefactor/ColumnHeader';
import { FilterConfig } from 'components/TableRefactor/Filters/types';
import SortableColumnHeader from 'components/TableRefactor/SortableColumnHeader';
import Table from 'components/TableRefactor/Table';
import {
  HeaderConfig,
  RowConfig,
  ViewConfig,
} from 'components/TableRefactor/types';
import { PrivateHealthPolicy } from 'models/privateHealthPolicy';
import { getPrivateTariffName } from 'pages/policies/privateHealth/utils/mapTariffNames';
import { useState } from 'react';
import { generatePath, useLocation } from 'react-router-dom';
import { paths } from 'routes/definedPaths';
import { getPrivateHealthStatus } from 'shared/mapPrivateHealthStatus';
import { mapUserOccupation } from 'shared/mapUserOccupation';
import { shouldDisplayIssueActivityDot } from 'shared/utils/shouldDisplayIssueActivityDot';

import CreatePolicy from '../components/CreatePolicy';
import { Reconciliation } from '../components/ReconciliationModal/ReconciliationModal';
import { GET_PRIVATE_POLICIES } from '../graphql/queries';

const NUMBER_OF_POLICIES_PER_PAGE = 12;

export interface Data {
  privateHealthPolicies: PrivateHealthPolicy[];
  privateHealthPoliciesCount: {
    totalPolicies: number;
    totalOpenIssuesPolicies: number;
    totalTaskReviewNeededPolicies: number;
  };
}

const filterStatusOptions: SelectButtonOption[] = [
  {
    title: 'Received',
    label: 'Received',
    id: 'received',
  },
  {
    title: 'Sent',
    label: 'Sent',
    id: 'sent',
  },
  {
    title: 'Covered',
    label: 'Covered',
    id: 'covered',
  },
  {
    title: 'Missing info',
    label: 'Missing info',
    id: 'missinginfo',
  },
  {
    title: 'Dropped out',
    label: 'Dropped out',
    id: 'droppedout',
  },
  {
    title: 'Canceled',
    label: 'Canceled',
    id: 'canceled',
  },
  {
    title: 'Not eligible',
    label: 'Not eligible',
    id: 'noteligible',
  },
];

const filterOccupationOptions: SelectButtonOption[] = [
  {
    title: 'Employed',
    label: 'Employed',
    id: 'employed',
  },
  {
    title: 'Self-employed',
    label: 'Self-employed',
    id: 'self-employed',
  },
  {
    title: 'Other',
    label: 'Other',
    id: 'other',
  },
];

const filterApplicationSentOptions: SelectButtonOption[] = [
  {
    title: 'Any',
    label: 'Any',
    id: 'any',
  },
  {
    title: 'Yes',
    label: 'Yes',
    id: 'yes',
  },
  {
    title: 'No',
    label: 'No',
    id: 'no',
  },
];

const InternalPrivateHealthPage = () => {
  // Only show recon modal if explicitly enabled and with special hash to minimize risk
  const { hash } = useLocation();
  const [error, setError] = useState<ApolloError | undefined>();
  const isReconciliationVisible =
    useFlag('admin_panel_private_reconciliation_visible') && hash === '#recon';

  const tableFilters: FilterConfig[] = [
    {
      options: filterStatusOptions,
      filterType: 'MULTIPLE',
      label: 'Status',
      id: 'filterStatus',
      placeholder: 'Select status(es)',
    },
    {
      options: filterOccupationOptions,
      filterType: 'MULTIPLE',
      label: 'Occupation',
      id: 'filterOccupation',
      placeholder: 'Select occupation(s)',
    },
    {
      filterType: 'DATE_RANGE',
      label: 'Created on',
      id: 'createdAt',
    },
    {
      options: filterApplicationSentOptions,
      filterType: 'TEXT',
      label: 'Application sent?',
      id: 'applicationSent',
    },
  ];

  const viewConfig: ViewConfig = {
    id: 'filterView',
    options: [
      {
        id: 'ALL',
        label: 'All policies',
      },
      {
        id: 'OPEN_ISSUES',
        label: 'All open issues',
        displayDot: (data: Data) =>
          Boolean(data?.privateHealthPoliciesCount.totalOpenIssuesPolicies),
      },
      {
        id: 'TASK_REVIEW_NEEDED',
        label: 'Task review needed',
        displayDot: (data: Data) =>
          Boolean(
            data?.privateHealthPoliciesCount.totalTaskReviewNeededPolicies
          ),
      },
    ],
    getGeneralActivityDot: (data: Data) =>
      Boolean(
        data?.privateHealthPoliciesCount.totalOpenIssuesPolicies ||
          data?.privateHealthPoliciesCount.totalTaskReviewNeededPolicies
      ),
  };

  const tableHeaders: HeaderConfig[] = [
    {
      id: 'activityDot',
      label: '',
      width: 'w-[40px]',
      minWidth: 'min-w-[40px]',
      sticky: true,
      threshold: 'left-0',
      border: 'none',
      component: ColumnHeader,
    },
    {
      id: 'first-name',
      label: 'First name',
      width: 'w-none',
      minWidth: 'min-w-[120px]',
      sticky: true,
      threshold: 'left-0',
      border: 'none',
      component: SortableColumnHeader,
    },
    {
      id: 'last-name',
      label: 'Last name',
      width: 'w-none',
      minWidth: 'min-w-[120px]',
      sticky: true,
      threshold: 'left-[120px]',
      border: 'none',
      component: SortableColumnHeader,
    },
    {
      id: 'status',
      label: 'Status',
      width: 'w-none',
      minWidth: 'min-w-[120px]',
      sticky: true,
      threshold: 'left-[360px]',
      border: 'right',
      component: SortableColumnHeader,
    },
    {
      id: 'sent',
      label: 'Sent',
      width: 'w-[56px]',
      minWidth: 'min-w-[56px]',
      component: ColumnHeader,
    },
    {
      id: 'start-date',
      label: 'Start date',
      width: 'w-[116px]',
      minWidth: 'min-w-[56px]',
      component: SortableColumnHeader,
    },
    {
      id: 'tariff',
      label: 'Tariff',
      width: 'w-[120px]',
      minWidth: 'min-w-[120px]',
      component: SortableColumnHeader,
    },
    {
      id: 'occupation',
      label: 'Occupation',
      width: 'w-[140px]',
      minWidth: 'min-w-[140px]',
      component: ColumnHeader,
    },
    {
      id: 'created-at',
      label: 'Created on',
      width: 'w-[120px]',
      minWidth: 'min-w-[120px]',
      component: SortableColumnHeader,
    },
    {
      id: 'source',
      label: 'Source',
      width: 'w-[120px]',
      minWidth: 'min-w-[120px]',
      component: ColumnHeader,
    },
  ];

  const rowConfig: RowConfig = {
    getResourceLink: (data: PrivateHealthPolicy) =>
      generatePath(paths.policies.privateHealth.internal.details.path, {
        id: data.id,
      }),
    cells: [
      {
        type: 'DOT',
        props: {
          getDot: (data: PrivateHealthPolicy) => ({
            active: shouldDisplayIssueActivityDot(data.issues),
            badgeColor: 'yellow',
            badgeCaption: 'Action needed',
          }),
        },
        textColor: 'dark',
      },
      {
        type: 'TEXT',
        props: {
          getText: (data: PrivateHealthPolicy) =>
            data.insuredPerson?.firstName ?? data.user.firstName ?? '',
        },
        textColor: 'dark',
      },
      {
        type: 'TEXT',
        props: {
          getText: (data: PrivateHealthPolicy) =>
            data.insuredPerson?.lastName ?? data.user.lastName ?? '',
        },
        textColor: 'dark',
      },
      {
        type: 'STATUS',
        props: {
          getStatus: (data: PrivateHealthPolicy) => {
            return data.archivedAt
              ? {
                  text: {
                    id: 'ARCHIVED',
                    text: 'Archived',
                  },
                  color: 'gray',
                }
              : getPrivateHealthStatus(data.publicStatus);
          },
        },
        textColor: 'dark',
      },
      {
        type: 'CHECK',
        props: {
          getCheck: (data: PrivateHealthPolicy) => ({
            check: data.applicationSentAt ? 'check' : 'cross',
          }),
        },
        textColor: 'light',
      },
      {
        type: 'DATE',
        props: {
          getDate: (data: PrivateHealthPolicy) => data.startDate ?? '',
        },
        textColor: 'light',
      },
      {
        type: 'TEXT',
        props: {
          getText: (data: PrivateHealthPolicy) =>
            getPrivateTariffName(data.currentTariffInfo?.tariff ?? ''),
        },
        textColor: 'light',
      },
      {
        type: 'TEXT',
        props: {
          getText: (data: PrivateHealthPolicy) =>
            mapUserOccupation(data.insuredPerson?.occupation ?? ''),
        },
        textColor: 'light',
      },
      {
        type: 'DATE',
        props: {
          getDate: (data: PrivateHealthPolicy) => data.createdAt ?? '',
        },
        textColor: 'light',
      },
      {
        type: 'TEXT',
        props: { getText: (data: PrivateHealthPolicy) => data.source ?? '' },
        textColor: 'light',
      },
    ],
  };

  const defaultEmptyStateMessage: EmptyStateMessage = {
    title: 'There are no policies yet.',
    description: 'It looks like there are no private health policies yet.',
  };

  return (
    <>
      <OverviewPage
        title="All policies - Feather Admin Panel"
        current="policies-private"
        error={error}
      >
        <Table
          title="Private health policies"
          itemsPerPage={NUMBER_OF_POLICIES_PER_PAGE}
          emptyStateLabel={defaultEmptyStateMessage}
          searchPlaceholder="Search by name, email, policy number, policy id or utm source"
          filterConfig={tableFilters}
          actionButton={
            <>
              {isReconciliationVisible && <Reconciliation />} <CreatePolicy />
            </>
          }
          query={GET_PRIVATE_POLICIES}
          headers={tableHeaders}
          rowConfig={rowConfig}
          onError={setError}
          viewConfig={viewConfig}
          countPath={['privateHealthPoliciesCount', 'totalPolicies']}
        />
      </OverviewPage>
    </>
  );
};

export default InternalPrivateHealthPage;
