import { gql } from '@apollo/client';

export const UPLOAD_DENTAL_DOCUMENT = gql`
  mutation UploadDentalDocument(
    $policyId: String!
    $newFilename: String
    $file: Upload
  ) {
    uploadDentalDocument(
      id: $policyId
      newFilename: $newFilename
      file: $file
    ) {
      id
      url
      name
    }
  }
`;

export const DELETE_DENTAL_DOCUMENT = gql`
  mutation DeleteDentalDocument(
    $id: String!
    $blobName: String!
    $documentType: String!
  ) {
    deleteDentalPolicyFile(id: $id, blobName: $blobName, type: $documentType) {
      id
    }
  }
`;
