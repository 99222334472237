import { gql } from '@apollo/client';

export const PRIVATE_DOCUMENT_FIELDS = gql`
  fragment PrivateDocumentFields on PrivateHealthPolicy {
    letterForEmployerDocument {
      id
      url
      blobName
    }
    taxStatementDocument {
      id
      url
      blobName
    }
    riskSurchargeDocument {
      id
      url
      blobName
    }
    exclusionsDocument {
      id
      url
      blobName
    }
    confirmationOfCoverageDocument {
      url
    }
    confirmationOfCoverageFromProviderDocument {
      id
      url
      blobName
    }
    applicationDocument {
      url
    }
    sepaMandateDocument {
      id
      url
      blobName
    }
    invoices {
      id
      url
      name
      blobName
    }
    letterOfIntentionDocuments {
      id
      url
      name
      blobName
    }
    employmentContractDocuments {
      id
      url
      name
      blobName
    }
    futureIncomeDocuments {
      id
      url
      name
      blobName
    }
    bankStatementDocuments {
      id
      url
      name
      blobName
    }
    proofOfIncomeDocuments {
      id
      url
      name
      blobName
    }
    otherDocuments {
      id
      url
      name
      blobName
    }
    additionalDocuments {
      id
      url
      name
      blobName
    }
    dependentsConfirmationOfCoverage {
      id
      url
      name
      blobName
    }
    questionnaireDownloadDocuments {
      id
      url
      type
      blobName
    }
  }
`;
