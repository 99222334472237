import { RefObject, useEffect, useRef } from 'react';

export const useTextareaAutoHeightSet = (
  dependencies?: any[]
): RefObject<HTMLTextAreaElement> => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const textAreaElement = textareaRef.current;

    if (textAreaElement) {
      // This sets the height of the element temporarily to get the correct scroll height
      textAreaElement.style.height = '0px';

      const { scrollHeight } = textAreaElement;

      // Set the new height of the depending on the new text content
      textAreaElement.style.height = `${scrollHeight}px`;
    }
  }, [textareaRef, ...(dependencies ?? [])]);

  return textareaRef;
};
