import { Country } from '../../../../shared/countries/models';
import { TransactionInsuranceType } from '../models/TransactionInsuranceType';

export const getCountry = (
  insuranceType: TransactionInsuranceType
): Country => {
  switch (insuranceType) {
    case 'DENTAL':
      return 'de';
    case 'INCOMING':
      return 'de';
    case 'INCOMING_ES':
      return 'es';
  }
};
