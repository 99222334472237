import { useTranslation } from '@getpopsure/i18n-react';
import { useOffset } from 'hooks/useOffset';
import useQueryParams from 'hooks/useQueryParams';
import { useHistory } from 'react-router';

import ArrowButton from './ArrowButton';
import PageButton from './PageButton';
import PageButtonSeparator from './PageButtonSeparator';
import { pagination, paginationButtons, paginationText } from './styles';

interface PaginationProps {
  totalItems: number;
  limitItems: number;
}

const Pagination = ({ totalItems, limitItems }: PaginationProps) => {
  const { t } = useTranslation();
  const queryParams = useQueryParams();
  const { offset } = useOffset();
  const history = useHistory();
  const currentPage = offset / limitItems + 1;

  const handlePageButtonClick = (page: number) => {
    queryParams.set('offset', String(page * limitItems - limitItems));
    history.push(`?${queryParams.toString()}`);
  };

  const numberPages =
    Math.ceil(totalItems / limitItems) === 0
      ? 1
      : Math.ceil(totalItems / limitItems);

  const handleArrowButtonClick = (next: boolean) => {
    let newCurrentPage;
    if (next) {
      if (currentPage + 1 >= numberPages) {
        newCurrentPage = numberPages;
      } else {
        newCurrentPage = currentPage + 1;
      }
    } else if (currentPage - 1 <= 1) {
      newCurrentPage = 1;
    } else {
      newCurrentPage = currentPage - 1;
    }
    handlePageButtonClick(newCurrentPage);
  };

  const getItemsIntervalStart = (): number => {
    if (totalItems === 0) {
      return 0;
    }
    return (currentPage - 1) * limitItems + 1;
  };

  const getItemsIntervalEnd = (): number | null => {
    if (totalItems !== 0) {
      if (currentPage * limitItems >= totalItems) {
        return totalItems;
      }
      return currentPage * limitItems;
    }

    return null;
  };

  const intervalStart = getItemsIntervalStart();
  const withHyphen = totalItems !== 0 ? ' - ' : ' ';
  const intervalEnd = getItemsIntervalEnd();

  return (
    <div className={pagination}>
      <div>
        <p className={paginationText}>
          {t(
            'admin.policytable.pagination.description',
            '{{intervalStart}} {{withHyphen}} {{intervalEnd}} of {{totalItems}}',
            { intervalStart, withHyphen, intervalEnd, totalItems }
          )}
        </p>
      </div>
      {totalItems !== 0 && (
        <div>
          <nav className={paginationButtons} aria-label="Pagination">
            <ArrowButton
              next={false}
              handleClick={handleArrowButtonClick}
              disabled={currentPage === 1}
              dataTestId="previous-arrow-button"
            />
            <PageButton
              current={false}
              handleClick={handlePageButtonClick}
              pageNumber={1}
              isVisible={numberPages > 3 && currentPage - 1 > 1}
            />
            <PageButtonSeparator
              isVisible={numberPages > 4 && currentPage - 2 > 1}
            />
            <PageButton
              current={currentPage === 1}
              handleClick={handlePageButtonClick}
              isVisible={true}
              pageNumber={
                currentPage === 1 || numberPages < 4
                  ? 1
                  : currentPage === numberPages
                  ? currentPage - 2
                  : currentPage - 1
              }
            />
            <PageButton
              current={
                currentPage !== 1 &&
                (currentPage !== numberPages || numberPages === 2)
              }
              isVisible={numberPages > 1}
              handleClick={handlePageButtonClick}
              pageNumber={
                currentPage === 1 || numberPages < 4
                  ? 2
                  : currentPage === numberPages
                  ? currentPage - 1
                  : currentPage
              }
            />
            <PageButton
              isVisible={numberPages > 2}
              current={currentPage === numberPages}
              handleClick={handlePageButtonClick}
              pageNumber={
                currentPage === 1
                  ? 3
                  : currentPage === numberPages
                  ? currentPage
                  : currentPage + 1
              }
            />
            <PageButtonSeparator
              isVisible={numberPages > 4 && currentPage < numberPages - 2}
            />
            <PageButton
              isVisible={numberPages > 3 && currentPage < numberPages - 1}
              current={false}
              handleClick={handlePageButtonClick}
              pageNumber={numberPages}
            />
            <ArrowButton
              next={true}
              handleClick={handleArrowButtonClick}
              disabled={currentPage === numberPages}
              dataTestId="next-arrow-button"
            />
          </nav>
        </div>
      )}
    </div>
  );
};

export default Pagination;
