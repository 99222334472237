import classNames from 'classnames';

export const tableWrapper = classNames(
  'mb-[80px]',
  'overflow-x-auto',
  'justify-self-center',
  'w-full'
);

export const title = classNames(
  'mx-[40px]',
  'text-2xl',
  'font-bold',
  'text-gray-900'
);

export const actionsBar = classNames(
  'mx-[40px]',
  'flex',
  'items-center',
  'mt-[16px]',
  'justify-between'
);

export const searchBar = classNames(
  'flex',
  'items-center',
  'items-start',
  'flex-wrap',
  'gap-2'
);

export const tableContent = classNames(
  'mt-2',
  'mx-[40px]',
  'align-middle',
  'rounded-[4px]',
  'overflow-x-scroll'
);

export const table = classNames('w-full', 'border-separate');

export const tableHeader = classNames(
  'bg-white',
  'border-b',
  'border-gray-300'
);

export const pagination = classNames('mx-[40px]');
