import { gql } from '@apollo/client';

export const EXPAT_DOCUMENT_UPLOAD = gql`
  mutation UpdateExpatStatus(
    $policyId: String!
    $newFilename: String!
    $file: Upload
  ) {
    uploadExpatHealthPolicyFile(
      newFilename: $newFilename
      policyId: $policyId
      file: $file
    ) {
      id
      url
      name
    }
  }
`;

export const DELETE_EXPAT_POLICY_FILE = gql`
  mutation DeleteExpatHealthFile(
    $id: String!
    $blobName: String!
    $documentType: String!
  ) {
    deleteExpatHealthPolicyFile(
      id: $id
      blobName: $blobName
      documentType: $documentType
    ) {
      id
      name
      type
      blobName
    }
  }
`;

export const REGENERATE_EXPAT_POLICY_FILE = gql`
  mutation RegenerateExpatHealthFile($policyId: String!) {
    regenerateExpatHealthFile(policyId: $policyId)
  }
`;
