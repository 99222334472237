import { TFunction } from '@getpopsure/i18n-react';
import { DocumentsSectionData } from 'components/DocumentsCard';

import { CompanyPensionPolicy } from '../models/CompanyPensionPolicy';

export const getDocumentSections =
  (t: TFunction) =>
  (data?: {
    hrCompanyPensionPolicy: CompanyPensionPolicy;
  }): DocumentsSectionData[] => {
    const policyDocuments: DocumentsSectionData = {
      title: t(
        'admin.hr.companyPension.policyDocuments.label',
        'Policy documents'
      ),
      documents: [],
    };
    const featherUploads: DocumentsSectionData = {
      title: t(
        'admin.hr.companyPension.featherUploads.label',
        'Feather uploads'
      ),
      documents: [],
    };

    data?.hrCompanyPensionPolicy.policyDocuments?.forEach((document) => {
      if (document.type === 'CONFIRMATION_OF_COVERAGE') {
        policyDocuments.documents.push({
          id: document.id,
          fileLink: document.url,
          fileName: 'Confirmation of coverage',
          resizedUrl: document.resizedUrl,
          blobName: document.blobName,
          canDelete: false,
        });
      } else if (document.type === 'APPLICATION') {
        policyDocuments.documents.push({
          id: document.id,
          fileLink: document.url,
          fileName: 'Application summary',
          resizedUrl: document.resizedUrl,
          blobName: document.blobName,
          canDelete: false,
        });
      } else {
        featherUploads.documents.push({
          id: document.id,
          fileLink: document.url,
          fileName: document.name,
          resizedUrl: document.resizedUrl,
          blobName: document.blobName,
          canDelete: false,
        });
      }
    });

    return [policyDocuments, featherUploads];
  };
