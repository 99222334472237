import { ApolloError } from '@apollo/client';
import Button from 'components/Button/Button';
import OverviewPage from 'components/OverviewPage/OverviewPage';
import { EmptyStateMessage } from 'components/Table/TableEmptyState';
import Table from 'components/TableRefactor/Table';
import { useState } from 'react';
import { countryNameMapper } from 'shared/countries/models';

import { textByInsuranceMapper } from '../../mappers';
import {
  ClaimsTemplateData,
  InsurancePathName,
  NUMBER_OF_CLAIMS_PER_PAGE,
} from '../../models';
import { CreateClaimModal } from '../CreateClaimModal';

interface Props<Status extends string, Type extends string> {
  data: ClaimsTemplateData<Status, Type>;
  insurancePathName: InsurancePathName;
}

export const ClaimsOverview = <Status extends string, Type extends string>({
  data: {
    insuranceType,
    region,
    queries: { fetchAllClaims, fetchAllPolicies },
    mutations: { createClaim },
    overview: { headers, filterConfig, rowConfig, viewConfig },
    claimCreation,
  },
  insurancePathName,
}: Props<Status, Type>) => {
  const [error, setError] = useState<ApolloError | undefined>();
  const [isNewClaimModalOpen, setIsNewClaimModalOpen] = useState(false);

  const noClaimsMessage: EmptyStateMessage = {
    title: 'There are no claims',
    description: 'It looks like there are no claims yet.',
  };

  const nVisibleFilters = region === 'eu' ? 3 : 2;
  const tableSubtitle = region ? countryNameMapper[region] : undefined;

  return (
    <OverviewPage
      error={error}
      current={`claims-${insurancePathName}`}
      title={textByInsuranceMapper[insurancePathName].windowTitle.overviewPage}
    >
      {claimCreation &&
        createClaim &&
        (isNewClaimModalOpen ? (
          <CreateClaimModal<Status, Type>
            setIsOpen={setIsNewClaimModalOpen}
            isOpen={isNewClaimModalOpen}
            claimCreation={claimCreation}
            insuranceType={insuranceType}
            createClaimMutation={createClaim}
            fetchPoliciesQuery={fetchAllPolicies}
            refetchQueries={[fetchAllClaims]}
          />
        ) : (
          <></>
        ))}
      <Table
        nVisibleFilters={nVisibleFilters}
        title={textByInsuranceMapper[insurancePathName].pageTitle.overviewPage}
        subtitle={tableSubtitle}
        emptyStateLabel={noClaimsMessage}
        searchPlaceholder="Search by name, email, claim number, claim id, policy number or policy id"
        itemsPerPage={NUMBER_OF_CLAIMS_PER_PAGE}
        query={fetchAllClaims}
        queryVariables={{
          insuranceType,
          ...(region && region !== 'eu' ? { region: [region] } : {}),
        }}
        headers={headers}
        filterConfig={filterConfig}
        rowConfig={rowConfig}
        viewConfig={viewConfig}
        onError={setError}
        actionButton={
          claimCreation &&
          createClaim && (
            <Button
              className="w-[100px]"
              buttonType="primary"
              onClick={() => setIsNewClaimModalOpen(true)}
            >
              New claim
            </Button>
          )
        }
      />
    </OverviewPage>
  );
};
