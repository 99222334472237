import classNames from 'classnames';

export const noteCard = classNames('p-[16px]', 'bg-white', 'rounded-[6px]');

export const header = classNames('flex', 'justify-between');

export const headerRight = classNames('flex');

export const iconButton = classNames(
  'mr-[8px]',
  'hover:text-indigo-500',
  'focus:text-indigo-500'
);

export const replyButton = classNames(
  iconButton,
  'text-gray-500',
  'w-[18px]',
  'h-[20px]'
);

export const pinButton = classNames(iconButton, 'ml-[8px]');

export const name = classNames('text-sm', 'text-gray-900');

export const noteContent = classNames('text-sm', 'text-gray-600', 'mt-[8px]');

export const date = classNames('text-xs', 'text-gray-500', 'mt-[8px]');

export const replyContainer = classNames(
  'flex',
  'mt-[24px]',
  'animate-appear-down'
);
export const replyContentContainer = classNames('ml-[8px]', 'w-full');

export const divider = classNames(
  'h-[1px]',
  'w-full',
  'bg-gray-300',
  'mt-[16px]'
);

export const greyDotContainer = classNames('flex', 'flex-col', 'items-center');
export const replyGreyDot = classNames(
  'h-[9px]',
  'w-[8px]',
  'bg-gray-400',
  'rounded-2xl',
  'mt-[6px]'
);
export const greyDot = classNames(
  'h-[8px]',
  'w-[8px]',
  'bg-gray-400',
  'rounded-2xl',
  'mt-[6px]'
);
export const greyLine = classNames('h-full', 'w-[1px]', 'bg-gray-200');

export const replyTextAreaContainer = classNames(
  'mt-[8px]',
  'flex',
  'items-start',
  'relative'
);
export const replyTextArea = classNames(
  'w-full',
  'h-[20px]',
  'min-h-[20px]',
  'max-h-[170px]',
  '!p-0',
  'ml-[8px]',
  'focus:ring-0',
  'resize-none'
);
export const createReplyNoteButton = classNames(
  'absolute',
  'bottom-[-2px]',
  'right-0',
  'rotate-90',

  'h-[24px]',
  'w-[24px]',

  'text-gray-500',
  'bg-indigo-100',

  'hover:text-indigo-500',

  'focus:text-indigo-500',
  'focus:outline-none',
  'focus:ring-2',
  'focus:ring-offset-2',
  'focus:ring-indigo-500',

  'rounded-[4px]',

  'flex',
  'justify-center',
  'items-center'
);
export const disabledCreateReplyNoteButton = classNames(
  'cursor-default',
  'opacity-50',
  'pointer-events-none'
);
export const createReplyNoteButtonIcon = classNames('h-[16px]', 'w-[16px]');

export const createNoteLoader = classNames(
  'mr-[5px]',
  'h-[20px]',
  'w-[20px]',
  'animate-spin'
);
