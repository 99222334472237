import DocumentsCard, { DocumentsSectionData } from 'components/DocumentsCard';
import { SelectMenuOption } from 'components/SelectMenu';
import { PrivateHealthPolicy } from 'models/privateHealthPolicy';
import {
  DELETE_PRIVATE_POLICY_FILE,
  REGENERATE_PRIVATE_HEALTH_FILE,
  UPLOAD_PRIVATE_POLICY_FILE,
} from 'pages/policies/privateHealth/graphql/mutations';
import { GET_PRIVATE_POLICY } from 'pages/policies/privateHealth/graphql/queries';
import { mapDocuments, mapQuestionnaireDocuments } from 'shared/mapDocuments';

import { PrivateHealthFileType } from '../../models';

interface DocumentsSectionProps {
  policy: PrivateHealthPolicy;
}

const DocumentsSection = ({ policy }: DocumentsSectionProps) => {
  const documents: DocumentsSectionData<PrivateHealthFileType>[] = [
    {
      title: 'Policy documents',
      documents: [
        {
          fileName: 'Confirmation of coverage',
          fileLink: policy.confirmationOfCoverageDocument?.url,
          isRegeneratable: true,
          documentType: 'CONFIRMATION_OF_COVERAGE',
          disabled:
            !policy.confirmationOfCoverageDocument &&
            policy.publicStatus === 'ACTIVE',
        },
        ...(policy.insuredPerson?.occupation === 'EMPLOYED'
          ? [
              {
                fileName: 'Letter for employer',
                fileLink: policy.letterForEmployerDocument?.url,
                isRegeneratable: true,
                documentType: 'LETTER_FOR_EMPLOYER' as const,
                id: policy.letterForEmployerDocument?.id,
                blobName: policy.letterForEmployerDocument?.blobName,
                canDelete: true,
                disabled:
                  !policy.letterForEmployerDocument &&
                  policy.publicStatus === 'ACTIVE',
              },
            ]
          : []),
      ],
    },
    {
      title: 'Customer uploads',
      documents: [
        {
          fileName: 'Application document',
          fileLink: policy.applicationDocument?.url,
        },
        {
          id: policy.sepaMandateDocument?.id,
          fileName: 'SEPA Mandate',
          fileLink: policy.sepaMandateDocument?.url,
          blobName: policy.sepaMandateDocument?.blobName,
          documentType: 'SEPA_MANDATE',
          canDelete: true,
        },
        ...(policy.insuredPerson?.occupation === 'EMPLOYED'
          ? mapDocuments<PrivateHealthFileType>(
              policy.employmentContractDocuments ?? [],
              true,
              'Employment contract',
              'EMPLOYMENT_CONTRACT'
            )
          : []),
        ...(policy.insuredPerson?.occupation === 'SELF_EMPLOYED'
          ? [
              ...mapDocuments<PrivateHealthFileType>(
                policy.bankStatementDocuments ?? [],
                true,
                'Bank statement',
                'BANK_STATEMENT'
              ),
              ...mapDocuments<PrivateHealthFileType>(
                policy.proofOfIncomeDocuments ?? [],
                true,
                'Additional proof of income',
                'PROOF_OF_INCOME'
              ),
              ...mapDocuments<PrivateHealthFileType>(
                policy.futureIncomeDocuments ?? [],
                true,
                'Proof of future income',
                'FUTURE_INCOME'
              ),
            ]
          : []),
      ],
    },
    {
      title: 'Feather uploads',
      documents: [
        ...mapDocuments<PrivateHealthFileType>(
          policy.dependentsConfirmationOfCoverage ?? [],
          true,
          'Family confirmation of coverage',
          'DEPENDENT_CONFIRMATION_OF_COVERAGE'
        ),
        {
          id: policy.confirmationOfCoverageFromProviderDocument?.id,
          fileName: 'Confirmation of coverage (provider)',
          fileLink: policy.confirmationOfCoverageFromProviderDocument?.url,
          blobName: policy.confirmationOfCoverageFromProviderDocument?.blobName,
        },
        {
          id: policy.taxStatementDocument?.id,
          fileName: 'Tax statement',
          fileLink: policy.taxStatementDocument?.url,
          blobName: policy.taxStatementDocument?.blobName,
          canDelete: true,
        },
        {
          id: policy.riskSurchargeDocument?.id,
          fileName: 'Risk surcharge',
          fileLink: policy.riskSurchargeDocument?.url,
          blobName: policy.riskSurchargeDocument?.blobName,
          canDelete: true,
        },
        {
          id: policy.exclusionsDocument?.id,
          fileName: 'Exclusions',
          fileLink: policy.exclusionsDocument?.url,
          blobName: policy.exclusionsDocument?.blobName,
          canDelete: true,
        },
        ...(policy.insuredPerson?.occupation === 'SELF_EMPLOYED'
          ? [
              ...mapDocuments<PrivateHealthFileType>(
                policy.invoices ?? [],
                true,
                'Invoice',
                'INVOICE'
              ),
              ...mapDocuments<PrivateHealthFileType>(
                policy.letterOfIntentionDocuments ?? [],
                true,
                'Letter of intention',
                'LETTER_OF_INTENTION'
              ),
            ]
          : []),
        ...mapDocuments<PrivateHealthFileType>(
          policy.otherInternalDocuments ?? [],
          true,
          undefined,
          'OTHER_INTERNAL'
        ),
        ...mapDocuments<PrivateHealthFileType>(
          policy.otherDocuments ?? [],
          true,
          undefined,
          'OTHER'
        ),
        ...mapDocuments<PrivateHealthFileType>(
          policy.additionalDocuments ?? [],
          false,
          undefined,
          'OTHER'
        ),
        ...mapQuestionnaireDocuments<PrivateHealthFileType>(
          policy.questionnaireDownloadDocuments ?? []
        ),
      ],
    },
    {
      title: 'Scanned documents',
      documents: [
        ...mapDocuments<PrivateHealthFileType>(
          policy.scannedDocuments?.flatMap((sDoc) =>
            sDoc.visibleInUserAccount
              ? [
                  {
                    ...sDoc,
                    name: sDoc.friendlyName,
                  },
                ]
              : []
          ) ?? [],
          false,
          undefined,
          'SCANNED_DOCUMENT'
        ),
      ],
    },
  ];

  const fileTypeOptions: SelectMenuOption[] = [
    {
      id: 'SEPA_MANDATE',
      label: 'Sepa Mandate',
    },
    ...(policy.insuredPerson?.occupation === 'EMPLOYED'
      ? [
          {
            id: 'EMPLOYMENT_CONTRACT',
            label: 'Employment contract',
          },
        ]
      : []),
    ...(policy.insuredPerson?.occupation === 'SELF_EMPLOYED'
      ? [
          {
            id: 'BANK_STATEMENT',
            label: 'Bank Statement',
          },
          {
            id: 'PROOF_OF_INCOME',
            label: 'Additional proof of income',
          },
          {
            id: 'FUTURE_INCOME',
            label: 'Proof of future income',
          },
        ]
      : []),
    {
      id: 'CONFIRMATION_OF_COVERAGE',
      label: 'Confirmation of coverage',
    },
    {
      id: 'DEPENDENT_CONFIRMATION_OF_COVERAGE',
      label: 'Family confirmation of coverage',
    },
    {
      id: 'CONFIRMATION_OF_COVERAGE_FROM_PROVIDER',
      label: 'Confirmation of coverage (Provider)',
    },
    {
      id: 'TAX_STATEMENT',
      label: 'Tax statement',
    },
    {
      id: 'RISK_SURCHARGE',
      label: 'Risk surcharge',
    },
    ...(policy.insuredPerson?.occupation === 'SELF_EMPLOYED'
      ? [
          {
            id: 'EXCLUSIONS',
            label: 'Exclusions',
          },
          {
            id: 'INVOICE',
            label: 'Invoice',
          },
          {
            id: 'LETTER_OF_INTENTION',
            label: 'Letter of intention',
          },
        ]
      : []),
    {
      id: 'OTHER',
      label: 'Other',
    },
    {
      id: 'OTHER_INTERNAL',
      label: 'Other - Internal',
    },
    {
      id: 'ADVICE_RECORD',
      label: 'Advice record',
    },
    {
      id: 'ARCHIVE',
      label: 'Policy documents archive',
    },
    {
      id: 'SUMMARY',
      label: 'Application summary',
    },
    {
      id: 'HALLESCHE_ALL_IN_ONE',
      label: 'Hallesche bundle',
    },
  ];

  return (
    <DocumentsCard<PrivateHealthFileType>
      sections={documents}
      fileTypeOptions={fileTypeOptions}
      mutation={UPLOAD_PRIVATE_POLICY_FILE}
      getPolicyMutation={GET_PRIVATE_POLICY}
      policyId={policy.id}
      deleteMutation={DELETE_PRIVATE_POLICY_FILE}
      regenerateMutation={REGENERATE_PRIVATE_HEALTH_FILE}
    />
  );
};

export default DocumentsSection;
