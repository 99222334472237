import { SelectButtonOption } from 'components/SelectButton';
import { StatusUpdateFormQuestion } from 'components/StatusUpdateModal/models';
import { StatusData } from 'pages/claims/template/models';
import {
  LegalClaimStatus,
  legalClaimStatuses,
} from 'shared/claims/legal/models';
import { policyMappers } from 'shared/insurances';
import { InsuranceType } from 'shared/insurances/types';

export const claimStatusOptions = (
  insuranceType: InsuranceType
): SelectButtonOption<LegalClaimStatus>[] => {
  const statusMapper = policyMappers[insuranceType]?.claim?.claimStatusMapper;
  const badgeColorMapper =
    policyMappers[insuranceType]?.claim?.badgeColorMapper;

  return legalClaimStatuses.map((statusId) => ({
    id: statusId,
    label: statusMapper?.[statusId] ?? '',
    title: statusMapper?.[statusId] ?? '',
    color: badgeColorMapper?.[statusId] ?? 'gray',
  }));
};

export const retrieveFormAndFooterTextByStatus = (
  status: LegalClaimStatus
): StatusData => ({
  form: formMapper[status],
  footerText: footerTextMapper(status),
});

const uploadForm: StatusUpdateFormQuestion = {
  id: 'uploadDocument',
  label: 'Upload document',
  data: {
    type: 'UPLOAD',
    fileName: { placeholder: 'Enter document display name', id: 'fileName' },
  },
};

const formMapper: Record<LegalClaimStatus, StatusUpdateFormQuestion[]> = {
  RECEIVED: [],
  SUBMITTED_TO_PROVIDER: [],
  CLOSED: [uploadForm],
};

const footerTextMapper = (status: LegalClaimStatus): string | undefined => {
  if (status !== 'SUBMITTED_TO_PROVIDER' && status !== 'RECEIVED') {
    return 'Once confirmed, claim status will be updated and documents will be added to the claim.';
  }
};
